import React, {useCallback, useEffect, useState} from 'react';
import NumberFormat from "react-number-format";
import * as TicketService from "../../../../service/Ticket.service";
import {hideLoading, showLoading, showModal} from "../../../../helpers/layoutHelper";
import Agreements from "../../../../data/agreements.json";
import {isMobile} from 'react-device-detect';
import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination } from "swiper";
import PasswordComponent from "./PasswordComponent";

const PaymentContainer = (props) => {

  const reservationIdx = parseInt(props.match.params.reservationIdx);
  const seriesReservationIdx = parseInt(props.match.params.seriesReservationIdx);

  const [reservation, setReservation] = useState();
  const [options, setOptions] = useState();
  const [myPoint, setMyPoint] = useState(0);
  const [myCoupons, setMyCoupons] = useState([]);
  const [ncPays, setNcPays] = useState([]);
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [cancelFeeAgreed, setCancelFeeAgreed] = useState(false);
  const [selectedPayMethod, setSelectedPayMethod] = useState('');
  const [couponOrPoint, setCouponOrPoint] = useState('POINT');
  const [usePoint, setUsePoint] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [enterPassword, setEnterPassword] = useState(false);
  const [ncPayIndex, setNcPayIndex] = useState(0);

  const [agreements, setAgreements] = useState([
    {
      title: '서비스 이용약관 동의 (필수)',
      checked: false,
      name: 'terms',
      message: Agreements.terms
    },
    {
      title: '개인정보 수집 및 이용동의 (필수)',
      checked: false,
      message: Agreements.privacy
    },
    {
      title: 'KBO리그 SAFE캠페인 동의 (필수)',
      checked: false,
      message: Agreements.campaign
    },
    {
      title: '시리즈티켓 약관 동의 (필수)',
      checked: false,
      message: Agreements.series,
      series: true,
    }
  ]);

  const prevPage = async () => {
    if (seriesReservationIdx) {
      if (window.confirm('이전 단계로 돌아가면 현재의 예매 정보를 잃게 됩니다.')) {
        showLoading();
        await TicketService.cancelSeriesSeat(seriesReservationIdx);
        props.history.goBack();
      }
    } else {
      props.history.goBack();
    }
  }

  const fetchData = useCallback(async () => {
    showLoading();
    const {data} = seriesReservationIdx ? await TicketService.getSeriesPayments(seriesReservationIdx) : await TicketService.getPayments(reservationIdx);
    const {reservation, ncPays, point, coupons, options} = data;
    setReservation(reservation);
    setOptions(options);
    setNcPays(ncPays);
    setMyPoint(point);
    setMyCoupons(coupons);
    setUserPhone(reservation.userPhone);
    setUserEmail(reservation.userEmail);

    hideLoading();
  }, [reservationIdx, seriesReservationIdx]);

  useEffect(() => {
    // document.title = "예매확인 및 결제"

    fetchData().then();
  }, [fetchData]);

  const checkAgreements = () => {
    // if (reservation.seats.find(seat => seat.paymentTypeIdx === 12)) {
    //   if (ncPays.length === 0 || (!ncPays[ncPayIndex].cardNo.startsWith('3562-97') && !ncPays[ncPayIndex].cardNo.startsWith('4518-42'))) {
    //     console.log(ncPays[ncPayIndex]);
    //     return false;
    //   }
    // }
    return agreements.reduce((a, b) => a && b.checked, true) && cancelFeeAgreed && (selectedPayMethod !== '' || paymentAmount() === 0) && (selectedPayMethod !== 'NCPAY' || ncPays.length>0);
  }

  const requestPay = async () => {
    if ((usePoint > 0 && usePoint < 1000) || usePoint % 100 > 0) {
      alert('포인트 사용은 최소 1000P 이상, 100P 단위로 사용이 가능합니다.');
      return;
    }
    if (paymentAmount() === 0) {
      showLoading();

      const {status, data} = await TicketService.requestPointPayment(reservationIdx, usePoint, selectedCoupon !== "" ? myCoupons[selectedCoupon].idx : null);

      if (status === 200) {
        props.history.push(`/reservation/complete`);
      } else {
        if (data.msg) {
          alert(data.msg)
        }
        hideLoading();
      }
    } else if (selectedPayMethod === 'NCPAY') {
      setEnterPassword(true);
    } else {
      const {status, data} = await TicketService.startPayment(reservationIdx, seriesReservationIdx, usePoint, selectedCoupon !== "" ? myCoupons[selectedCoupon].idx : null);
      if (status === 200) {
        if (isMobile) {
          showLoading();
          document.payForm.action = "https://web.nicepay.co.kr/v3/v3Payment.jsp";
          document.payForm.EdiDate.value = data.ediDate;
          document.payForm.SignData.value = data.signData;
          document.payForm.Moid.value = data.moid;
          document.payForm.ReqReserved.value = encodeURI(JSON.stringify({
            idx: reservationIdx,
            seriesReservationIdx,
            point: usePoint,
            coupon: selectedCoupon !== "" ? myCoupons[selectedCoupon].idx : null
          }));
          document.payForm.acceptCharset="euc-kr";
          document.payForm.submit();
        } else {
          document.payForm.EdiDate.value = data.ediDate;
          document.payForm.SignData.value = data.signData;
          document.payForm.Moid.value = data.moid;
          document.payForm.ReqReserved.value = encodeURI(JSON.stringify({
            idx: reservationIdx,
            seriesReservationIdx,
            point: usePoint,
            coupon: selectedCoupon !== "" ? myCoupons[selectedCoupon].idx : null
          }));
          document.payForm.acceptCharset="euc-kr";
          window.goPay(document.payForm);
        }
      } else {
        if (data.msg) {
          alert(data.msg);
        }
      }
    }
  }

  const requestNcPay = useCallback(async (ncPayId) => {
    setEnterPassword(false);

    const {status, data} = await TicketService.requestNcPayPayment(reservationIdx, seriesReservationIdx, ncPayId, usePoint, selectedCoupon !== "" ? myCoupons[selectedCoupon].idx : null);

    if (status === 200) {
      props.history.push(`/reservation/complete`);
    } else {
      if (data.msg) {
        alert(data.msg)
      }
      hideLoading();
    }

  }, [usePoint, selectedCoupon]);

  const handleUsePoint = (e) => {
    if (parseInt(e.target.value) > myPoint) {
      alert('사용 포인트가 보유 포인트를 초과합니다.');
      return;
    }
    if (parseInt(e.target.value) > reservation.totalPrice - reservation.partnerDiscountAmount) {
      alert('사용 포인트가 티켓 금액을 초과합니다.');
      return;
    }
    let point = parseInt(e.target.value);
    if (isNaN(point)) {
      point = 0;
    }
    setUsePoint(point);
  }

  const useAllPoint = () => {
    let pt = Math.floor(Math.min(myPoint, reservation.totalPrice - reservation.partnerDiscountAmount) / 100) * 100;
    setUsePoint(pt);
  }

  const couponDiscountAmount = () => {
    if (selectedCoupon !== "") {
      const coupon = myCoupons[selectedCoupon];
      if (coupon.discountType === 1) {
        let couponDiscount = parseInt(reservation.totalPrice * coupon.discountAmount / 100);
        if (coupon.maxDiscount) {
          couponDiscount = Math.min(couponDiscount, coupon.maxDiscount);
        }
        return Math.round(couponDiscount / 100) * 100;
      } else {
        return coupon.discountAmount;
      }

    } else {
      return 0;
    }
  }

  const handleUseCoupon = (e) => {
    setSelectedCoupon(e.target.value);
  }

  const showTerms = (e) => {
    showModal(e.target.dataset.message, e.target.dataset.title);
  }

  function paymentAmount() {
    return Math.max(reservation.totalPrice - usePoint - couponDiscountAmount() - reservation.partnerDiscountAmount, 0) + parseInt(reservation.totalFee) + parseInt(reservation.totalExtraPrice);
  }

  function CouponList() {
    const listItems = myCoupons.map((coupon, index) => {
      return (
        <option key={index} value={index}>{coupon.couponName}</option>
      );
    })

    return (
      <select value={selectedCoupon} onChange={handleUseCoupon}>
        <option value="">사용할 쿠폰을 선택해 주세요.</option>
        {listItems}
      </select>
    );
  }

  function AgreementList(props) {
    const handleChange = index => {
      setAgreements(
        agreements.map((agreement, key) =>
          key === index ? {...agreement, checked: !agreement.checked} : agreement)
      );
    }

    const listItems = props.agreements.map((agreement, index) => {
      return (
        <li key={index}>
          <label className="form-label">
            <input type="checkbox" checked={agreement.checked} onChange={() => handleChange(index)}/>
            <span>{agreement.title}</span>
          </label>
          <span onClick={showTerms} data-message={agreement.message} data-title={agreement.title}>상세보기</span>
        </li>
      );
    });
    return (
      <div className="list">
        <ul>
          {listItems}
        </ul>
      </div>
    );
  }

  function PayMethodList() {
    let payMethods = [
      {method: 'CARD', title: '카드 결제'},
      // {method: 'BANK', title: '실시간 계좌 이체'},
      // {method: 'CELLPHONE', title: '휴대폰 결제'}
      {method: 'NCPAY', title: 'NC 다이노스 페이'},
      // {method: 'APPLEPAY', title: 'Apple Pay'},
    ]
    if (reservation.seats.find(seat => seat.paymentTypeIdx === 12)) {
      payMethods = payMethods.filter(payMethod => payMethod.method === 'NCPAY');
      setSelectedPayMethod('NCPAY');
    }
    const listItems = payMethods.map((payMethod, index) => {
        return (
          <li key={index}>
            <input type="radio" name="payment" id={`payment_${payMethod.method}`} value={payMethod.method} checked={selectedPayMethod===payMethod.method} onChange={() => {
              showModal('선불카드, 기프트카드, 카드사 포인트를 사용한 복합 결제는 티켓 예매 후 익일부터 또는 경기 당일 티켓 예매 시 카드사 정책에 따라 부분취소, 전체취소 모두 불가합니다.', '카드 결제 안내');
              setSelectedPayMethod(payMethod.method);
            }}/>
            <label htmlFor={`payment_${payMethod.method}`}><span>{payMethod.title}</span></label>
          </li>
        );
      })
    return (
      <ul>
        {listItems}
      </ul>
    )
  }

  const openNcPayManage = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('appLink', 'https://app.ncdinos.com/ncpayList', 'NC 다이노스 페이');
    } else {
      window.open(`https://app.ncdinos.com/ncpayList`);
    }
  }

  const NcPayList = () => {
    const listItems = ncPays.map((ncPay, index) => {
      let enabled = true;
      return (
        <SwiperSlide key={index}>
          <div className="card-pay ncdinos">
            <i></i>
            <h6>{ncPay.cardName}카드</h6>
            <p>{ncPay.cardNo}</p>
            <div className="disabled" style={{display: enabled ? 'none' : 'block'}}>사용불가</div>
          </div>
        </SwiperSlide>
      );
    });
    return (
      <>
        <h6>NC 다이노스 페이<span onClick={openNcPayManage}>카드관리</span></h6>
        {/*<h6>NC 다이노스 페이<span>카드변경</span></h6>*/}
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="card-swiper"
          spaceBetween={20}
          initialSlide={ncPayIndex}
          onSlideChangeTransitionEnd={(swiper) => {
            if (ncPayIndex !== swiper.activeIndex) {
              setNcPayIndex(swiper.activeIndex);
            }
          }}>
          {ncPays.length > 0 ?
            listItems :
            <SwiperSlide><div className="card-pay no-card">등록된 카드가 없습니다.</div></SwiperSlide>
          }
        </Swiper>
      </>);
  }

  if (enterPassword) {
    return (<PasswordComponent ncPayId={ncPays[ncPayIndex].id} onPasswordConfirm={requestNcPay}/>);
  }

  return (
      reservation ?
    <div>
      <div id="content">
        <div className="order_info">
          <div className="title">
            구매자 정보 입력
          </div>
          <div className="user-input">
            <ul>
              <li>이름 (필수)</li>
              <li><input type="text" value={reservation.userName || ''} readOnly={true}/></li>
              <li>휴대폰 번호 (필수)</li>
              <li><input type="text" value={userPhone || ''} readOnly={true}/></li>
              <li>이메일 (선택)</li>
              <li><input type="email" value={userEmail || ''} onChange={(e) => {setUserEmail(e.target.value)}}/></li>
            </ul>
          </div>
        </div>

        {
          // reservation.gameIdx === process.env.REACT_APP_TOWNHALL_MEETING_GAME_IDX &&
          <div className="sale">
            <div className="title">할인수단</div>
            <ul className="sale-type">
              <li className={couponOrPoint==='POINT' ? 'on' : null} onClick={() => setCouponOrPoint('POINT')}>포인트</li>
              <li className={couponOrPoint==='COUPON' ? 'on' : null} onClick={() => setCouponOrPoint('COUPON')}>쿠폰<span style={{fontSize: 13, marginLeft: 2, color: "red", fontWeight: "bold"}}>({myCoupons.length})</span></li>
            </ul>
            {couponOrPoint==='POINT' ?
            <div className="sale-point">
              <div>
                <input type="text" pattern="\d*" placeholder="사용할 포인트를 입력해 주세요." value={usePoint} onChange={handleUsePoint} />
                <button onClick={useAllPoint}>모두 사용</button>
              </div>
              <ul className="sale-guide">
                <li>보유 포인트 <NumberFormat value={myPoint} displayType={"text"} thousandSeparator={true} suffix="P"/></li>
              </ul>
            </div>
              :
            <div className="sale-coupon">
              <CouponList />
              <ul className="sale-guide">
                <li>사용 가능 쿠폰 <span>{myCoupons.length}장</span></li>
              </ul>
            </div>}
          </div>
        }

        <div className="pay-price">
          <div className="title">최종 결제 금액</div>
          <ul>
            <li><span>티켓 금액</span><span><NumberFormat value={reservation.totalPrice} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
            <li><span>예매 수수료</span><span><NumberFormat value={reservation.totalFee} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
            {
              reservation.extraPriceName != null &&
              <li><span>{reservation.extraPriceName}</span><span><NumberFormat value={reservation.totalExtraPrice} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
            }
            {
              couponOrPoint === 'POINT' ?
                <li><span>포인트 사용</span><span><NumberFormat value={usePoint} displayType={"text"}
                                                           thousandSeparator={true}/><span>원</span></span></li>
                :
                <li><span>쿠폰 할인</span><span><NumberFormat value={-couponDiscountAmount()} displayType={"text"}
                                                          thousandSeparator={true}/><span>원</span></span></li>
            }
            {
              reservation.partnerDiscountAmount > 0 ?
              <li><span>{reservation.partnerDiscountTitle}</span><span><NumberFormat value={-Math.min(reservation.partnerDiscountAmount, reservation.totalPrice - usePoint - couponDiscountAmount())} displayType={"text"}
              thousandSeparator={true}/><span>원</span></span></li>
              : null
            }
            <li className="total-price"><span>총 결제 금액</span><span><NumberFormat value={paymentAmount()} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
          </ul>
          {[4, 5, 6].includes(reservation.type) &&
          <><h6>유의사항</h6>
          <p dangerouslySetInnerHTML={{__html: `&#8901; 이 상품은 모바일 전용 티켓입니다.<br/>&#8901; 각 경기 티켓마다, 예매 수수료 1,000원이 발생합니다.<br/>&#8901; 우천 취소 시 월 시리즈, 싱글 시리즈 티켓은 해당 경기일만큼 부분 환불됩니다. 팀 시리즈 티켓은 부분 환불없이 잔여경기 편성 시 신규 티켓이 재 발행됩니다.<br/>&#8901; 공정거래위원회 기준에 따라 각 상품마다 취소 환불기준이 다릅니다. 상품 구매 시 취소 안내 내용 확인 부탁드립니다.<br/>&#8901; 일부 좌석은 사고 대비석, 구단 관계자(KBO 관계자, 홈 프로모션, 이벤트 관계자 등) 참석 등으로 구단이 사전 선점할 수 있습니다.`}}></p></>
          }
          {reservation.totalOptionPrice > 0 &&
          <><h6>오픈프랙티스 유의사항</h6>
          <p dangerouslySetInnerHTML={{__html: `&#8901; 구매 시 1시간 선입장하여 ‘홈팀’ 훈련을 관람할 수 있습니다.<br/>&#8901; 선수단 훈련 관람 시 자유롭게 착석 가능하나 시즌회원 입장 후부터 시즌회원 좌석은 착석 불가합니다.<br/>&#8901; 선수단 훈련을 방해하지 않도록 유의해 관람해주세요. 상황에 따라 진행요원이 퇴장을 요구할 수 있습니다.<br/>&#8901; GATE 1로 선입장합니다. 언제라도 재입장 가능하며, 별도의 퇴장 절차가 없습니다.`}}></p></>
          }
          <h6>취소 기간 및 취소 수수료 안내</h6>
          {reservation.gameIdx === parseInt(process.env.REACT_APP_TOWNHALL_MEETING_GAME_IDX) ?
          <p dangerouslySetInnerHTML={{__html: `&#8901; 11/24(금) 오전 10시 까지 수수료 없이 취소/재구매 가능`}}></p>
          :
          [5, 6].includes(reservation.type) ?
          <p dangerouslySetInnerHTML={{__html: `&#8901; 예매당일 : 취소수수료 없음, 예매수수료 환불 (경기당일 제외)<br/>&#8901; 예매익일 ~ 경기시작 4시간 전 : 예매수수료 환불 불가<br/>&#8901; "소비자분쟁해결기준(공정거래위원회고시)" 관련 규정을 준용하여 처리<br/>&#8901; 시리즈티켓 구매 후 환불은 판매금액에서 시리즈티켓의 종류별 유효기간 내 시리즈티켓 적용 첫 경기 개시일로부터 환불요청이 구단에 도달한 날까지 진행된 경기 수에 대상 좌석의 1경기 정상 판매가격과 예매 수수료 1,000원을 곱한 금액과 시리즈티켓 구매금액의 10%에 해당하는 위약금을 제외한 금액에 대하여 가능합니다.<br/>&#8901; 경기 당일 경기시작 4시간 전 부터 취소 불가<br/>&#8901; 경기 당일 예매 후 취소 시 : 예매수수료 환불 불가, 티켓 금액의 10% 취소수수료 발생<br/>&#8901; 마산야구센터 내 주차공간이 부족합니다. 대중교통 이용을 부탁드리며 주차 및 교통난으로 인한 예매 티켓 취소, 환불, 변경이 불가합니다.`}}></p>
          :
          <p dangerouslySetInnerHTML={{__html: `${reservation.type === 4 ? '&#8901; 이 상품은 패키지 상품으로 부분취소가 되지 않습니다(일괄취소, 우천취소 제외)<br/>' : ''}&#8901; 예매당일 : 취소수수료 없음, 예매수수료 환불 (경기당일 제외)<br/>&#8901; 예매익일 ~ 경기시작 4시간 전 : 예매수수료 환불 불가, 티켓 금액의 10% 취소 수수료 발생<br/>&#8901; 경기시작 4시간 전 부터 취소 불가<br/>&#8901; 경기 당일 예매 후 취소 시 : 예매수수료 환불 불가, 티켓 금액의 10% 취소수수료 발생<br/>&#8901; 마산야구센터 내 주차공간이 부족합니다. 대중교통 이용을 부탁드리며 주차 및 교통난으로 인한 예매 티켓 취소, 환불, 변경이 불가합니다.`}}></p>
          }
          {reservation.totalOptionPrice > 0 &&
          <><h6>오픈프랙티스 취소 안내</h6>
          <p dangerouslySetInnerHTML={{__html: `&#8901; 티켓 구매 시 추가 옵션으로만 구매 가능합니다.<br/>&#8901; 티켓 결제 후 오픈 프랙티스 추가 구매 또는 변경 원할 경우 해당 티켓을 취소하고 재예매해야 합니다.<br/>&#8901; 재예매에 따른 수수료가 발생합니다.<br/>&#8901; 오픈 프랙티스는 입장이 시작된 뒤에는 환불 불가합니다.<br/>&#8901; 경기가 취소되어 경기 티켓을 환불 받더라도 오픈 프랙티스가 진행된 경우 해당 금액은 환불되지 않습니다. <br/>&#8901; 구매 후 개인 사정으로 관람하지 못한 경우에도 환불되지 않습니다.<br/>&#8901; (단, 선수단 훈련이 실내에 진행되어 관람하지 못한 경우는 환불해드립니다)`}}></p></>
          }
          
          <label><input type="checkbox" checked={cancelFeeAgreed} onChange={() => setCancelFeeAgreed(!cancelFeeAgreed)} /> <span>취소 기간 및 취소 수수료{reservation.totalOptionPrice > 0 ? ', 오픈프랙티스 취소 안내' : ''}에 동의합니다.</span></label>

        </div>

        <div className="payment-way" hidden={paymentAmount() === 0}>
          <div className="title">결제 방법 선택</div>
          <PayMethodList />
          {selectedPayMethod === 'NCPAY' ? <NcPayList /> : null}
        </div>

        <div className="agreement">
          <div className="title">
            약관 동의
            <label><input type="checkbox" onChange={(e) => setAgreements(agreements.map((agreement) => {
              return {
                ...agreement,
                checked: e.target.checked
              }
            }))}/><span>모두 동의</span></label>
          </div>
          <AgreementList agreements={agreements.filter(agreement => !agreement.series || [4, 5, 6].includes(reservation.type))} />
        </div>

        <div className="ticket_summary">
          <div>
            <span>총 결제금액</span><span><NumberFormat value={paymentAmount()} displayType={"text"} thousandSeparator={true}/>원</span>
          </div>
        </div>
      </div>
      <footer>
        <div className="prev" onClick={prevPage}>이전단계</div>
        <div className={`next${checkAgreements() ? '' : ' disabled'}`} onClick={checkAgreements() ? requestPay : null}>결제하기</div>
      </footer>
      <form name="payForm" method="post" action={`${process.env.REACT_APP_TICKET_API_URL}/paymentConfirm`}>
        <input type="hidden" name="GoodsName" value={reservation.reservationName}/>
        <input type="hidden" name="Amt" value={paymentAmount()}/>
        <input type="hidden" name="MID" value={process.env.REACT_APP_NICEPAY_MID}/>
        <input type="hidden" name="EdiDate"/>
        <input type="hidden" name="Moid"/>
        <input type="hidden" name="SignData"/>
        <input type="hidden" name="BuyerName" value={reservation.userName}/>
        <input type="hidden" name="BuyerTel" value={userPhone}/>
        <input type="hidden" name="ReturnURL" value={`${process.env.REACT_APP_TICKET_API_URL}/paymentConfirm`}/>
        {/*<input type="hidden" name="PayMethod" value={}/>*/}
        {/*<input type="hidden" name="ReqReserved"/>*/}
        <input type="hidden" name="BuyerEmail" value={userEmail}/>
        <input type="hidden" name="CharSet" value="utf-8"/>
        <input type="hidden" name="VbankExpDate" id="vExp"/>
        <input type="hidden" name="GoodsCl" value={1}/>
        <input type="hidden" name="WapUrl" value="ncdinos://"/>
        <input type="hidden" name="PayMethod" value={selectedPayMethod}/>
        <input type="hidden" name="ReqReserved"/>
        <input type="hidden" name="MallReserved"/>
        <input type="hidden" name="CardQuota" value="00"/>
        <input type="hidden" name="OSType"/>
        {/*<input type="hidden" name="IspCancelUrl" value={}/>*/}
        {/*<input type="hidden" name="TransType" value={0}/>*/}
        {/*<input type="hidden" name="EncryptData" value={'afawefeawfaewf'}/>*/}
      </form>
    </div> : <div/>
  );
};

export default PaymentContainer;
