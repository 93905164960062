export const zoneGroupData = [
  {
    "idx": 1,
    "name": "테이블석",
    "color": "#185094",
    "order": 3
  },
  {
    "idx": 2,
    "name": "미니 테이블석",
    "color": "#5c8ebb",
    "order": 4
  },
  {
    "idx": 3,
    "name": "내야석(1층/2층)",
    "color": "#00316a",
    "order": 5
  },
  {
    "idx": 4,
    "name": "내야석(3층/4층)",
    "color": "#c7cbd3",
    "order": 6
  },
  {
    "idx": 5,
    "name": "외야석",
    "color": "#878787",
    "order": 7
  },
  {
    "idx": 6,
    "name": "외야잔디석 (5인)",
    "color": "#007d41",
    "order": 8
  },
  {
    "idx": 16,
    "name": "외야잔디석",
    "color": "#007d41",
    "order": 8
  },
  {
    "idx": 7,
    "name": "ABL생명 프리미엄석",
    "color": "#A38259",
    "order": 1
  },
  {
    "idx": 8,
    "name": "ABL생명 프리미엄 테이블석",
    "color": "#9E95CE",
    "order": 2
  },
  {
    "idx": 9,
    "name": "스카이박스",
    "color": "#6449A0",
    "order": 20
  },
  {
    "idx": 10,
    "name": "포크밸리 바베큐석 (6인)",
    "color": "#866241",
    "order": 9
  },
  {
    "idx": 11,
    "name": "피크닉테이블석 (6인)",
    "color": "#866241",
    "order": 10
  },
  {
    "idx": 12,
    "name": "가족석 (2인)",
    "color": "#00b4e9",
    "order": 11
  },
  {
    "idx": 13,
    "name": "라운드테이블석 (2인)",
    "color": "#165193",
    "order": 14
  },
  {
    "idx": 14,
    "name": "불펜석",
    "color": "#790170",
    "order": 17
  },
  {
    "idx": 15,
    "name": "불펜가족석 (4인)",
    "color": "#9f1d90",
    "order": 18
  },
  {
    "idx": 17,
    "name": "휠체어석",
    "color": "#FFFFFF",
    "order": 19
  },
  {
    "idx": 19,
    "name": "가족석 (4인)",
    "color": "#00b4e9",
    "order": 12
  },
  {
    "idx": 20,
    "name": "가족석 (6인)",
    "color": "#00b4e9",
    "order": 13
  },
  {
    "idx": 21,
    "name": "라운드테이블석 (4인)",
    "color": "#165193",
    "order": 15
  },
  {
    "idx": 22,
    "name": "라운드테이블석 (6인)",
    "color": "#165193",
    "order": 16
  },
  {
    "idx": 23,
    "name": "노스피크캠크닉석 (4인)",
    "color": "#4EAD6F",
    "order": 21
  },
  {
    "idx": 24,
    "name": "노스피크캠크닉석 (8인)",
    "color": "#4EAD6F",
    "order": 22
  }
]
