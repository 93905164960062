import axios from 'axios';
import { queryStringFromObject } from '../helpers/apiHelper'

export const get = async (url, slug = {}) => {
  console.log('Api.service:get');
  const params = queryStringFromObject(slug);

  return axios.get(url + params)
    .then(({ status, data }) => {
      if (status === 403) {
        if (data.msg) {
          alert(data.msg);
        }
        if (window.location.pathname.startsWith('/mypage')) {
          window.location.href = '/mypage';
        } else {
          window.location.href = '/games';
        }
      } else {
        return { status, data };
      }
    }).catch(errorHandler);
}

export const post = async (url, params) => {
  console.log('Api.service:post');

  return axios.post(url, params)
    .then(({ status, data }) => {
      if (status === 403) {
        alert(data.msg);
        window.location.href = '/games';
      } else {
        return { status, data };
      }
    }).catch(errorHandler);
}

const errorHandler = ({ response }) => {
  console.log(response)
  const { data, status } = response;
  return { status, data };
  // alert(data.message);
  // console.log(status);
}
