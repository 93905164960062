import React, { useCallback, useEffect, useState } from "react";
import * as TicketService from "../../../../service/Ticket.service";
import {showLoading, hideLoading} from "../../../../helpers/layoutHelper";
import Moment from "moment";

const TeamSeriesContainer = (props) => {
  const [series, setSeries] = useState([]);

  const onClickSeries = useCallback(series => {
    if (series.opened) {
      props.history.push(`/series/${series.idx}`);
      showLoading();
    }
  }, [props.history]);

  useEffect(() => {
    document.title = "시리즈 티켓 예매";

    getSerieses().then();
  }, []);

  const getSerieses = async () => {
    showLoading();
    const {data} = await TicketService.getTeamSerieses()
    setSeries(data);
    hideLoading();
  }

  function SeriesList(props) {
    const listItems = props.series.map((series) =>
      <div key={series.idx} className="btn_reservation" onClick={() => {onClickSeries(series)}}>{series.title}<span>{series.opened ? (series.priority ? '선예매하기' : '예매하기') : `(${Moment(series.rsvOpenDatetime).format('YYYY-MM-DD HH:mm')} ${series.priority ? '선예매 오픈예정' : '오픈예정'})`}</span></div>
    );
    return (
      listItems.length > 0 ? <div className="game_list">{listItems}</div> :
        <div className="no_games">예매 가능한 경기가 없습니다.</div>
    );
  }

  return (
    <div id="content">
      <SeriesList series={series} />
    </div>
  )
}

export default TeamSeriesContainer;
