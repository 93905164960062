import {createPortal} from "react-dom";
import {hideConfirm, hideModal} from "../../helpers/layoutHelper";

const Confirm = (props) => {
  return props.messages != null ? createPortal(
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        bottom: 0,
        width: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 10000,
      }}>
      <div className="pop-wrap">
        <div className="pop-title-center" style={{maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}>
          {props.messages[0].split("<br/>").map((txt) => (
            <>
              {txt}
              <br/>
            </>
          ))}
        </div>
        <div className="pop-btn">
          <button className="gray" onClick={() => {
            hideConfirm();

            if (props.messages[3]) {
              props.messages[3]();
            }
          }}>취소</button>
          <button className="navy" onClick={() => {
            hideConfirm();

            if (props.messages[2]) {
              props.messages[2]();
            }
          }}>{props.messages[4] != null ? props.messages[4] : '확인'}</button>
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  ) : null;
}

export default Confirm;