export const SET_TOKEN = "ticket/SET_TOKEN";

export const setToken = value => ({ type: SET_TOKEN, value });

const initialState = {
  token: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.value
      };
    default:
      return state;
  }
}
