import React from "react";

const SeriesTicketComponent = (props) => {

  const seriesTypeName = {
    4: '싱글 시리즈',
    5: '월별 시리즈',
    6: '팀별 시리즈'
  }

  const handleClick = e => {
    e.preventDefault();
    props.onClickDetail(props.reservation.idx);
  }

  return (
    <div className={`list series-${props.reservation.type}`} onClick={handleClick}>
      <div className="info">
        <span className="label">{seriesTypeName[props.reservation.type]}</span>
        <div className="title">
          {props.reservation.title}
        </div>
        <div className="count">{props.reservation.moid}</div>
      </div>
      <div className="status">
        <div className="bg">
          <img src="/imgs/bg-ticket.png" srcSet="/imgs/bg-ticket@2x.png 2x, /imgs/bg-ticket@3x.png 3x"
              className="bg_ticket"/>
        </div>
        <div className="present">
          <img src="/imgs/ic-ticket-list.png" srcSet="/imgs/ic-ticket-list@2x.png 2x, /imgs/ic-ticket-list@3x.png 3x"
             className="ic_ticket_list"/>
        </div>
      </div>
    </div>
  );
}

export default SeriesTicketComponent;
