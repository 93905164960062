import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import * as TicketService from "../../../../service/Ticket.service";
import {showLoading, hideLoading, showModal} from "../../../../helpers/layoutHelper";
import Agreements from "../../../../data/agreements.json";
import PasswordComponent from "../payment/PasswordComponent";
import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination } from "swiper";
import DaumPostcode from 'react-daum-postcode';
import {isMobile} from 'react-device-detect';

const SeasonTicketContinueContainer = (props) => {
  const [user, setUser] = useState();
  const [reservation, setReservation] = useState();
  const [selectedPayMethod, setSelectedPayMethod] = useState('');
  const [ncPays, setNcPays] = useState([]);
  const [enterPassword, setEnterPassword] = useState(false);
  const [searchAddr, setSearchAddr] = useState(false);
  const [ncPayIndex, setNcPayIndex] = useState(0);
  const [zonecode, setZonecode] = useState('');
  const [addr, setAddr] = useState('');
  const [addr2, setAddr2] = useState('');
  const [shippingMemo, setShippingMemo] = useState('');
  const [nickname, setNickname] = useState('');

  const [agreements, setAgreements] = useState([
    {
      title: '서비스 이용약관 동의 (필수)',
      checked: false,
      name: 'terms',
      message: Agreements.terms,
      required: true
    },
    {
      title: '개인정보 수집 및 이용동의 (필수)',
      checked: false,
      message: Agreements.privacy,
      required: true
    },
    {
      title: '서비스 만족도 조사를 위한 개인정보 수집 및 이용 동의 (선택)',
      checked: false,
      name: 'survey',
      message: Agreements.survey,
      required: false
    },
    {
      title: 'KBO리그 SAFE캠페인 동의 (필수)',
      checked: false,
      message: Agreements.campaign,
      required: true
    },
    {
      title: 'KBO 코로나19 관람수칙 동의 (필수)',
      checked: false,
      message: Agreements.covid19,
      required: true
    },
    {
      title: '2024 NC다이노스 시즌티켓 구매 약관 (필수)',
      checked: false,
      message: Agreements.seasonTicket,
      required: true
    }
  ]);

  const prevPage = async () => {
    props.history.goBack();
  }

  useEffect(() => {
    document.title = "시즌티켓 구매";

    getSeasonTicketInfo().then();
  }, []);

  const getSeasonTicketInfo = async () => {
    showLoading();
    const {data} = await TicketService.getSeasonTicketInfo()
    setUser(data.user);
    setReservation(data.reservation);
    setNcPays(data.ncPays);
    setZonecode(data.user.postalCode);
    setAddr(data.user.address3);
    setAddr2(data.user.address4);
    hideLoading();

    if (!data.reservation) {
      alert('구매 가능한 시즌티켓이 없습니다.');
      props.history.goBack();
    }
  }

  const checkAgreements = () => {
    return agreements.reduce((a, b) => a && (!b.required || b.checked), true) && selectedPayMethod !== '' && (selectedPayMethod !== 'NCPAY' || ncPays.length>0);
  }

  const requestPay = async () => {
    if (selectedPayMethod === 'NCPAY') {
      setEnterPassword(true);
    } else {
      const {status, data} = await TicketService.startPayment(reservation.idx, null, 0, null);
      if (status === 200) {
        if (isMobile) {
          showLoading();
          document.payForm.action = "https://web.nicepay.co.kr/v3/v3Payment.jsp";
          document.payForm.EdiDate.value = data.ediDate;
          document.payForm.SignData.value = data.signData;
          document.payForm.Moid.value = data.moid;
          document.payForm.ReqReserved.value = encodeURI(JSON.stringify({
            idx: reservation.idx,
            point: 0,
            coupon: null,
            seasonTicket: 1,
            addr,
            addr2,
            zonecode,
            memo: shippingMemo,
            nickname,
            survey: agreements.find(agreement => agreement.name === 'survey').checked ? 1 : 0
          }));
          document.payForm.acceptCharset="euc-kr";
          document.payForm.submit();
        } else {
          document.payForm.EdiDate.value = data.ediDate;
          document.payForm.SignData.value = data.signData;
          document.payForm.Moid.value = data.moid;
          document.payForm.ReqReserved.value = encodeURI(JSON.stringify({
            idx: reservation.idx,
            point: 0,
            coupon: null,
            seasonTicket: 1,
            addr,
            addr2,
            zonecode,
            memo: shippingMemo,
            nickname,
            survey: agreements.find(agreement => agreement.name === 'survey').checked ? 1 : 0
          }));
          document.payForm.acceptCharset="euc-kr";
          window.goPay(document.payForm);
        }
      }
    }
  }

  const requestNcPay = async (ncPayId) => {
    setEnterPassword(false);

    const {status, data} = await TicketService.requestNcPayPayment(reservation.idx, null, ncPayId, null, null, '00', {
      addr, addr2, zonecode, memo: shippingMemo, nickname, survey: agreements.find(agreement => agreement.name === 'survey').checked ? 1 : 0
    });

    if (status === 200) {
      props.history.push(`/seasonTicket/complete`);
    } else {
      if (data.msg) {
        alert(data.msg)
      }
      hideLoading();
    }
  };

  function AgreementList(props) {
    const handleChange = index => {
      setAgreements(
        agreements.map((agreement, key) =>
          key === index ? {...agreement, checked: !agreement.checked} : agreement)
      );
    }

    const listItems = props.agreements.map((agreement, index) => {
      return (
        <li key={index}>
          <label className="form-label">
            <input type="checkbox" checked={agreement.checked} onChange={() => handleChange(index)}/>
            <span>{agreement.title}</span>
          </label>
          <span onClick={showTerms} data-message={agreement.message} data-title={agreement.title}>상세보기</span>
        </li>
      );
    });
    return (
      <div className="list">
        <ul>
          {listItems}
        </ul>
      </div>
    );
  }

  const showTerms = (e) => {
    showModal(e.target.dataset.message, e.target.dataset.title, null, 'left');
  }

  function PayMethodList() {
    let payMethods = [
      {method: 'CARD', title: '카드 결제'},
      // {method: 'BANK', title: '실시간 계좌 이체'},
      // {method: 'CELLPHONE', title: '휴대폰 결제'}
      {method: 'NCPAY', title: 'NC 다이노스 페이'},
    ]
    const listItems = payMethods.map((payMethod, index) => {
        return (
          <li key={index}>
            <input type="radio" name="payment" id={`payment_${payMethod.method}`} value={payMethod.method} checked={selectedPayMethod===payMethod.method} onChange={() => setSelectedPayMethod(payMethod.method)}/>
            <label htmlFor={`payment_${payMethod.method}`}><span>{payMethod.title}</span></label>
          </li>
        );
      })
    return (
      <ul>
        {listItems}
      </ul>
    )
  }

  const openNcPayManage = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('appLink', 'https://app.ncdinos.com/ncpayList', 'NC 다이노스 페이');
    } else {
      window.open(`https://app.ncdinos.com/ncpayList`);
    }
  }

  const NcPayList = () => {
    const listItems = ncPays.map((ncPay, index) => {
      let enabled = true;
      // if (reservation.seats.find(seat => seat.paymentTypeIdx === 12)) {
      //   if (!ncPay.cardNo.startsWith('3562-97') && !ncPay.cardNo.startsWith('4518-42')) {
      //     enabled = false;
      //   }
      // }
      return (
        <SwiperSlide key={index}>
          <div className="card-pay ncdinos">
            <i></i>
            <h6>{ncPay.cardName}카드</h6>
            <p>{ncPay.cardNo}</p>
            <div className="disabled" style={{display: enabled ? 'none' : 'block'}}>사용불가</div>
          </div>
        </SwiperSlide>
      );
    });
    return (
      <>
        <h6>NC 다이노스 페이<span onClick={openNcPayManage}>카드관리</span></h6>
        {/*<h6>NC 다이노스 페이<span>카드변경</span></h6>*/}
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="card-swiper"
          spaceBetween={20}
          initialSlide={ncPayIndex}
          onSlideChangeTransitionEnd={(swiper) => {
            if (ncPayIndex !== swiper.activeIndex) {
              setNcPayIndex(swiper.activeIndex);
            }
          }}>
          {ncPays.length > 0 ?
            listItems :
            <SwiperSlide><div className="card-pay no-card">등록된 카드가 없습니다.</div></SwiperSlide>
          }
        </Swiper>
      </>);
  }

  const onCompleteSearchAddr = (data) => {
    console.log(data);

    setZonecode(data.zonecode);
    setAddr(data.address);
    setAddr2('');
    setSearchAddr(false);
  }

  if (enterPassword) {
    return (<PasswordComponent ncPayId={ncPays[ncPayIndex].id} onPasswordConfirm={requestNcPay}/>);
  }

  return (
    reservation ?
    <div>
      {searchAddr ? 
        <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, backgroundColor: '#00000099'}}>
          <DaumPostcode style={{width: 'calc(100% - 20px)', height: 'calc(100% - 40px)', maxWidth: 600, maxHeight: 700, margin: '10px auto 0'}} autoClose onComplete={onCompleteSearchAddr} />
        </div>
      : null}
      <div id="content">
        <div className="season_ticket_seat">
          <div className="seat_info">
            <div className="icon">
              <img src="/img-won-seat.png" alt=""/>
            </div>
            <div className="info">
              <div>{reservation.zoneGroupName}</div>
              <div>{reservation.zoneName}구역 {reservation.seatLine} {reservation.seatNo}번</div>
            </div>
          </div>
        </div>
        <div className="order_info">
          <div className="title">
            구매자 정보 입력
          </div>
          <div className="user-input">
            <ul>
              <li>이름 (필수)</li>
              <li><input type="text" value={user.name || ''} readOnly={true}/></li>
              <li>휴대폰 번호 (필수)</li>
              <li><input type="text" value={user.phone || ''} readOnly={true}/></li>
              <li>닉네임 (선택)</li>
              <li><input type="text" value={nickname} onChange={(e) => {setNickname(e.target.value)}} placeholder="좌석네임태그 반영, 한글 최대 7자" maxLength={7}/></li>
              <li>배송지 주소 (선택)</li>
              <li>
                <input type="text" value={zonecode || ''} readOnly={true} style={{width: 'calc(100% - 110px)', marginBottom: 0}}/>
                <button style={{width: 100, height: 48, border: 'none', backgroundColor: '#a4a4a4', color: 'white', fontSize: 14, marginLeft: 10}} onClick={() => {setSearchAddr(true);}}>우편번호 검색</button>
              </li>
              <li><input type="text" value={addr || ''} readOnly={true} style={{marginBottom: 0}}/></li>
              <li><input type="text" value={addr2 || ''} onChange={(e) => {setAddr2(e.target.value)}} placeholder="상세주소"/></li>
              <li>배송메모</li>
              <li><input type="text" value={shippingMemo || ''} onChange={(e) => {setShippingMemo(e.target.value)}}/></li>
            </ul>
          </div>
        </div>

        <div className="pay-price">
          <div className="title">최종 결제 금액</div>
          <ul>
            <li><span>시즌티켓 금액</span><span><NumberFormat value={reservation.totalPrice} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
            <li><span>{user.seasonTicketYear}년차 할인({user.seasonTicketYear}%)</span><span><NumberFormat value={reservation.seasonTicketDiscountAmount} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
            <li className="total-price"><span>총 결제 금액</span><span><NumberFormat value={reservation.totalPrice - reservation.seasonTicketDiscountAmount} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
          </ul>
          {/* <h6>취소 기간 및 취소 수수료 안내</h6> */}
          {/* <p dangerouslySetInnerHTML={{__html: `${seriesReservationIdx ? '&#8901; 이 상품은 패키지 상품으로 부분취소가 되지 않습니다(일괄취소, 우천취소 제외)<br/>' : ''}&#8901; 예매당일 : 취소수수료 없음, 예매수수료 환불 (경기당일 제외)<br/>&#8901; 예매익일 ~ 경기시작 4시간 전 : 예매수수료 환불 불가, 티켓 금액의 10% 취소 수수료 발생<br/>&#8901; 경기시작 4시간 전 부터 취소 불가<br/>&#8901; 경기 당일 예매 후 취소 시 : 예매수수료 환불 불가, 티켓 금액의 10% 취소수수료 발생<br/>&#8901; 마산야구센터 내 주차공간이 부족합니다. 대중교통 이용을 부탁드리며 주차 및 교통난으로 인한 예매 티켓 취소, 환불, 변경이 불가합니다.`}}></p> */}
          {/* <label><input type="checkbox" checked={cancelFeeAgreed} onChange={() => setCancelFeeAgreed(!cancelFeeAgreed)} /> <span>취소 기간 및 취소 수수료에 동의합니다.</span></label> */}
        </div>

        <div className="payment-way">
          <div className="title">결제 방법 선택</div>
          <PayMethodList />
          {selectedPayMethod === 'NCPAY' ? <NcPayList /> : null}
        </div>

        <div className="agreement">
          <div className="title">
            약관 동의
            <label><input type="checkbox" onChange={(e) => setAgreements(agreements.map((agreement) => {
              return {
                ...agreement,
                checked: e.target.checked
              }
            }))}/><span>모두 동의</span></label>
          </div>
          <AgreementList agreements={agreements} />
        </div>

        <div className="ticket_summary">
          <div>
            <span>총 결제금액</span><span><NumberFormat value={reservation.totalPrice - reservation.seasonTicketDiscountAmount} displayType={"text"} thousandSeparator={true}/>원</span>
          </div>
        </div>
      </div>
      <footer>
        <div className="prev" onClick={prevPage}>이전단계</div>
        <div className={`next${checkAgreements() ? '' : ' disabled'}`} onClick={checkAgreements() ? requestPay : null}>결제하기</div>
      </footer>
      <form name="payForm" method="post" action={`${process.env.REACT_APP_TICKET_API_URL}/paymentConfirm`}>
        <input type="hidden" name="GoodsName" value={reservation.reservationName}/>
        <input type="hidden" name="Amt" value={reservation.totalPrice - reservation.seasonTicketDiscountAmount}/>
        <input type="hidden" name="MID" value={process.env.REACT_APP_NICEPAY_MID}/>
        <input type="hidden" name="EdiDate"/>
        <input type="hidden" name="Moid"/>
        <input type="hidden" name="SignData"/>
        <input type="hidden" name="BuyerName" value={user.name}/>
        <input type="hidden" name="BuyerTel" value={user.phone}/>
        <input type="hidden" name="ReturnURL" value={`${process.env.REACT_APP_TICKET_API_URL}/paymentConfirm`}/>
        <input type="hidden" name="BuyerEmail" value={user.email}/>
        <input type="hidden" name="CharSet" value="utf-8"/>
        <input type="hidden" name="VbankExpDate" id="vExp"/>
        <input type="hidden" name="GoodsCl" value={1}/>
        <input type="hidden" name="WapUrl" value="ncdinos://"/>
        <input type="hidden" name="PayMethod" value={selectedPayMethod}/>
        <input type="hidden" name="ReqReserved"/>
      </form>
    </div> :
    <>
    </>
  );
}

export default SeasonTicketContinueContainer;