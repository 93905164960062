import React, { useState, useEffect } from 'react';

const Header = () => {
  const [title, setTitle] = useState(document.title);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      if (mutations[0].target.text != null) {
        setTitle(mutations[0].target.text);
      }
    })
    observer.observe(document.querySelector("title"), {
      subtree: true,
      characterData: true,
      childList: true,
    })
    return () => observer.disconnect()
  }, []);

  return (
    <div>
      <header>
        {title}
      </header>
      <div id="top_dummy"/>
      <img src="/imgs/ic-profile@2x.png" style={{position: 'absolute', width: 20, height: 20, right: 20, top: 20, zIndex: 1000, display: 'none'}}/>
    </div>
  );
};

export default Header;
