import Moment from 'moment';
import { teamData } from "../../../../data/team"

const SeriesComponent = (props) => {

  const handleClick = (e) => {
    e.preventDefault();
    props.onClickSeries(props.series);
  }

  return (
    <div className="series-list">
      <div className={'team-info'}>
        <span className={'label type-4'}>싱글 시리즈</span>
        {props.series.awayTeamId ?
        <ul className={'logo'}>
          <li><img src="/imgs/NC.png"/></li>
          <li>VS</li>
          <li><img src={`/imgs/${teamData[props.series.awayTeamId].logo}`}/></li>
        </ul>
        : <ul className={'logo'}></ul>}
        <ul className={'team'}>
          <li>
            {props.series.awayTeamId ?
            <p>NC vs {teamData[props.series.awayTeamId].shortName}</p>
            : <p></p>}
            <ul>
              <li>{Moment(props.series.playDatetimes.split('|')[0]).format('YYYY.MM.DD(ddd) HH:mm')}</li>
              <li>{Moment(props.series.playDatetimes.split('|')[1]).format('YYYY.MM.DD(ddd) HH:mm')}</li>
              {props.series.playDatetimes.split('|').length > 2 &&
              <li>{Moment(props.series.playDatetimes.split('|')[2]).format('YYYY.MM.DD(ddd) HH:mm')}</li>
              }
            </ul>
            <ul>
              <li>{props.series.stadiumName}</li>
            </ul>
          </li>
        </ul>
      </div>
      <button className={props.series.opened ? "type-4" : "gray"} onClick={props.series.opened ? handleClick : null}>
        {props.series.opened ? (props.series.priority ? '선예매하기' : '예매하기') : `(${Moment(props.series.rsvOpenDatetime).format('YYYY-MM-DD HH:mm')} ${props.series.priority ? '선예매 오픈예정' : '오픈예정'})`}
      </button>
    </div>
  )
}

export default SeriesComponent;
