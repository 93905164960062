import React, {useEffect, useState} from 'react';
import {hideLoading, showLoading, showConfirm} from "../../../../helpers/layoutHelper";
import * as TicketService from "../../../../service/Ticket.service";
import NumberFormat from "react-number-format";
import Moment from "moment";

const SubscriptionContainer = (props) => {

  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    document.title = "구독 서비스 현황";

    const fetchData = async () => {
      const {data} = await TicketService.getSubscriptions();
      console.log(data);
      setSubscriptions(data.subscriptions);
      hideLoading();
    }

    showLoading();
    fetchData();
  }, []);

  const cancelSubscription = async (subscriptionIdx) => {
    showLoading();

    const {status, data} = await TicketService.cancelSubscription(subscriptionIdx);
    if (status === 200) {
    } else if (data.msg) {
      alert(data.msg);
    } else {
      alert('구독을 취소할 수 없습니다.');
    }

    window.location.reload();
  }

  return (
    <>
      {
      subscriptions.length === 0 ?
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, height: '100%', paddingBottom: 80}}>
        <img src="/imgs/img-no-subscribe.png" alt="" style={{width: 72, height: 72}} />
        <p style={{color: '#747474'}}>구독 중인 서비스가 없습니다.</p>
      </div>
      :
      <ul className='subscription'>
        {
          subscriptions.map(subscription =>
          <li key={subscription.idx}>
            <span className={subscription.status <= -2 ? 'cancel' : ''}>{subscription.status === -3 ? '구독취소' : (subscription.status === -2 ? '구독만료' : (subscription.status === -1 ? '구독만료예정' : '구독중'))}</span>
            {/* {subscription.status === -1 &&
              <span>(8/31 구독형티켓 판매종료)</span>
            } */}
            <h6>NC다이노스 구독형 D티켓{(subscription.status >= 0 || (subscription.status === -1 && subscription.voucherCnt >= 3)) && <span onClick={() => setSelectedSubscription(subscription)}>구독취소</span>}</h6>
            <div>사용 가능 예매권 현황<span><span>{subscription.voucherCnt}</span> / 3장</span></div>
            <ul>
              <li>구독 시작일<span>{Moment(subscription.startDatetime).format('YYYY년 MM월 DD일')}</span></li>
              {subscription.status >= 0 ?
                <>
                <li>다음 결제일<span>{Moment(subscription.nextPaymentDate).format('YYYY년 MM월 DD일')}</span></li>
                <li>결제금액<span><NumberFormat value={subscription.price} displayType={"text"} thousandSeparator={true}/>원</span></li>
                </>
                :
                <li>구독 종료일<span>{Moment(subscription.expireDate).format('YYYY년 MM월 DD일')}</span></li>
              }
              {subscription.status === -3 &&
                <li>환불 금액<span><NumberFormat value={subscription.refundAmount} displayType={"text"} thousandSeparator={true}/>원</span></li>
              }
            </ul>
          </li>)
        }
      </ul>
      }

      {selectedSubscription != null &&
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        bottom: 0,
        width: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 10000,
      }}>
        <div className="pop-wrap">
          <div className="pop-cont">
            <p style={{padding: '16px 0 8px', fontSize: 13, textAlign: 'left'}}>
              {
                selectedSubscription.voucherCnt < 3 ?
                <div style={{textAlign: 'center', padding: 16, marginBottom: 24, background: '#F8F8F8', borderRadius: 2, fontSize: 13, color: '#000', fontWeight: 500}}>이미 예매권을 사용해 환불이 불가합니다.<br/>보유한 예매권은 사용이 가능합니다.<br/>다음 달 구독을 취소하시겠습니까?</div>
                :
                <div style={{padding: 16, marginBottom: 24, background: '#F8F8F8', borderRadius: 2, fontSize: 14, fontWeight: 500}}>환불 금액 : <span style={{float: 'right', fontWeight: 700}}><NumberFormat value={selectedSubscription.noFee ? selectedSubscription.price : parseInt(selectedSubscription.price * 0.9)} displayType={"text"} thousandSeparator={true}/></span></div>
              }
              <h3 style={{fontSize: 14, marginBottom: 4, textAlign: 'center'}}>취소 및 환불 안내</h3>
              1. 전액환불: 결제(구매) 후 <span style={{color: '#FF3529'}}>7일 이내 예매권을 한 번도 이용하지 않은 경우</span><br/>
              2. 위약금(10%) 발생: 결제(구매) 후 <span style={{color: '#FF3529'}}>7일 이후 예매권을 한 번도 이용하지 않은 경우</span><br/>
              3. 구매 후 예매권을 한 번이라도 사용한 경우 환불 불가, 취소 진행 시 익월 구독 약정 해지 반영<br/>
              4. 정기 구독 중 취소 신청<br/>
                  - 결제 후 7일 이내이면서 혜택을 한 번도 이용하지 않는 경우: 전액환불, 당월 예매권 3매 회수<br/>
                  - 결제 후 7일 이후이면서 혜택을 한 번도 이용하지 않는 경우: 위약금 공제 후 환불, 당월 예매권 3매 회수<br/>
                  - 결제 후 한번이라도 예매권을 사용한 경우: 예매권 3매 사용 유효, 다음 달 결제일에 최종 약정 해지
            </p>
          </div>
          <div className="pop-btn">
            <button className="gray" onClick={() => {
              setSelectedSubscription(null);
            }}>취소</button>
            <button className="navy" onClick={async () => {
              setSelectedSubscription(null);
              cancelSubscription(selectedSubscription.idx);
            }}>구독 취소 하기</button>
          </div>
        </div>
      </div>
      }
    </>
  );
}

export default SubscriptionContainer;