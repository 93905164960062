import { useEffect } from "react";
import { showLoading } from "../../../../helpers/layoutHelper";
import * as TicketService from "../../../../service/Ticket.service";

const EventContainer = (props) => {

  useEffect(() => {
    showLoading();

    const fetchData = async () => {
      const {status, data} = await TicketService.getEventDetail(props.match.params.eventIdx);

      if (status === 200) {
        props.history.replace(data.url);
      } else {
        if (data.msg) {
          alert(data.msg);
        }
        if (window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler('close');
        } else {
          props.history.goBack();
        }
      }
    }

    fetchData();
  }, []);

  return (
    <></>
  );
}

export default EventContainer;
