export const teamData = {
  "NC": {
    "name": "NC 다이노스",
    "shortName": "NC",
    "logo": "NC.png"
  },
  "OB": {
    "name": "두산 베어스",
    "shortName": "두산",
    "logo": "OB.png"
  },
  "KT": {
    "name": "KT 위즈",
    "shortName": "KT",
    "logo": "KT.png"
  },
  "LG": {
    "name": "LG 트윈스",
    "shortName": "LG",
    "logo": "LG.png"
  },
  "WO": {
    "name": "키움 히어로즈",
    "shortName": "키움",
    "logo": "WO.png"
  },
  "HT": {
    "name": "KIA 타이거즈",
    "shortName": "KIA",
    "logo": "HT.png"
  },
  "LT": {
    "name": "롯데 자이언츠",
    "shortName": "롯데",
    "logo": "LT.png"
  },
  "SS": {
    "name": "삼성 라이온즈",
    "shortName": "삼성",
    "logo": "SS.png"
  },
  "SK": {
    "name": "SSG 랜더스",
    "shortName": "SSG",
    "logo": "SK.png"
  },
  "HH": {
    "name": "한화 이글스",
    "shortName": "한화",
    "logo": "HH.png"
  }
}
