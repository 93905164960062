
const OpenPracticePurchaseCompleteContainer = (props) => {
  const reservationIdx = parseInt(props.match.params.reservationIdx);
  const reservationSeatIdx = parseInt(props.match.params.reservationSeatIdx);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        bottom: 0,
        width: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 10000,
      }}>
      <div className="pop-wrap">
        <div className="pop-title">
          구매가 완료되었습니다.
        </div>
        <div className="pop-cont">
          해당 티켓을 선물할 경우<br/>오픈 프랙티스 입장권도 선물이 됩니다.
        </div>
        <div className="pop-btn">
          <button className="navy" onClick={() => {
            props.history.push(`/mypage/${reservationIdx}#${reservationSeatIdx}`);
          }}>확인</button>
        </div>
      </div>
    </div>
  );
}

export default OpenPracticePurchaseCompleteContainer;