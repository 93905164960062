import { store } from "../index";
import {setConfirmMessages, setIsLoading, setModalMessages} from "../store/layout";

export const showLoading = () => {
    store.dispatch(setIsLoading(true));
}

export const hideLoading = () => {
    store.dispatch(setIsLoading(false));
}

export const showModal = (message, title = null, callback = null, align = 'center') => {
    store.dispatch(setModalMessages([message, title, callback, align]));
}

export const hideModal = () => {
    store.dispatch(setModalMessages(null));
}

export const showConfirm = (message, title = null, confirmCallback = null, cancelCallback = null, confirmButton = null) => {
    store.dispatch(setConfirmMessages([message, title, confirmCallback, cancelCallback, confirmButton]));
}

export const hideConfirm = () => {
    store.dispatch(setConfirmMessages(null));
}
