import React, {useCallback, useEffect, useState} from 'react';
import ReservationComponent from "./ReservationComponent";
import * as TicketService from "../../../../service/Ticket.service";
import {showLoading, hideLoading} from "../../../../helpers/layoutHelper";

const SeriesTicketListContainer = (props) => {
  const seriesReservationIdx = parseInt(props.match.params.seriesReservationIdx);
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    document.title = "티켓 보관함";
  }, [])

  useEffect(() => {
    showLoading();

    TicketService.getSeriesReservations(seriesReservationIdx).then(response => {
      setReservations(response.data.reservations);
      hideLoading();
    });
  }, [seriesReservationIdx]);

  const onClickDetail = useCallback(reservationIdx => {
    props.history.push(`/mypage/${reservationIdx}`);
    showLoading();
  }, [props.history]);

  return (
    <div id="content">
      <div className="ticket">
        <div className="ticket_list">
          {
            reservations.length > 0 ?
            <>
              {reservations.map(reservation => <ReservationComponent key={reservation.idx} reservation={reservation} onClickDetail={onClickDetail} />)}
            </>
            :
            <div className="no_games">예매 내역이 없습니다.</div>
          }
        </div>
      </div>
    </div>
  );
}

export default SeriesTicketListContainer;
