import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBSTUCNM2r643udBhTkM9fi6Z_t_mArFg4",
  authDomain: "nc-dinos-product.firebaseapp.com",
  databaseURL: "https://nc-dinos-product.firebaseio.com",
  projectId: "nc-dinos-product",
  storageBucket: "nc-dinos-product.appspot.com",
  messagingSenderId: "438248360122",
  appId: "1:438248360122:web:5c7254426d50d022a55a83",
  measurementId: "G-W3B8W9R4L1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const store = createStore(
  rootReducer,
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/*<ScrollToTop>*/}
          <App />
        {/*</ScrollToTop>*/}
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
