import React from "react";
import { teamData } from "../../../../data/team"
import Moment from "moment";

const ReservationComponent = (props) => {

  const handleClick = e => {
    e.preventDefault();
    if (props.reservation.type === 1) {
      props.onClickSeasonTicketGiftDetail(props.reservation.idx, props.reservation.gameIdx);
    } else {
      props.onClickDetail(props.reservation.idx);
    }
  }

  const Badge = (props) => {
    if (props.reservation.gift) {
      return <div className="present">
        <img src="/imgs/ic-ticket-list.png" srcSet="/imgs/ic-ticket-list@2x.png 2x, /imgs/ic-ticket-list@3x.png 3x"
             className="ic_ticket_list"/>
      </div>
    } else if (props.reservation.status === -2) {
      return <div className="cancel">
        취소
      </div>
    } else {
      return <div className="before">
        사용 전
      </div>
    }
  }

  const typeName = {
    0: '일반경기',
    1: '시즌티켓',
    2: '시즌티켓',
    3: '오너스클럽',
    4: '싱글 시리즈',
    5: '월별 시리즈',
    6: '팀별 시리즈',
    7: '이벤트'
  }

  return (
    <div className="list normal" onClick={handleClick}>
      <div className="info">
        <span className={`label type-${props.reservation.type}`}>{typeName[props.reservation.type]}</span>
        <div className="title">
          {props.reservation.eventTitle ? props.reservation.eventTitle : `NC vs ${teamData[props.reservation.awayTeamId].shortName}`}{props.reservation.doubleHeader>0 && ` ${props.reservation.doubleHeader}차전`}
          <span className="date">{props.reservation.gameIdx === 214 ? '10.15 경기종료 후' : (props.reservation.playDatetime ? Moment(props.reservation.playDatetime).format('YYYY.MM.DD(ddd) HH:mm') : '미편성 경기')}</span>
        </div>
        <div className="count">{props.reservation.moid}</div>
      </div>
      {/* <div className="info">
        <div className="team">
          {props.reservation.eventTitle ? props.reservation.eventTitle : `NC vs ${teamData[props.reservation.awayTeamId].shortName}`}
        </div>
        <div className="date">
          {props.reservation.gameIdx === 110 ? '2022.10.08 경기종료 후' : Moment(props.reservation.playDatetime).format('YYYY.MM.DD(ddd) HH:mm')}, {props.reservation.stadiumName}
        </div>
        {
          props.reservation.gameIdx === 111 || props.reservation.gameIdx === 112 ?
            <div className="count">
              총 {props.reservation.seatCnt}장{props.reservation.gift ? `(${props.reservation.userName}님이 선물한 티켓)` : ''}
              {props.reservation.canceledCnt>0 ? <span> [{props.reservation.canceledCnt}장 취소]</span> : null}<br/>
              예매번호 : {props.reservation.moid}
            </div>
            :
            <div className="count">
              총 {props.reservation.seatCnt}장({props.reservation.gift ? `${props.reservation.userName}님이 선물한 티켓` : `${Moment(props.reservation.completedDatetime).format('YYYY.MM.DD')}일 예매`})
              {props.reservation.canceledCnt>0 ? <span> [{props.reservation.canceledCnt}장 취소]</span> : null}<br/>
              예매번호 : {props.reservation.moid}
            </div>
        }
        <div className="emblem">
          <img src={`/imgs/${teamData[props.reservation.awayTeamId].logo}`} srcSet={`/imgs/img-embelm-${props.reservation.awayTeamId}-60@2x.png 2x, /imgs/img-embelm-${props.reservation.awayTeamId}-60@3x.png 3x`}/>
        </div>
      </div> */}
      <div className="status">
        <div className="bg">
          <img src="/imgs/bg-ticket-normal.png" srcSet="/imgs/bg-ticket-normal@2x.png 2x, /imgs/bg-ticket-normal@3x.png 3x"
                className="bg_ticket"/>
        </div>
        <div className="present">
          <img src="/imgs/ic-ticket-normal-list.png" srcSet="/imgs/ic-ticket-normal-list@2x.png 2x, /imgs/ic-ticket-normal-list@3x.png 3x"
             className="ic_ticket_list"/>
        </div>
        {/* <Badge reservation={props.reservation} /> */}
      </div>
    </div>
  );
}

export default ReservationComponent;
