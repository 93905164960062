import React, {useEffect, useRef, useState} from "react";
import NumberFormat from "react-number-format";
import {showModal} from "../../../helpers/layoutHelper";

const ZoneComponent = (props) => {
  const ref = useRef();

  useEffect(() => {
    if(props.autoScroll && props.active) {
      ref.current.scrollIntoView({block: 'center'});
    }
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    props.onSelectZone(props.zone.idx, false);
  }

  const showDpInfo = () => {
    showModal("NC 다이노스의 티켓은 <b>다이내믹 프라이싱</b>을 적용하여 매경기 상황에 맞게 탄력적으로 티켓 가격을 책정하고 있습니다.\n<b>다이내믹 프라이싱</b>이란 각종 경기 데이터를 기반으로 좌석 수요를 예측하고, 티켓 가격을 유동적으로 결정하는 방식을 의미합니다.");
  }

  return (
    <div ref={ref} className={`sub_list${props.active ? ' selected' : ''}`} onClick={!props.active ? handleClick : null}>
      <div>
        {props.zone.name}블록
        <div className="seat">{props.zone.cnt || 0}<span> 석</span></div>
      </div>
    </div>
  )
}

export default ZoneComponent
