import React, {useEffect, useState} from "react";
import {hideLoading, showLoading} from "../../../../helpers/layoutHelper";
import * as TicketService from "../../../../service/Ticket.service";

const PasswordComponent = (props) => {

  const [password, setPassword] = useState('');
  const [wrongPasswordCnt, setWrongPasswordCnt] = useState(0);

  useEffect(async () => {
    if (password.length === 6) {
      showLoading();

      const {status, data} = await TicketService.confirmNcPayPassword(props.ncPayId, password);
      if (status === 200) {
        props.onPasswordConfirm(props.ncPayId);
      } else {
        setWrongPasswordCnt(1);
        setPassword('');
        hideLoading();
      }
    }
  }, [password]);

  const handleClickKeypad = (key) => {
    if (password.length < 6) {
      setPassword(password + key);
    }
  }

  const resetPassword = () => {
    setPassword('');
  }

  const deletePassword = () => {
    if (password.length > 0) {
      setPassword(password.substr(0, password.length -1));
    }
  }

  return (
    <content>
      <div className="pass_register">
        <div className="title">
          비밀번호 등록
        </div>
        <div className="sub_title">
          비밀번호를 입력해주세요.
        </div>
        <div className="pass">
          <div className="input"><div className={password.length>=1 ? 'filled' : null}/></div>
          <div className="input"><div className={password.length>=2 ? 'filled' : null}/></div>
          <div className="input"><div className={password.length>=3 ? 'filled' : null}/></div>
          <div className="input"><div className={password.length>=4 ? 'filled' : null}/></div>
          <div className="input"><div className={password.length>=5 ? 'filled' : null}/></div>
          <div className="input"><div className={password.length>=6 ? 'filled' : null}/></div>
        </div>
        {wrongPasswordCnt > 0 ?
          <div className="pass_error">비밀번호가 일치하지 않습니다.</div> : null
        }
        <div className="keypad">
          <button onMouseDown={() => handleClickKeypad('1')}>1</button>
          <button onMouseDown={() => handleClickKeypad('2')}>2</button>
          <button onMouseDown={() => handleClickKeypad('3')}>3</button>
          <button onMouseDown={() => handleClickKeypad('4')}>4</button>
          <button onMouseDown={() => handleClickKeypad('5')}>5</button>
          <button onMouseDown={() => handleClickKeypad('6')}>6</button>
          <button onMouseDown={() => handleClickKeypad('7')}>7</button>
          <button onMouseDown={() => handleClickKeypad('8')}>8</button>
          <button onMouseDown={() => handleClickKeypad('9')}>9</button>
          <button onMouseDown={resetPassword}>Reset</button>
          <button onMouseDown={() => handleClickKeypad('0')}>0</button>
          <button onMouseDown={deletePassword}>삭제</button>
        </div>
      </div>
    </content>
  );
}

export default PasswordComponent;