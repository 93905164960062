import React, {useEffect, useState} from 'react';
import * as TicketService from "../../../../service/Ticket.service";
import Agreements from "../../../../data/agreements.json";
import {hideLoading, showLoading, showModal} from "../../../../helpers/layoutHelper";
import PasswordComponent from "../../reservation/payment/PasswordComponent";
import NumberFormat from "react-number-format";
import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination } from "swiper";

const SubscribePaymentContainer = (props) => {

  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [ncPays, setNcPays] = useState([]);
  const [ncPayIndex, setNcPayIndex] = useState(0);
  const [enterPassword, setEnterPassword] = useState(false);
  const [subscriptionPrice, setSubscriptionPrice] = useState(0);
  const [agreements, setAgreements] = useState([
    {
      title: '서비스 이용약관 동의 (필수)',
      checked: false,
      name: 'terms',
      message: Agreements.terms
    },
    {
      title: '개인정보 수집 및 이용동의 (필수)',
      checked: false,
      message: Agreements.privacy
    },
    {
      title: 'KBO리그 SAFE캠페인 동의 (필수)',
      checked: false,
      message: Agreements.campaign
    },
    // {
    //   title: 'KBO 코로나19 관람수칙 동의 (필수)',
    //   checked: false,
    //   message: Agreements.covid19
    // },
    {
      title: '구독형 D티켓 약관 동의(필수)',
      checked: false,
      message: Agreements.subscription
    },
    {
      title: '정기과금(자동결제) 이용 약관 동의(필수)',
      checked: false,
      message: Agreements.autorenewal
    },
    {
      title: '매월 정기결제에 동의합니다(필수)',
      checked: false
    },
  ]);

  useEffect(() => {
    document.title = "티켓 예매";

    const fetchData = async () => {
      {
        const {data} = await TicketService.requestMyWallet();
        setUserName(data.name);
        setUserPhone(data.phone);
        setUserEmail(data.email);
        setNcPays(data.ncPays);

        const response = await TicketService.getSeasonInfo();
        if (response.status === 200) {
          setSubscriptionPrice(response.data.subscriptionPrice);
        }
      }
      hideLoading();
    }

    fetchData();
  }, []);
  
  const requestPay = async () => {
    setEnterPassword(false);

    const {status, data} = await TicketService.requestSubscription(ncPays[ncPayIndex].id, userName, userPhone, userEmail);

    if (status === 200) {
      props.history.push(`/reservation/complete`);
    } else {
      if (data.msg) {
        alert(data.msg)
      }
      hideLoading();
    }
  }

  const checkAgreements = () => {
    return agreements.reduce((a, b) => a && b.checked, true) && ncPays.length>0;
  }

  const openNcPayManage = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('appLink', 'https://app.ncdinos.com/ncpayList', 'NC 다이노스 페이');
    } else {
      window.open(`https://app.ncdinos.com/ncpayList`);
    }
  }

  const NcPayList = () => {
    const listItems = ncPays.map((ncPay, index) => {
      let enabled = true;
      return (
        <SwiperSlide key={index}>
          <div className="card-pay ncdinos">
            <i></i>
            <h6>{ncPay.cardName}카드</h6>
            <p>{ncPay.cardNo}</p>
            <div className="disabled" style={{display: enabled ? 'none' : 'block'}}>사용불가</div>
          </div>
        </SwiperSlide>
      );
    });
    return (
      <>
        <h6>정기결제 카드<span onClick={openNcPayManage}>카드관리</span></h6>
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="card-swiper"
          spaceBetween={20}
          initialSlide={ncPayIndex}
          onSlideChangeTransitionEnd={(swiper) => {
            if (ncPayIndex !== swiper.activeIndex) {
              setNcPayIndex(swiper.activeIndex);
            }
          }}>
          {ncPays.length > 0 ?
            listItems :
            <SwiperSlide><div className="card-pay no-card">등록된 카드가 없습니다.<span style={{position: 'absolute', fontSize: 12, lineHeight: '16px', top: '90px', left: '50%', transform: 'translate(-50%, 0)', textDecoration: 'underline'}} onClick={() => window.location.reload()}>새로고침</span></div></SwiperSlide>
          }
        </Swiper>
      </>);
  }

  const showTerms = (e) => {
    showModal(e.target.dataset.message, e.target.dataset.title, null, 'left');
  }

  function AgreementList(props) {
    const handleChange = index => {
      setAgreements(
        agreements.map((agreement, key) =>
          key === index ? {...agreement, checked: !agreement.checked} : agreement)
      );
    }

    const listItems = props.agreements.map((agreement, index) => {
      return (
        <li key={index}>
          <label className="form-label">
            <input type="checkbox" checked={agreement.checked} onChange={() => handleChange(index)}/>
            <span>{agreement.title}</span>
          </label>
          {agreement.message != null &&
            <span onClick={showTerms} data-message={agreement.message} data-title={agreement.title}>상세보기</span>
          }
        </li>
      );
    });
    return (
      <div className="list">
        <ul>
          {listItems}
        </ul>
      </div>
    );
  }

  if (enterPassword) {
    return (<PasswordComponent ncPayId={ncPays[ncPayIndex].id} onPasswordConfirm={requestPay}/>);
  }

  return (
    subscriptionPrice !== 0 &&
    <div>
      <div id="content">
        <div className="order_info">
          <div className="title">
            구매자 정보 입력
          </div>
          <div className="user-input">
            <ul>
              <li>이름 (필수)</li>
              <li><input type="text" value={userName || ''} readOnly={true}/></li>
              <li>휴대폰 번호 (필수)</li>
              <li><input type="text" value={userPhone || ''} readOnly={true}/></li>
              <li>이메일 (필수)</li>
              <li><input type="email" value={userEmail || ''} onChange={(e) => {setUserEmail(e.target.value)}}/></li>
            </ul>
          </div>
        </div>

        <div className="pay-price">
          <div className="title">최종 결제 금액</div>
          <ul>
            <li><span>티켓 금액</span><span><NumberFormat value={subscriptionPrice} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
            <li className="total-price"><span>총 결제 금액</span><span><NumberFormat value={subscriptionPrice} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
          </ul>
          <div className="refund">
            <h6>취소 및 환불 안내</h6>
            <ul>
              <li>- 전액환불 : 결제(구매) 후 7일 이내 예매권을 한 번도 이용하지 않은 경우</li>
              <li>- 위약금(10%) 발생: 결제(구매) 후 7일 이후 예매권을 한 번도 이용하지 않은 경우</li>
              <li>- 구매 후 예매권을 한 번이라도 사용한 경우 환불 불가, 취소 진행 시 익월 구독 약정 해지 반영</li>
              <li>- 정기 구독 중 취소 신청
                <ul>
                  <li>1. 결제 후 7일 이내이면서 혜택을 한 번도 이용하지 않는 경우 : 전액환불, 당월 예매권 3매 회수</li>
                  <li>2. 결제 후 7일 이후이면서 혜택을 한 번도 이용하지 않는 경우 : 위약금 공제 후 환불, 당월 예매권 3매 회수</li>
                  <li>3. 결제 후 한번이라도 예매권을 사용한 경우 : 예매권 3매 사용 유효, 다음 달 결제일에 최종 약정 해지</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="payment-way">
          <div className="title">결제 방법 선택</div>
          <NcPayList />
        </div>

        <div className="agreement">
          <div className="title">
            약관 동의
            <label><input type="checkbox" onChange={(e) => setAgreements(agreements.map((agreement) => {
              return {
                ...agreement,
                checked: e.target.checked
              }
            }))}/><span>모두 동의</span></label>
          </div>
          <AgreementList agreements={agreements} />
        </div>

        <div className="ticket_summary">
          <div>
            <span>총 결제금액</span><span><NumberFormat value={subscriptionPrice} displayType={"text"} thousandSeparator={true}/>원</span>
          </div>
        </div>
      </div>
      <footer>
        <div className="prev" onClick={() => {props.history.goBack()}}>이전단계</div>
        <div className={`next${checkAgreements() ? '' : ' disabled'}`} onClick={checkAgreements() ? () => {setEnterPassword(true)} : null}>결제하기</div>
      </footer>
    </div>
  );
}

export default SubscribePaymentContainer;