import React, { useEffect } from 'react';
import {hideLoading} from "../../../../helpers/layoutHelper";

const SeasonTicketCompleteContainer = (props) => {
  const moveHome = () => {
    props.history.push(`/games`);
  }

  useEffect(() => {
    document.title = "구매 완료"

    hideLoading();
  }, []);

  return (
    <div id="content">
      <div className="complete">
        <img src="/complete.png" alt="티켓 구매 완료"/>
        <div className="txt1">
          구매완료 되었습니다. 감사합니다.
        </div>
        <div className="txt2">
          <h4 style={{color: 'black',fontWeight:'bold',marginBottom:4}}>시즌권 배송안내</h4>
          시즌권 배송일정은 추후 확정되면<br/>
          홈페이지에 공지하도록 하겠습니다.
        </div>
        <button onClick={moveHome}>홈으로 돌아가기</button>
      </div>
    </div>
  );
}

export default SeasonTicketCompleteContainer;
