const SET_IS_LOADING = 'layout/SET_IS_LOADING';
const SET_MODAL_MESSAGES = 'layout/SET_MODAL_MESSAGES';
const SET_CONFIRM_MESSAGES = 'layout/SET_CONFIRM_MESSAGES';

export const setIsLoading = value => ({ type: SET_IS_LOADING, value });
export const setModalMessages = value => ({ type: SET_MODAL_MESSAGES, value });
export const setConfirmMessages = value => ({ type: SET_CONFIRM_MESSAGES, value });

const initialState = {
  isLoading: true,
  modalMessages: null,
  confirmMessages: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.value
      };
    case SET_MODAL_MESSAGES:
      return {
        ...state,
        modalMessages: action.value
      };
    case SET_CONFIRM_MESSAGES:
      return {
        ...state,
        confirmMessages: action.value
      };
    default:
      return state;
  }
}
