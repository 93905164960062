import {createPortal} from "react-dom";
import {hideModal} from "../../helpers/layoutHelper";

const Modal = (props) => {
  return props.messages != null ? createPortal(
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        bottom: 0,
        width: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 10000,
      }}>
      <div className="pop-wrap">
        {props.messages[1] != null ?
        <div className="pop-title">
          {props.messages[1]}
        </div> : null}
        <div className={`pop-cont ${props.messages[3]}`}>
          <div dangerouslySetInnerHTML={{__html: props.messages[0].replaceAll("\n", "<br/>")}} />
        </div>
        <div className="pop-btn">
          <button className="navy" onClick={() => {
            hideModal();
            if (props.messages[2]) {
              props.messages[2]();
            }
          }}>확인</button>
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  ) : null;
}

export default Modal;