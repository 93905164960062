import React, { useCallback, useEffect, useState } from "react";
import * as TicketService from "../../../../service/Ticket.service";
import MatchComponent from "./MatchComponent";
import {showLoading, hideLoading, showModal} from "../../../../helpers/layoutHelper";
import Moment from "moment";

const MatchContainer = (props) => {
  const [events, setEvents] = useState([]);
  const [matches, setMatches] = useState([]);
  const [banners, setBanners] = useState([]);
  const [seasonTicket, setSeasonTicket] = useState({});

  const onClickMatch = useCallback(match => {
    if (window.location.pathname.startsWith("/exclude")) {
      props.history.push(`/exclude/${match.idx}`);
    } else {
      props.history.push(`/games/${match.idx}`);
    }
    showLoading();
  }, []);

  useEffect(() => {
    document.title = "티켓 예매";

    getMatches().then();
  }, []);

  const moveMypage = () => {
    props.history.push(`/mypage`);
  }

  const movePayment = (reservationIdx) => {
    showModal("&#8901; 예매 수수료, 취소 수수료 모두 일반 홈경기 티켓과 동일한 정책에 따릅니다.\n&#8901; 23시 40분부터는 카드사별 점검시간에 따라 결제가 불가할 수 있사오니 이점 유의해주시기 바랍니다.", null, () => {
      props.history.push(`/reservation/${reservationIdx}/payment`);
    });
  }
  
  const movePrevSeasonTicket = () => {
    props.history.push(`/seasonTicket/continue`);
  }

  const moveSeasonTicket = () => {
    props.history.push(`/seasonTicket`);
  }

  const moveTo = (url, title) => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('appLink', `https://ticket.ncdinos.com${url}`, title);
    } else {
      props.history.push(url)
    }
  }

  const getEvents = async () => {
    const {data} = await TicketService.getEventApplicants();
    console.log(data.events);
    setEvents(data.events);
  }

  const getMatches = async () => {
    showLoading();

    await getEvents();

    const {data} = await TicketService.getGames();
    setMatches(data.games);
    setBanners(data.banners);
    setSeasonTicket(data.seasonTicket);
    hideLoading();
  }

  function EventList(props) {
    const listItems = props.events.map((event) =>
      <div className="btn_reservation" onClick={() => {movePayment(event.reservationIdx);}}>{event.title} 결제하기<span>${Moment(event.endPaymentDatetime).format('YYYY.MM.DD HH:mm')} 까지</span></div>
    );
    return (
      <>{listItems}</>
    );
  }

  function MatchList(props) {
    const listItems = props.matches.map((match) =>
      <MatchComponent key={match.idx} match={match} onClickMatch={onClickMatch}/>
    );
    return (
      listItems.length > 0 ? <div className="game_list">{listItems}</div> :
        <div className="no_games">예매 가능한 경기가 없습니다.</div>
    );
  }

  return (
    <div id="content">
      {seasonTicket.open1 ? <div className="btn_reservation" onClick={movePrevSeasonTicket}>2024 시즌티켓 N TYPE 우선판매 기존좌석 구매<span>2024.02.11 23:50 까지</span></div> : null}
      {seasonTicket.open2 ? <div className="btn_reservation" onClick={moveSeasonTicket}>2024 시즌티켓 N TYPE 우선판매 신규좌석 이동<span>2024.02.12(월) 11:00 ~ 2024.02.18(일) 23:50</span></div> : null}
      {seasonTicket.open3 ? <div className="btn_reservation" onClick={moveSeasonTicket}>2024 시즌티켓 N TYPE 구매<span>2024.02.19(월) 11:00 ~ 2024.02.29(목) 23:50</span></div> : null}
      <div style={{backgroundColor: '#fff', paddingBottom: 14, borderBottom: '1px solid #E5E5E5'}}>
        {/* 시리즈 */}
        {banners.map(banner => 
          <img key={banner.idx} src={`/imgs/${banner.image}`} onClick={() => moveTo(banner.link, banner.title)} alt={banner.title} style={{width: 'calc(100% - 40px)', margin: '8px 20px 0', borderRadius: 4, cursor: 'pointer'}} />
        )}

      </div>
      <EventList events={events}/>
      <MatchList matches={matches}/>

      <div className="ticket-storage-btn" onClick={moveMypage}>티켓 보관함 바로가기</div>
    </div>
  )
}

export default MatchContainer;
