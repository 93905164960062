import React, { useCallback, useEffect, useState } from 'react';
import Moment from "moment";
import { teamData } from "../../../../data/team";
import * as TicketService from "../../../../service/Ticket.service";
import NumberFormat from "react-number-format";
import {hideConfirm, hideLoading, hideModal, showLoading, showModal} from "../../../../helpers/layoutHelper";

const SummaryContainer = (props) => {

  const reservationIdx = parseInt(props.match.params.reservationIdx);

  const [totalPrice, setTotalPrice] = useState(0);
  const [reservation, setReservation] = useState(null);
  const [payments, setPayments] = useState([]);
  const [paymentOpenIdx, setPaymentOpenIdx] = useState(null);
  const [seatPaymentType, setSeatPaymentType] = useState(null);
  const [showVoucherPopup, setShowVoucherPopup] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherCodeReadonly, setVoucherCodeReadonly] = useState(false);
  const [vouchers, setVouchers] = useState({})
  const [userVouchers, setUserVouchers] = useState(null);

  const handlePrev = async () => {
    if (window.confirm('이전 단계로 돌아가면 현재의 예매 정보를 잃게 됩니다.')) {
      showLoading();
      await TicketService.cancelSeat(reservationIdx);
      props.history.goBack();
    }
  }

  const fetchData = async () => {
    showLoading();

    const {data} = await TicketService.getPayments(reservationIdx)
    const {reservation, payments} = data;

    for (const i in reservation.seats) {
      const seat = reservation.seats[i];
      if (seat.voucherCode) {
        vouchers[seat.idx] = seat.voucherCode;
      }
    }
    setReservation(reservation);
    setPayments(payments);

    hideLoading();
  }

  useEffect(() => {
    document.title = "결제선택"

    fetchData().then();
  }, []);

  useEffect(() => {
    if (reservation && payments.length > 0) {
      let _seatPaymentType = {};
      for (let key in reservation.seats) {
        const reservationSeat = reservation.seats[key];
        if (reservationSeat.paymentTypeIdx) {
          _seatPaymentType[reservationSeat.idx] = reservationSeat.paymentTypeIdx;
        } else {
          _seatPaymentType[reservationSeat.idx] = payments[0].idx;
        }
      }
      setSeatPaymentType(_seatPaymentType);
    }
  }, [reservation, payments]);

  useEffect(() => {
    let sum = 0;
    if (payments.length > 0 && seatPaymentType) {
      for (let key in seatPaymentType) {
        const payment = payments.find((payment) => payment.idx === seatPaymentType[key]);
        let price = getPrice(payment);
        sum += price;
      }
    }
    setTotalPrice(sum);
  }, [payments, seatPaymentType]);

  const getVoucherList = async () => {
    if(!reservation) return
    const { data } = await TicketService.getVouchers(reservationIdx);
    if(data.vouchers){
      setUserVouchers(data.vouchers);
    }
  }

  useEffect(() => {
    if(reservation){
      getVoucherList().then();
    }
  }, [reservation]);

  function getPrice(payment) {
    let price = payment.isChild ? reservation.childPrice : reservation.price
    if (payment.discountAmount > 0) {
      price -= payment.discountAmount;
    } else if (payment.discountRate > 0) {
      price *= ((100 - payment.discountRate) / 100);
    }
    return Math.round(Math.max(price, 0));
  }

  function openPaymentList(e) {
    let idx = parseInt(e.target.dataset.idx);
    if (idx === paymentOpenIdx) {
      idx = null;
    }
    setPaymentOpenIdx(idx);
  }

  function selectPayment(e) {
    const idx = parseInt(e.target.dataset.idx);
    const paymentTypeIdx = parseInt(e.target.dataset.payment_idx);
    const paymentType = payments.find(payment => payment.idx === paymentTypeIdx);

    let otherSeatPaymentTypes = {...seatPaymentType};
    delete otherSeatPaymentTypes[idx];

    if (paymentTypeIdx === 101) {
      setVoucherCode("");
      setVoucherCodeReadonly(false);
      setShowVoucherPopup(true);
      return;
    }

    if (paymentType.type === 1) {
      const otherCardPayments = Object.values(otherSeatPaymentTypes).filter(idx => {
        const paymentType = payments.find(payment => payment.idx === idx);
        return paymentType.idx !== paymentTypeIdx && paymentType.type === 1;
      });

      if (otherCardPayments.length > 0) {
        alert(`카드 할인은 동일한 권종으로만 선택이 가능합니다.`);
        return;
      }
    }

    const cnt = Object.values(otherSeatPaymentTypes).filter(idx => paymentTypeIdx === idx).length;
    if (paymentType.quantityLimit > 0 && cnt + paymentType.quantityCnt >= paymentType.quantityLimit) {
      alert(`해당 권종은 게임당 최대 ${paymentType.quantityLimit}장 까지 선택이 가능합니다.`);
      return;
    }

    if (paymentType.monthlyLimit > 0 && paymentType.monthlyCnt >= paymentType.monthlyLimit) {
      alert(`해당 카드는 한 달에 최대 ${paymentType.monthlyLimit}경기 할인이 가능합니다.`);
      return;
    }

    if (paymentType.comment) {
      showModal(paymentType.comment, '예매안내');
    }

    const reservationSeat = reservation.seats.find(reservationSeat => reservationSeat.idx === paymentOpenIdx);
    if (reservationSeat.seatGroupIdx != null) {
      const filteredSeats = reservation.seats.filter(seat2 => seat2.seatGroupIdx === reservationSeat.seatGroupIdx);
      for (var key in filteredSeats) {
        const idx2 = filteredSeats[key].idx;
        seatPaymentType[idx2] = paymentTypeIdx;
        delete vouchers[idx2];
      }
    } else {
      seatPaymentType[idx] = paymentTypeIdx;
      delete vouchers[idx];
    }

    setSeatPaymentType({...seatPaymentType});
    setVouchers({...vouchers});
    setPaymentOpenIdx(null);
  }

  const setSeatPayments = async () => {
    showLoading();
    const {status, data} = await TicketService.setSeatPayments(reservationIdx, seatPaymentType, vouchers);
    if (status === 200) {
      props.history.push(`/reservation/${props.match.params.reservationIdx}/payment`);
    } else {
      if (data.msg) {
        alert(data.msg)
      }
      window.location.reload();
    }
  }

  function ReservationSeatList(props) {
    const listItems = props.reservationSeats.map((reservationSeat) => {
      if (seatPaymentType) {
        const payment = payments.find((payment) => payment.idx === seatPaymentType[reservationSeat.idx]);
        return (
            <div key={reservationSeat.idx} className="seat_info">
              <div className="icon">
                <img src="/img-won-seat.png" alt=""/>
              </div>
              <div className="info" style={{marginBottom: 16}}>
                <div>{reservationSeat.zoneGroup}</div>
                <div>{reservationSeat.zone}구역 {reservationSeat.line} {reservationSeat.no}번</div>
              </div>
              <div className="current_price">
                {payment.title}{payment.idx === 101 ? ` ${vouchers[reservationSeat.idx]}` : ''}<span><NumberFormat value={getPrice(payment)} displayType={"text"} thousandSeparator={true} suffix="원"/></span>
              </div>
              <div className="discount_title" data-idx={reservationSeat.idx} onClick={openPaymentList}>할인 권종을 선택해주세요.
                <img src="/ic-arrow-up-ticket-re-2.png" style={{transform: reservationSeat.idx === paymentOpenIdx ? 'scaleY(1)' : 'scaleY(-1)'}} alt="expand"/>
              </div>
              <ul style={{display: paymentOpenIdx === reservationSeat.idx ? 'block' : 'none'}}>
                <PaymentList reservationSeat={reservationSeat}/>
              </ul>
            </div>
        );
      } else {
        return null;
      }
    });
    return (
      <div>
        {listItems}
      </div>
    );
  }

  function PaymentList(props) {
    return payments.map((payment) => {
      let price = getPrice(payment);
      return (
          <li key={payment.idx} data-payment_idx={payment.idx} data-idx={props.reservationSeat.idx} onClick={selectPayment}>
            <img src={seatPaymentType[props.reservationSeat.idx] === payment.idx ? "/ic-checkbox-ov.png" : "/ic-checkbox.png"} alt="check"/>
            {payment.title}
            <span><NumberFormat value={price} displayType={"text"} thousandSeparator={true} suffix="원"/></span>
            <p data-payment_idx={payment.idx} data-idx={props.reservationSeat.idx}>{payment.comment}</p>
          </li>
      );
    });
  }

  const checkVoucherCode = async () => {
    if (Object.values(vouchers).find(code => code === voucherCode)) {
      setVoucherCode("");
      alert('동일한 예매권 번호를 사용할 수 없습니다.');
      return;
    }

    showLoading();
    setVoucherCodeReadonly(true);
    const {status, data} = await TicketService.checkVoucherCode(reservationIdx, voucherCode);
    setVoucherCodeReadonly(false);
    hideLoading();

    if (status === 200) {
      hideVoucherPopup();
      const seat = reservation.seats.find(seat => seat.idx === paymentOpenIdx);
      if (seat.seatGroupIdx != null) {
        const filteredSeats = reservation.seats.filter(seat2 => seat2.seatGroupIdx === seat.seatGroupIdx);
        for (var key in filteredSeats) {
          const seatIdx = filteredSeats[key].idx;
          seatPaymentType[seatIdx] = 101;
          vouchers[seatIdx] = voucherCode;
        }
      } else {
        seatPaymentType[paymentOpenIdx] = 101;
        vouchers[paymentOpenIdx] = voucherCode;
      }
      setSeatPaymentType({...seatPaymentType});
      setVouchers({...vouchers});
      setPaymentOpenIdx(null);
    } else {
      if (data.msg) {
        alert(data.msg)
      } else {
        alert('예매권 번호를 찾을 수 없습니다.');
      }
    }
  }

  function hideVoucherPopup() {
    setShowVoucherPopup(false);
  }

  function changeVoucher(item) {
    if(!item || !reservation) return;

    if(!item.enabled){
      alert('사용할 수 없는 번들티켓입니다.');
      setVoucherCodeReadonly(false);
      return;
    }

    if(reservation.seats[0].zoneGroupIdx != item.zoneGroupIdx){
      alert('해당 구역에서 사용할 수 없는 번들티켓입니다.');
      setVoucherCodeReadonly(false);
      return;
    }

    if(item.usedDatetime){
      alert(`${Moment(item.usedDatetime).format('YYYY.MM.DD(ddd)')}에 사용된 번들티켓입니다.`);
      setVoucherCodeReadonly(false);
      return;
    }

    if(item.expired){
      alert('기간이 만료된 번들티켓입니다.');
      setVoucherCodeReadonly(false);
      return;
    }

    setVoucherCode(item.code);
    setVoucherCodeReadonly(true);
  }

  return (
      reservation ?
    <div style={{height: '100%', backgroundColor: '#fff'}}>
      <div
        style={{
          display: showVoucherPopup ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          bottom: 0,
          width: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        }}>
        <div className="pop-wrap">
          <div className="pop-title">
            예매권 번호 입력
          </div>
          <div className="pop-cont">
            <input type="text" value={voucherCode} onChange={(e) => {setVoucherCode(e.target.value)}} readOnly={voucherCodeReadonly}/>
          </div>
          {/* 번들 티켓 */}
          <div className="pop-cont">
            <ul className="voucher-wrap">
              {
                userVouchers?.map(item =>
                    <li key={item.idx} className={`${!item.usedDatetime && !item.expired && item.enabled ? 'blue cursor-link' : ''}`} onClick={e => {changeVoucher(item)}} data-code={item.code}>예매권 번호 : {item.code} | 대상 구역 : {item.name} {item.usedDatetime ? ' | [사용완료]' : (!item.weekend ? '[화,수,목 사용가능]' : null)}</li>
                )
              }
            </ul>
          </div>
          <div className="pop-btn">
            <button className="gray" onClick={() => {
              hideVoucherPopup();
            }}>취소</button>
            <button className="navy" onClick={async () => {
              checkVoucherCode().then();
            }}>확인</button>
          </div>
        </div>
      </div>
      <div id="content">
        <div className="ticket_info">
          <div className="title">
            예매 경기정보
          </div>
          <div className="ncpark">
            {reservation.stadiumName}
          </div>
          <div className="date">
            {Moment(reservation.playDatetime).format('YYYY.MM.DD(ddd) HH시 mm분')}
          </div>
          <div className="vs">
            VS
          </div>
          <div className="logo">
            <img src={`/imgs/${teamData[reservation.awayTeamId].logo}`} alt={teamData[reservation.awayTeamId].name}/>
          </div>
        </div>
        <div className="price_type">
          <div className="summary_info_txt">
            <img src="/ticket-ico-20-ico-20-noti-2.svg"/>
            좌석 선점 완료! <span>10분 이내</span> 결제해 주세요.
          </div>
          <ReservationSeatList reservationSeats={reservation.seats}/>
        </div>
        <div className="ticket_summary">
          <div>
            <span>티켓 금액</span><span><NumberFormat value={totalPrice} displayType={"text"} thousandSeparator={true}/>원</span>
          </div>
        </div>
      </div>
      <footer>
        <div className="prev" onClick={handlePrev}>이전단계</div>
        <div className={"next"} onClick={setSeatPayments}>다음단계(3/3)</div>
      </footer>
    </div> : <div/>
  );
};

export default SummaryContainer;
