import React, { useEffect } from 'react';
import {hideLoading} from "../../../../helpers/layoutHelper";

const CompleteContainer = (props) => {
  const moveHome = () => {
    props.history.push(`/games`);
  }

  useEffect(() => {
    document.title = "구매 완료"

    hideLoading();
  }, []);

  return (
    <div id="content">
      <div className="complete">
        <img src="/complete.png" alt="티켓 구매 완료"/>
        <div className="txt1">
          감사합니다.<br/>
          티켓 구매가 완료되었습니다.
        </div>
        <div className="txt2">
          NC다이노스 티켓 구매가 완료되었습니다. <br/>다가올 경기를 기대해주세요.
        </div>
        <button onClick={moveHome}>홈으로 돌아가기</button>
      </div>
    </div>
  );
}

export default CompleteContainer;
