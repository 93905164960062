import { combineReducers } from 'redux';

import page from "./page";
import layout from "./layout";
import ticket from "./ticket";

const rootReducer = combineReducers({
  page,
  layout,
  ticket,
});

export default rootReducer;
