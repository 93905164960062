import React, { useCallback, useEffect, useRef, useState } from 'react';
import NumberFormat from "react-number-format";
import * as TicketService from "../../../../service/Ticket.service";
import { hideLoading, showLoading, showModal } from "../../../../helpers/layoutHelper";
import Agreements from "../../../../data/agreements.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import PasswordComponent from "../../reservation/payment/PasswordComponent";
import Moment from "moment";
import { isMobile } from 'react-device-detect';

const OpenPracticeContainer = (props) => {

  const reservationIdx = parseInt(props.match.params.reservationIdx);
  const gameIdx = parseInt(props.match.params.gameIdx);
  const reservationSeatIdx = parseInt(props.match.params.reservationSeatIdx);

  const [reservation, setReservation] = useState(null);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [myPoint, setMyPoint] = useState(0);
  const [usePoint, setUsePoint] = useState(0);
  const [ncPays, setNcPays] = useState([]);
  const [ncPayIndex, setNcPayIndex] = useState(0);
  const [cancelFeeAgreed, setCancelFeeAgreed] = useState(false);
  const [selectedPayMethod, setSelectedPayMethod] = useState('');
  const [enterPassword, setEnterPassword] = useState(false);
  const [seatOptionGroups, setSeatOptionGroups] = useState([]);
  const [selectedSeatOption, setSelectedSeatOption] = useState();
  const [agreements, setAgreements] = useState([
    {
      title: '서비스 이용약관 동의 (필수)',
      checked: false,
      name: 'terms',
      message: Agreements.terms
    },
    {
      title: '개인정보 수집 및 이용동의 (필수)',
      checked: false,
      message: Agreements.privacy
    }
  ]);
  const openPracticePrice = 3000;


  useEffect(() => {
    const fetchData = async () => {
      {
        const { data } = await TicketService.requestMyWallet();
        setUserName(data.name);
        setUserPhone(data.phone);
        setUserEmail(data.email);
        setMyPoint(data.point);
        setNcPays(data.ncPays);
      }
      {
        const { data } = await TicketService.getReservationDetail(reservationIdx, gameIdx);
        const seat = data.reservation.seats.find(seat => seat.reservationSeatIdx === reservationSeatIdx)
        if (seat.reservationSeatOptionIdx != null) {
          alert('이미 오픈프랙티스를 구매한 좌석입니다.');
          props.history.goBack();
        }
        setReservation(data.reservation);

        const seatOptionGroups = data.seatOptionGroups;
        setSeatOptionGroups(data.seatOptionGroups);

        const selectedSeatOption  = seatOptionGroups.find(seatOptionGroup => seatOptionGroup.idx === 1)
          .seatOptions.find(seatOption => seatOption.total === 0 || seatOption.cnt < seatOption.total)
        setSelectedSeatOption(selectedSeatOption);

        document.title = "오픈 프랙티스";
      }
      hideLoading();
    }

    fetchData();
  }, []);

  const handleUsePoint = (e) => {
    if (parseInt(e.target.value) > myPoint) {
      alert('사용 포인트가 보유 포인트를 초과합니다.');
      return;
    }
    if (parseInt(e.target.value) > openPracticePrice) {
      alert('사용 포인트가 티켓 금액을 초과합니다.');
      return;
    }
    let point = parseInt(e.target.value);
    if (isNaN(point)) {
      point = 0;
    }
    setUsePoint(point);
  }

  const useAllPoint = () => {
    let pt = Math.floor(Math.min(myPoint, openPracticePrice) / 100) * 100;
    setUsePoint(pt);
  }

  const paymentAmount = useCallback(() => {
    return Math.max(selectedSeatOption?.price ?? 0 - usePoint, 0);
  }, [selectedSeatOption, usePoint]);

  const checkAgreements = () => {
    return agreements.reduce((a, b) => a && b.checked, true) && cancelFeeAgreed && (selectedPayMethod !== '' || paymentAmount() === 0) && (selectedPayMethod !== 'NCPAY' || ncPays.length > 0) && selectedSeatOption;
  }

  const requestPay = async () => {
    if ((usePoint > 0 && usePoint < 1000) || usePoint % 100 > 0) {
      alert('포인트 사용은 최소 1000P 이상, 100P 단위로 사용이 가능합니다.');
      return;
    }
    if (paymentAmount() === 0) {
      // showLoading();

      // const { status, data } = await TicketService.requestOpenPracticePaymentPoint(reservationSeatIdx, selectedSeatOption.idx, usePoint);

      // if (status === 200) {
      //   props.history.push(`/mypage/${reservationIdx}/openPractice/${reservationSeatIdx}/complete`);
      // } else {
      //   if (data.msg) {
      //     alert(data.msg)
      //   }
      //   hideLoading();
      // }
    } else if (selectedPayMethod === 'NCPAY') {
      setEnterPassword(true);
    } else {
      const { status, data } = await TicketService.startOpenPracticePayment(gameIdx, reservationSeatIdx, selectedSeatOption.idx, usePoint);
      if (status === 200) {
        if (isMobile) {
          showLoading();
          document.payForm.action = "https://web.nicepay.co.kr/v3/v3Payment.jsp";
          document.payForm.EdiDate.value = data.ediDate;
          document.payForm.SignData.value = data.signData;
          document.payForm.Moid.value = data.moid;
          document.payForm.ReqReserved.value = encodeURI(JSON.stringify({
            gameIdx,
            reservationIdx,
            reservationSeatIdx,
            seatOptionIdx: selectedSeatOption.idx,
            paymentIdx: data.paymentIdx,
            point: usePoint,
          }));
          document.payForm.acceptCharset = "euc-kr";
          document.payForm.submit();
        } else {
          document.payForm.EdiDate.value = data.ediDate;
          document.payForm.SignData.value = data.signData;
          document.payForm.Moid.value = data.moid;
          document.payForm.ReqReserved.value = encodeURI(JSON.stringify({
            reservationIdx,
            reservationSeatIdx,
            paymentIdx: data.paymentIdx,
            point: usePoint,
          }));
          document.payForm.acceptCharset = "euc-kr";
          window.goPay(document.payForm);
        }
      }
    }
  }

  const requestNcPay = useCallback(async (ncPayId) => {
    setEnterPassword(false);

    const { status, data } = await TicketService.requestOpenPracticePaymentNCPay(gameIdx, reservationSeatIdx, selectedSeatOption.idx, ncPayId, usePoint, userName, userPhone, userEmail);

    if (status === 200) {
      props.history.push(`/mypage/${reservationIdx}/${gameIdx}/openPractice/${reservationSeatIdx}/complete`);
    } else {
      if (data.msg) {
        alert(data.msg)
        window.location.reload();
      }
      hideLoading();
    }

  }, [props.history, reservationIdx, selectedSeatOption, reservationSeatIdx, usePoint, userName, userPhone, userEmail]);

  const showTerms = (e) => {
    showModal(e.target.dataset.message, e.target.dataset.title);
  }

  const SeatOptionList = useCallback((prop) => {
    const seatOptionGroup = prop.seatOptionGroups.find(seatOptionGroup => seatOptionGroup.idx === 1);

    if (seatOptionGroup === undefined) {
      return <></>
    }

    const listItems = seatOptionGroup.seatOptions.map(seatOption => {
      return (
        <li key={seatOption.idx} style={{color: seatOption.total>0 && seatOption.cnt>=seatOption.total ? '#c8c8c8' : '#000'}}>
          <input type="radio" name="seatOption" id={`seatOption_${seatOption.idx}`} disabled={seatOption.total>0 && seatOption.cnt>=seatOption.total} checked={(selectedSeatOption?.idx ?? 0) === seatOption.idx} onChange={() => {setSelectedSeatOption(seatOption);}
            } />
          <label htmlFor={`seatOption_${seatOption.idx}`}><span>{`${seatOption.name}${seatOption.total > 0 ? ` [${seatOption.total - seatOption.cnt}/${seatOption.total}]` : ''}`}</span></label>
          <span><NumberFormat value={seatOption.price} displayType={"text"} thousandSeparator={true} /><span>원</span></span>
        </li>
      );
    });
    return (
      <ul>
        {listItems}
      </ul>
    )
  }, [selectedSeatOption]);

  function PayMethodList() {
    let payMethods = [
      { method: 'CARD', title: '카드 결제' },
      // {method: 'BANK', title: '실시간 계좌 이체'},
      // {method: 'CELLPHONE', title: '휴대폰 결제'}
      { method: 'NCPAY', title: 'NC 다이노스 페이' },
      // {method: 'APPLEPAY', title: 'Apple Pay'},
    ]
    const listItems = payMethods.map((payMethod, index) => {
      return (
        <li key={index}>
          <input type="radio" name="payment" id={`payment_${payMethod.method}`} value={payMethod.method} checked={selectedPayMethod === payMethod.method} onChange={() => setSelectedPayMethod(payMethod.method)} />
          <label htmlFor={`payment_${payMethod.method}`}><span>{payMethod.title}</span></label>
        </li>
      );
    })
    return (
      <ul>
        {listItems}
      </ul>
    )
  }

  const NcPayList = () => {
    const listItems = ncPays.map((ncPay, index) => {
      let enabled = true;
      return (
        <SwiperSlide key={index}>
          <div className="card-pay ncdinos">
            <i></i>
            <h6>{ncPay.cardName}카드</h6>
            <p>{ncPay.cardNo}</p>
            <div className="disabled" style={{ display: enabled ? 'none' : 'block' }}>사용불가</div>
          </div>
        </SwiperSlide>
      );
    });
    return (
      <>
        <h6>NC 다이노스 페이</h6>
        {/*<h6>NC 다이노스 페이<span>카드변경</span></h6>*/}
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="card-swiper"
          spaceBetween={20}
          initialSlide={ncPayIndex}
          onSlideChangeTransitionEnd={(swiper) => {
            if (ncPayIndex !== swiper.activeIndex) {
              setNcPayIndex(swiper.activeIndex);
            }
          }}>
          {ncPays.length > 0 ?
            listItems :
            <SwiperSlide><div className="card-pay no-card">등록된 카드가 없습니다.</div></SwiperSlide>
          }
        </Swiper>
      </>);
  }

  function AgreementList(props) {
    const handleChange = index => {
      setAgreements(
        agreements.map((agreement, key) =>
          key === index ? { ...agreement, checked: !agreement.checked } : agreement)
      );
    }

    const listItems = props.agreements.map((agreement, index) => {
      return (
        <li key={index}>
          <label className="form-label">
            <input type="checkbox" checked={agreement.checked} onChange={() => handleChange(index)} />
            <span>{agreement.title}</span>
          </label>
          <span onClick={showTerms} data-message={agreement.message} data-title={agreement.title}>상세보기</span>
        </li>
      );
    });
    return (
      <div className="list">
        <ul>
          {listItems}
        </ul>
      </div>
    );
  }

  if (enterPassword) {
    return (<PasswordComponent ncPayId={ncPays[ncPayIndex].id} onPasswordConfirm={requestNcPay} />);
  }

  return (
    reservation != null &&
    <div>
      <div id="content">
        <div className="order_info">
          <div className="title">
            구매자 정보 입력
          </div>
          <div className="user-input">
            <ul>
              <li>이름 (필수)</li>
              <li><input type="text" value={userName || ''} readOnly={true} /></li>
              <li>휴대폰 번호 (필수)</li>
              <li><input type="text" value={userPhone || ''} readOnly={true} /></li>
              <li>이메일 (선택)</li>
              <li><input type="email" value={userEmail || ''} onChange={(e) => { setUserEmail(e.target.value) }} /></li>
            </ul>
          </div>
        </div>

        <div className="seat-option">
          <div className="title">오픈 프랙티스 권종 선택</div>
          <SeatOptionList seatOptionGroups={seatOptionGroups}/>
        </div>

        <div className="pay-price">
          <div className="title">최종 결제 금액</div>
          <ul>
            <li><span>오픈 프랙티스 금액</span><span><NumberFormat value={selectedSeatOption?.price ?? 0} displayType={"text"} thousandSeparator={true} /><span>원</span></span></li>
            <li className="total-price"><span>총 결제 금액</span><span><NumberFormat value={paymentAmount()} displayType={"text"} thousandSeparator={true} /><span>원</span></span></li>
          </ul>
          <h6>오픈프랙티스 취소 안내</h6>
          <p dangerouslySetInnerHTML={{ __html: `&#8901; 취소수수료 없음<br/>&#8901; 경기 당일 경기시작 4시간 전 부터 취소 불가` }}></p>

          <label><input type="checkbox" checked={cancelFeeAgreed} onChange={() => setCancelFeeAgreed(!cancelFeeAgreed)} /> <span>오픈프랙티스 취소 안내에 동의합니다.</span></label>

        </div>

        <div className="payment-way" hidden={paymentAmount() === 0}>
          <div className="title">결제 방법 선택</div>
          <PayMethodList />
          {selectedPayMethod === 'NCPAY' ? <NcPayList /> : null}
        </div>

        <div className="agreement">
          <div className="title">
            약관 동의
            <label><input type="checkbox" onChange={(e) => setAgreements(agreements.map((agreement) => {
              return {
                ...agreement,
                checked: e.target.checked
              }
            }))} /><span>모두 동의</span></label>
          </div>
          <AgreementList agreements={agreements} />
        </div>

        <div className="ticket_summary">
          <div>
            <span>총 결제금액</span><span><NumberFormat value={paymentAmount()} displayType={"text"} thousandSeparator={true} />원</span>
          </div>
        </div>
      </div>
      <footer>
        <div className="prev" onClick={() => { props.history.goBack() }}>이전단계</div>
        <div className={`next${checkAgreements() ? '' : ' disabled'}`} onClick={checkAgreements() ? requestPay : null}>결제하기</div>
      </footer>
      <form name="payForm" method="post" action={`${process.env.REACT_APP_TICKET_API_URL}/openPractice/payment/confirm`}>
        <input type="hidden" name="GoodsName" value={`오픈 프랙티스 (${Moment(reservation.playDatetime).format('MM.DD')})`} />
        <input type="hidden" name="Amt" value={paymentAmount()} />
        <input type="hidden" name="MID" value={process.env.REACT_APP_NICEPAY_MID} />
        <input type="hidden" name="EdiDate" />
        <input type="hidden" name="Moid" />
        <input type="hidden" name="SignData" />
        <input type="hidden" name="BuyerName" value={userName} />
        <input type="hidden" name="BuyerTel" value={userPhone} />
        <input type="hidden" name="ReturnURL" value={`${process.env.REACT_APP_TICKET_API_URL}/openPractice/payment/confirm`} />
        {/*<input type="hidden" name="PayMethod" value={}/>*/}
        {/*<input type="hidden" name="ReqReserved"/>*/}
        <input type="hidden" name="BuyerEmail" value={userEmail} />
        <input type="hidden" name="CharSet" value="utf-8" />
        <input type="hidden" name="VbankExpDate" id="vExp" />
        <input type="hidden" name="GoodsCl" value={1} />
        <input type="hidden" name="WapUrl" value="ncdinos://" />
        <input type="hidden" name="PayMethod" value={selectedPayMethod} />
        <input type="hidden" name="ReqReserved" />
        <input type="hidden" name="MallReserved" />
        <input type="hidden" name="CardQuota" value="00" />
        <input type="hidden" name="OSType" />
        {/*<input type="hidden" name="IspCancelUrl" value={}/>*/}
        {/*<input type="hidden" name="TransType" value={0}/>*/}
        {/*<input type="hidden" name="EncryptData" value={'afawefeawfaewf'}/>*/}
      </form>
    </div>
  );
}

export default OpenPracticeContainer;
