import React, { useCallback, useEffect, useState } from 'react';
import * as TicketService from '../../../service/Ticket.service';
import { useDispatch, useSelector } from "react-redux";
import {MAX_PR_SEAT_SELECT, MAX_SEAT_SELECT} from "../../commons/layout";
import { hideLoading, showLoading } from "../../../helpers/layoutHelper";
import {ReactSVG} from "react-svg";
import {useWindowSize} from "@react-hook/window-size";
import { isMobile } from 'react-device-detect';

const CheckSeatContainer = (props) => {

  const selectedZone = parseInt(props.match.params.zoneName);

  const prevPage = () => {
    props.history.goBack();
    showLoading();
  }

  const [priority, setPriority] = useState(0);
  const [reservedCnt, setReservedCnt] = useState(0);
  const [width, height] = useWindowSize({initialWidth: 400, initialHeight: 800});
  const [ mapHeight, setMapHeight ] = useState(0);
  const [ svgHeight, setSvgHeight ] = useState(2000);

  // const [svgMap, setSvgMap] = useState();
  const { isApp } = useSelector(state => state.layout);

  useEffect(() => {
    document.title = "좌석선택"
  }, []);

  const [availableSeats, setAvailableSeats] = useState([]);
  const [transform, setTransform] = useState({
    x: -(2000-width)/2,
    y: -(svgHeight-height)/2,
    scale: width / 2000,
  });

  const [mouseState, setMouseState] = useState({
    dragging: false,
  });

  const getAvailableSeats = async () => {
    const {data} = await TicketService.getReservedSeats(selectedZone);
    const {seats, reservedCnt, priority} = data;
    setReservedCnt(reservedCnt);
    setPriority(priority);
    return seats;
  }

  useEffect(() => {
    const mapSize = {width, height: document.getElementById('content').clientHeight - 30};

    const size = Math.min(mapSize.width, mapSize.height);

    setTransform({
      x: -(2000-mapSize.width)/2,
      y: -(svgHeight-mapSize.height)/2,
      scale: size / 2000,
    })

    setMapHeight(mapSize.height);
  }, [width, height, svgHeight]);

  useEffect(() => {
    function onTouchStart(e) {
      if(e.touches.length === 1) {
        dragging = true;
        startPointX = e.touches[0].clientX;
        startPointY = e.touches[0].clientY;
        startTransformPointX = transform.x;
        startTransformPointY = transform.y;
      } else {
        dragging = false;
        scaling = true;

        let dx = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
        let dy = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
        startDistance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
        startScale = transform.scale;
      }
    }

    function onTouchMove(e) {
      if(dragging) {
        let dx = e.changedTouches[0].clientX - startPointX;
        let dy = e.changedTouches[0].clientY - startPointY;

        transform.x = startTransformPointX + dx;
        transform.y = startTransformPointY + dy;

        document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      } else if (scaling && e.touches.length > 1) {
        let dx = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
        let dy = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
        let distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

        transform.scale = Math.min(Math.max(0.194892, distance / startDistance * startScale), 2);

        document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      }

      e.preventDefault();
    }

    function onMouseDown(e) {
      dragging = true;
      mouseState.dragging = false;
      startPointX = e.clientX;
      startPointY = e.clientY;
      startTransformPointX = transform.x;
      startTransformPointY = transform.y;
    }

    function onMouseMove(e) {
      if(dragging) {
        let dx = e.clientX - startPointX;
        let dy = e.clientY - startPointY;

        if (dx > 5 || dy > 5) {
          mouseState.dragging = true;
        }

        transform.x = startTransformPointX + dx;
        transform.y = startTransformPointY + dy;

        document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      }

      e.preventDefault();
    }

    function onMouseUp(e) {
      dragging = false;
      mouseState.dragging = false;
      e.preventDefault();
    }

    let startPointX;
    let startPointY;
    let startTransformPointX;
    let startTransformPointY;
    let startScale;
    let startDistance;
    let dragging = false;
    let scaling = false;
    const svgContainer = document.getElementById("svg_container");
    if (isMobile) {
      svgContainer.removeEventListener('touchstart', onTouchStart);
      svgContainer.addEventListener('touchstart', onTouchStart);
      svgContainer.removeEventListener('touchmove', onTouchMove);
      svgContainer.addEventListener('touchmove', onTouchMove);
    } else {
      svgContainer.removeEventListener('mousedown', onMouseDown);
      svgContainer.addEventListener('mousedown', onMouseDown);
      svgContainer.removeEventListener('mousemove', onMouseMove);
      svgContainer.addEventListener('mousemove', onMouseMove);
      svgContainer.removeEventListener('mouseleave', onMouseUp);
      svgContainer.addEventListener('mouseleave', onMouseUp);
      svgContainer.removeEventListener('mouseup', onMouseUp);
      svgContainer.addEventListener('mouseup', onMouseUp);
    }
    svgContainer.addEventListener('mousewheel', (e) => {
      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      console.log(e.deltaY);
      transform.scale = Math.min(Math.max(0.194802, transform.scale - e.deltaY * 0.001), 2);

      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
    });
  }, [transform]);

  useEffect(async () => {
    showLoading();

    setAvailableSeats(await getAvailableSeats());

    console.log(document.getElementsByTagName('svg')[0]);

    hideLoading();
  }, []);

  const setSeats = (element) => {
    let children = element.childNodes;
    for (const key in children) {
      const child = children[key];

      if (child.classList) {
        const classes = child.classList;
        if (classes.contains('seat')) {
          const seat = availableSeats.find(seat => seat.seatIdx === parseInt(child.id))
          setColor(child, null);
          if (seat) {
            if (seat.type >= 1 && seat.type <= 3) {
              setColor(child, '#F00');
            }
            classes.remove('disabled');
          } else {
            classes.add('disabled');
          }
        } else if (classes.contains('seat_group')) {
          setSeats(child);
        }
      }
    }
  }

  function setColor(element, color) {
    let children = element.childNodes;
    for (const key in children) {
      const child = children[key];
      if (child.nodeName === 'rect' || child.nodeName === 'path' || child.nodeName === 'text') {
        child.style.fill = color;
      }
    }
  }

  return (
    <div>
      <div id="content">
        <div className="seat_selection"
             style={{
               position: "relative",
               width: "100vw",
               height: `calc(100vh - ${157 + (isApp ? 0 : 61)}px)`
             }}>
          <div className="center">경기장방향</div>
          <div id="svg_container" style={{width, height: mapHeight, overflow: 'hidden'}}>
            <div id="svg_transform" style={{transform: `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`, width: 2000, height: svgHeight}}>
              <ReactSVG src={`https://svg.ncdinos.com/zone_${selectedZone % 1000}.svg`}
                afterInjection={(error, svg) => {
                  setSvgHeight(svg.viewBox.baseVal.height);

                  if (error) {
                    console.error(error)
                    return
                  }

                  setSeats(svg);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckSeatContainer;
