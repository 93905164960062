import {createPortal} from "react-dom";
import ZoneGroupComponent from "./ZoneGroupComponent";
import React from "react";

const ZoneSelectPopup = (props) => {

  const onSelect = (e) => {
    const zoneIdx = parseInt(e.target.dataset.zone_idx);
    const zoneGroupIdx = Math.floor(zoneIdx / 10000);
    if (props.selectedZoneGroup !== zoneGroupIdx) {
      props.onSelectZoneGroup(zoneGroupIdx);
    }
    props.onSelectZone(parseInt(zoneIdx), true);
    props.closeZoneSelectPopup();
  }

  const Zones = () => {
    const listItems = props.zones.map((zoneIdx) => {
      const zoneGroup = props.zoneGroups.find(zoneGroup => zoneGroup.idx === Math.floor(zoneIdx/10000));
      const zone = zoneGroup.zones.find(zone => zone.idx === zoneIdx);
      return (<li key={zoneGroup.idx}>
        <div>
          <span className={zoneGroup.idx === 17 ? "color wheelchair" : "color"} style={{backgroundColor: zoneGroup.color}}/>
          <h6>&nbsp;{zoneGroup.name}</h6>
          <p>{zone.cnt}석</p>
        </div>
        <button onClick={onSelect} data-zone_idx={zoneIdx}>선택</button>
      </li>);
    });
    return (
      <ul className="seat-list">
        {listItems}
      </ul>
    );
  }

  return createPortal(
    <div id="pop"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        bottom: 0,
        width: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 10000,
      }}>
      <div className="pop-wrap">
        <div className="pop-title">
          좌석 선택 유형
          <i className="ic-close" onClick={props.closeZoneSelectPopup}/>
        </div>
        <Zones/>
      </div>
    </div>,
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     position: "fixed",
    //     top: 0,
    //     bottom: 0,
    //     width: "100%",
    //     background: "rgba(0, 0, 0, 0.5)",
    //     zIndex: 10000,
    //   }}>
    //   <div className="pop-wrap">
    //     {props.messages[1] != null ?
    //       <div className="pop-title">
    //         {props.messages[1]}
    //       </div> : null}
    //     <div className="pop-cont">
    //       <div dangerouslySetInnerHTML={{__html: props.messages[0]}} />
    //     </div>
    //     <div className="pop-btn">
    //       <button className="navy" onClick={() => {
    //         hideModal();
    //         if (props.messages[2]) {
    //           props.messages[2]();
    //         }
    //       }}>확인</button>
    //     </div>
    //   </div>
    // </div>,
    document.getElementById('modal')
  );
}

export default ZoneSelectPopup;