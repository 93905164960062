import React, { useCallback, useEffect, useState } from "react";
import * as TicketService from "../../../../service/Ticket.service";
import SingleSeriesComponent from "./SingleSeriesComponent";
import {showLoading, hideLoading, showModal} from "../../../../helpers/layoutHelper";

const SingleSeriesContainer = (props) => {
  const [serieses, setSerieses] = useState([]);

  const onClickSeries = useCallback(series => {
    props.history.push(`/series/${series.idx}`);
    showLoading();
  }, []);

  useEffect(() => {
    document.title = "싱글 시리즈 티켓 예매";

    getSeries().then();
  }, []);

  const moveMypage = () => {
    props.history.push(`/mypage`);
  }

  const movePayment = (reservationIdx) => {
    showModal("&#8901; 예매 수수료, 취소 수수료 모두 일반 홈경기 티켓과 동일한 정책에 따릅니다.\n&#8901; 23시 40분부터는 카드사별 점검시간에 따라 결제가 불가할 수 있사오니 이점 유의해주시기 바랍니다.", null, () => {
      props.history.push(`/reservation/${reservationIdx}/payment`);
    });
  }

  const getSeries = async () => {
    showLoading();
    const {data} = await TicketService.getSingleSeries()
    setSerieses(data);
    hideLoading();
  }

  function SeriesList(props) {
    const listItems = props.serieses.map((series) =>
      <SingleSeriesComponent key={series.idx} series={series} onClickSeries={onClickSeries}/>
    );
    return (
      listItems.length > 0 ? <div className="game_list">{listItems}</div> :
        <div className="no_games">예매 가능한 경기가 없습니다.</div>
    );
  }

  return (
    <div id="content">
      <SeriesList serieses={serieses}/>
    </div>
  )
}

export default SingleSeriesContainer;
