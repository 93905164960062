import React from "react";

const SeasonTicketComponent = (props) => {

  const handleClick = e => {
    e.preventDefault();
    props.onClickDetail(props.reservation.idx);
  }

  return (
    <div className="list season" onClick={handleClick}>
      <div className="info">
        <span className="label">시즌티켓</span>
        <div className="title">
          {props.reservation.reservationName}
        </div>
        <div className="count">{props.reservation.moid}</div>
      </div>
      <div className="status">
        <div className="bg">
          <img src="/imgs/bg-ticket.png" srcSet="/imgs/bg-ticket@2x.png 2x, /imgs/bg-ticket@3x.png 3x"
              className="bg_ticket"/>
        </div>
        <div className="present">
          <img src="/imgs/ic-ticket-list.png" srcSet="/imgs/ic-ticket-list@2x.png 2x, /imgs/ic-ticket-list@3x.png 3x"
             className="ic_ticket_list"/>
        </div>
      </div>
    </div>
  );
}

export default SeasonTicketComponent;
