import Moment from 'moment';
import { teamData } from "../../../../data/team"

const MatchComponent = (props) => {

  const handleClick = (e) => {
    e.preventDefault();
    props.onClickMatch(props.match);
  }

  return (
    <div className="list">
      <div className="team">
        <img src="/imgs/NC.png"/>
        <div>VS</div>
        <img src={`/imgs/${teamData[props.match.awayTeamId].logo}`}/>
      </div>
      <div className="summary">
        {
          props.match.eventTitle != null ?
          <div>
            {props.match.eventTitle}
          </div>
          :
          <div>
            NC vs {teamData[props.match.awayTeamId].shortName}
            {props.match.doubleHeader !== 0 && <span>더블헤더 {props.match.doubleHeader}차전</span>}
          </div>
        }

        <div>
          {Moment(props.match.playDatetime).format('YYYY.MM.DD(ddd) HH:mm')}
        </div>
        <div>
          {props.match.stadiumName}
        </div>

      </div>
      <div className="reservation-btn">
        {/*<div className="price">가격안내</div>*/}
        <div className={props.match.opened ? "reservation" : "reservation_inactive"} onClick={props.match.opened ? handleClick : null}>
          {props.match.opened ? (props.match.priority ? '선예매하기' : '예매하기') : `(${Moment(props.match.rsvOpenDatetime).format('YYYY-MM-DD HH:mm')} ${props.match.priority ? '선예매 오픈예정' : '오픈예정'})`}
        </div>
      </div>
    </div>
  )
}

export default MatchComponent;
