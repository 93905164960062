import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as TicketService from '../../../../service/Ticket.service';
import { useSelector } from "react-redux";
import {MAX_PR_SEAT_SELECT, MAX_SEAT_SELECT, MAX_SERIES_SEAT_SELECT} from "../../../commons/layout";
import { hideLoading, showLoading } from "../../../../helpers/layoutHelper";
import {ReactSVG} from "react-svg";
import {useWindowSize} from "@react-hook/window-size";
import { isMobile } from 'react-device-detect';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

SwiperCore.use([Autoplay,Pagination,Navigation]);

const SeatContainer = (props) => {

  const gameIdx = parseInt(props.match.params.gameIdx);
  const seriesIdx = parseInt(props.match.params.seriesIdx);
  const selectedZone = parseInt(props.match.params.zoneIdx);

  const prevPage = () => {
    props.history.goBack();
    showLoading();
  }

  const [priority, setPriority] = useState(0);
  const [reservedCnt, setReservedCnt] = useState(0);
  const [zone, setZone] = useState();
  const [seatView, setSeatView] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navPrevBtn = useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navNextBtn = useRef(null);
  const [width, height] = useWindowSize({initialWidth: 400, initialHeight: 800});
  const [ mapHeight, setMapHeight ] = useState(0);
  const [ svgHeight, setSvgHeight ] = useState(2000);

  // const [svgMap, setSvgMap] = useState();
  const { isApp } = useSelector(state => state.layout);

  useEffect(() => {
    // document.title = "좌석선택"
  }, []);

  const [selectedSeats, setSelectedSeats] = useState([]);
  const [availableSeats, setAvailableSeats] = useState([]);
  const [transform, setTransform] = useState({
    x: -(2000-width)/2,
    y: -(svgHeight-height)/2,
    scale: width / 2000,
  });

  const [mouseState, setMouseState] = useState({
    dragging: false,
  });

  const getAvailableSeats = async () => {
    const {data} = seriesIdx ? await TicketService.getSeriesSeats(seriesIdx, selectedZone) : await TicketService.getSeats(gameIdx, selectedZone);
    const {seats, reservedCnt, priority, zone, view} = data;
    setReservedCnt(reservedCnt);
    setPriority(priority);
    setZone(zone);
    setSeatView(view);
    return seats;
  }

  useEffect(() => {
    const mapSize = {width, height: document.getElementById('content').clientHeight - 30};

    const size = Math.min(mapSize.width, mapSize.height);

    setTransform({
      x: -(2000-mapSize.width)/2,
      y: -(svgHeight-mapSize.height)/2,
      scale: size / 2000,
    })

    setMapHeight(mapSize.height);
  }, [width, height, svgHeight]);

  useEffect(() => {
    function onTouchStart(e) {
      if(e.touches.length === 1) {
        dragging = true;
        startPointX = e.touches[0].clientX;
        startPointY = e.touches[0].clientY;
        startTransformPointX = transform.x;
        startTransformPointY = transform.y;
      } else {
        dragging = false;
        scaling = true;

        let dx = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
        let dy = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
        startDistance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
        startScale = transform.scale;
      }
    }

    function onTouchMove(e) {
      if(dragging) {
        let dx = e.changedTouches[0].clientX - startPointX;
        let dy = e.changedTouches[0].clientY - startPointY;

        transform.x = startTransformPointX + dx;
        transform.y = startTransformPointY + dy;

        document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      } else if (scaling && e.touches.length > 1) {
        let dx = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
        let dy = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
        let distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

        transform.scale = Math.min(Math.max(0.194892, distance / startDistance * startScale), 2);

        document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      }

      e.preventDefault();
    }

    function onMouseDown(e) {
      dragging = true;
      mouseState.dragging = false;
      startPointX = e.clientX;
      startPointY = e.clientY;
      startTransformPointX = transform.x;
      startTransformPointY = transform.y;
    }

    function onMouseMove(e) {
      if(dragging) {
        let dx = e.clientX - startPointX;
        let dy = e.clientY - startPointY;

        if (dx > 5 || dy > 5) {
          mouseState.dragging = true;
        }

        transform.x = startTransformPointX + dx;
        transform.y = startTransformPointY + dy;

        document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      }

      e.preventDefault();
    }

    function onMouseUp(e) {
      dragging = false;
      mouseState.dragging = false;
      e.preventDefault();
    }

    let startPointX;
    let startPointY;
    let startTransformPointX;
    let startTransformPointY;
    let startScale;
    let startDistance;
    let dragging = false;
    let scaling = false;
    const svgContainer = document.getElementById("svg_container");
    if (isMobile) {
      svgContainer.removeEventListener('touchstart', onTouchStart);
      svgContainer.addEventListener('touchstart', onTouchStart);
      svgContainer.removeEventListener('touchmove', onTouchMove);
      svgContainer.addEventListener('touchmove', onTouchMove);
    } else {
      svgContainer.removeEventListener('mousedown', onMouseDown);
      svgContainer.addEventListener('mousedown', onMouseDown);
      svgContainer.removeEventListener('mousemove', onMouseMove);
      svgContainer.addEventListener('mousemove', onMouseMove);
      svgContainer.removeEventListener('mouseleave', onMouseUp);
      svgContainer.addEventListener('mouseleave', onMouseUp);
      svgContainer.removeEventListener('mouseup', onMouseUp);
      svgContainer.addEventListener('mouseup', onMouseUp);
    }
    svgContainer.addEventListener('mousewheel', (e) => {
      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      console.log(e.deltaY);
      transform.scale = Math.min(Math.max(0.194802, transform.scale - e.deltaY * 0.001), 2);

      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
    });
  }, [transform]);

  useEffect(async () => {
    showLoading();

    setAvailableSeats(await getAvailableSeats());

    console.log(document.getElementsByTagName('svg')[0]);

    hideLoading();
  }, []);

  const onSelectSeat = useCallback(selectedSeatIds => {
    let isDeselect = false;
    for (let key in selectedSeatIds) {
      const selectedSeatId = selectedSeatIds[key];
      if (selectedSeats.includes(selectedSeatId)) {
        isDeselect = true;
        break;
      }
    }
    if (isDeselect) {
      setSelectedSeats(selectedSeats.filter(seatId => !selectedSeatIds.includes(seatId)));
    } else {
      if (parseInt(selectedSeatIds[0] / 10000000) !== 9) {
        if (gameIdx === parseInt(process.env.REACT_APP_TOWNHALL_MEETING_GAME_IDX) && selectedSeats.length + selectedSeatIds.length > 2 - reservedCnt) {
          alert(`1인 1매 구매가 가능합니다.`);
          return;
        } else if (priority && selectedSeats.length + selectedSeatIds.length > MAX_PR_SEAT_SELECT - reservedCnt) {
          alert(`경기당 선예매 가능 좌석(${MAX_PR_SEAT_SELECT}석)을 초과했습니다.`);
          return;
        } else if (seriesIdx && selectedSeats.length + selectedSeatIds.length > MAX_SERIES_SEAT_SELECT - reservedCnt) {
          alert(`시리즈 티켓 예매 가능 좌석(${MAX_SERIES_SEAT_SELECT}석)을 초과했습니다.`);
          return;
        } else if (selectedSeats.length + selectedSeatIds.length > MAX_SEAT_SELECT - reservedCnt) {
          alert(`경기당 예매 가능 좌석(${MAX_SEAT_SELECT}석)을 초과했습니다.`);
          return;
        }
      }

      setSelectedSeats(selectedSeats.concat(selectedSeatIds).sort((a, b) => a - b));
    }
  }, [selectedSeats, reservedCnt, priority]);

  const reserveSeats = async () => {
    showLoading();
    const {status, data} = seriesIdx ? await TicketService.reserveSeriesSeats(seriesIdx, selectedSeats) : await TicketService.reserveSeats(gameIdx, selectedSeats);
    if (status === 200) {
      if (seriesIdx) {
        props.history.push(`/reservation/series/${data.idx}/payment`);
      } else {
        props.history.push(`/reservation/${data.idx}`);
      }
    } else {
      if (data.msg) {
        alert(data.msg)
      }
      window.location.reload();
    }
  }

  const setSeats = (element) => {
    let children = element.childNodes;
    for (const key in children) {
      const child = children[key];

      if (child.classList) {
        const classes = child.classList;
        if (classes.contains('seat')) {
          if (availableSeats.find(seat => seat.seatIdx === parseInt(child.id))) {
            classes.remove('disabled');
            if (selectedSeats.find(seatIdx => seatIdx === parseInt(child.id))) {
              classes.add('selected');
            } else {
              classes.remove('selected');
            }

            child.onclick = (_) => {
              if (mouseState.dragging === false) {
                const parent = child.parentNode;
                if (parent && child.parentNode.classList.contains('seat_group')) {
                  let seatIds = [];
                  for (const key2 in parent.childNodes) {
                    const child2 = parent.childNodes[key2];
                    const classes2 = child2.classList;
                    if (classes2 && classes2.contains('seat') && !classes2.contains('disabled')) {
                      seatIds.push(parseInt(child2.id));
                    }
                  }
                  onSelectSeat(seatIds);
                } else {
                  onSelectSeat([parseInt(child.id)]);
                }
              }
            }
          } else {
            classes.add('disabled');
            classes.remove('selected');
          }
        } else if (classes.contains('seat_group')) {
          setSeats(child);
        }
      }
    }
  }

  return (
    <div>
      <div id="content">
        <div className="seat_selection"
             style={{
               position: "relative",
               width: "100vw",
               height: `calc(100vh - ${157 + (isApp ? 0 : 61)}px)`
             }}>
          <div className="center">경기장방향
            {
              seatView && (seatView.imgPath || seatView.comment) ?
                <button className="seat-view-btn cursor-link" onClick={() => setShowPopup(true)}>좌석뷰</button>
                : null
            }
          </div>
          <div id="svg_container" style={{width, height: mapHeight, overflow: 'hidden'}}>
            {zone != null &&
            <div id="svg_transform" style={{transform: `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`, width: 2000, height: svgHeight}}>
              <ReactSVG src={`https://svg.ncdinos.com/zone_${zone.svg}.svg`}
                afterInjection={(error, svg) => {
                  setSvgHeight(svg.viewBox.baseVal.height);

                  if (error) {
                    console.error(error)
                    return
                  }

                  setSeats(svg);
                }}
              />
            </div>
            }
          </div>

        </div>
        <div className="seat_summary">
          <div className="title">
            선택좌석
          </div>
          <div className="detail">
            <span>{selectedSeats.length > 0 ? "" : "선택된 좌석이 없습니다."}</span>
            <span>{selectedSeats.map(seatIdx => {
              const seat = availableSeats.find(seat => parseInt(seat.seatIdx) === parseInt(seatIdx));
              return seat ? `${seat.line || '-'} ${seat.no || '-'}번` : '';
            }).join(", ")}</span>
            <span>총 {selectedSeats.length}개 좌석</span>
          </div>
        </div>
      </div>
      <footer>
        <div className="prev" onClick={prevPage}>이전단계</div>
        <div className={`next${selectedSeats.length > 0 ? '' : ' disabled'}`} onClick={selectedSeats.length > 0 ? reserveSeats : null}>다음단계(2/3)</div>
      </footer>

      <div
        style={{
          display: showPopup ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          bottom: 0,
          width: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        }}>
        <div className="pop-wrap seat-view-swiper-wrap" style={{maxWidth: "540px"}}>
          <div className="pop-title">
            {seatView && seatView.zoneGroupName} ({seatView && seatView.zoneName})
            <i className="ic-close" onClick={() => setShowPopup(false)}/>
          </div>
          <Swiper
            className="seat-view-swiper"
            pagination={true}
            loop={false}
            autoplay={{
              delay : 3000,
              disableOnInteraction : false,
            }}
            navigation={{
              nextEl: navNextBtn.current,
              prevEl: navPrevBtn.current,
            }}
            spaceBetween={20}
          >
            {seatView && seatView.imgPath && <SwiperSlide><img src={seatView.imgPath}/></SwiperSlide>}
            {seatView && seatView.imgPath2 && <SwiperSlide><img src={seatView.imgPath2}/></SwiperSlide>}
            {seatView && seatView.imgPath3 && <SwiperSlide><img src={seatView.imgPath3}/></SwiperSlide>}
          </Swiper>
          <div className="swiper-button">
            <div className="swiper-button-prev" ref={navPrevBtn} />
            <div className="swiper-button-next" ref={navNextBtn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeatContainer;
