import React, { useState, useEffect, useCallback } from 'react';
import * as TicketService from "../../../../service/Ticket.service";
import ZoneGroupComponent from "../zone/ZoneGroupComponent";
import { zoneGroupData } from "../../../../data/zone-group"
import {showLoading, hideLoading, showConfirm, showModal} from "../../../../helpers/layoutHelper";
import { useWindowSize } from '@react-hook/window-size'
import { ReactSVG } from "react-svg";
import { isMobile } from 'react-device-detect';
import ZoneSelectPopup from "../zone/ZoneSelectPopup";

const SeasonTicketZoneContainer = (props) => {

  const [ zoneGroups, setZoneGroups ] = useState([]);
  const [ selectedZoneGroup, setSelectedZoneGroup ] = useState();
  const [ selectedZone, setSelectedZone ] = useState();
  const [ autoScroll, setAutoScroll ] = useState(false);
  const [ width, height ] = useWindowSize({initialWidth: 430, initialHeight: 900});
  const [mouseState] = useState({
    dragging: false,
  });
  const [ multiZones, setMultiZones ] = useState();
  const [ mapSize, setMapSize ] = useState({
    width: 0,
    height: 0,
    landscapeMode: false
  });

  const [transform, setTransform] = useState({
    x: -(2000-width)/2,
    y: -(2000-width)/2,
    scale: width / 2000,
  });

  useEffect(() => {
    document.title = "2024 시즌티켓";

    getPrevReservation().then();
  }, []);

  useEffect(() => {
    let landscapeMode = false;
    if (width > height + 100) {
      landscapeMode = true;
    }
    const mapSize = {width: landscapeMode ? width - 400 : width, height: landscapeMode ? document.getElementById('content').clientHeight - 60 : Math.min(width, document.getElementById('content').clientHeight - 400), landscapeMode};
    setMapSize(mapSize);

    const size = Math.min(mapSize.width, mapSize.height);

    setTransform({
      x: -(2000-mapSize.width)/2,
      y: -(2000-mapSize.height)/2,
      scale: size / 2000,
    })
  }, [width, height]);

  useEffect(() => {
    const svgContainer = document.getElementById("svg_container");
    if (isMobile) {
      svgContainer.removeEventListener('touchstart', onTouchStart);
      svgContainer.addEventListener('touchstart', onTouchStart);
      svgContainer.removeEventListener('touchmove', onTouchMove);
      svgContainer.addEventListener('touchmove', onTouchMove);
    } else {
      svgContainer.removeEventListener('mousedown', onMouseDown);
      svgContainer.addEventListener('mousedown', onMouseDown);
      svgContainer.removeEventListener('mousemove', onMouseMove);
      svgContainer.addEventListener('mousemove', onMouseMove);
      svgContainer.removeEventListener('mouseup', onMouseUp);
      svgContainer.addEventListener('mouseup', onMouseUp);
      svgContainer.removeEventListener('mouseleave', onMouseUp);
      svgContainer.addEventListener('mouseleave', onMouseUp);
    }
    svgContainer.addEventListener('mousewheel', (e) => {
      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
      transform.scale = Math.min(Math.max(0.194802, transform.scale - e.deltaY * 0.001), 2);
      transform.x = Math.min(Math.max(transform.x, -1900 + (1000 - 1000 * transform.scale)), mapSize.width - 100 - (1000 - 1000 * transform.scale));
      transform.y = Math.min(Math.max(transform.y, -1900 + (1000 - 1000 * transform.scale)), mapSize.height - 100 - (1000 - 1000 * transform.scale));

      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
    });
  }, [transform]);

  let startPointX;
  let startPointY;
  let startTransformPointX;
  let startTransformPointY;
  let startScale;
  let startDistance;
  let dragging = false;
  let scaling = false;

  function onMouseDown(e) {
    dragging = true;
    mouseState.dragging = false;
    startPointX = e.clientX;
    startPointY = e.clientY;
    startTransformPointX = transform.x;
    startTransformPointY = transform.y;
  }

  function onMouseMove(e) {
    if(dragging) {
      let dx = e.clientX - startPointX;
      let dy = e.clientY - startPointY;

      if (dx > 5 || dy > 5) {
        mouseState.dragging = true;
      }

      transform.x = Math.min(Math.max(startTransformPointX + dx, -1900 + (1000 - 1000 * transform.scale)), mapSize.width - 100 - (1000 - 1000 * transform.scale));
      transform.y = Math.min(Math.max(startTransformPointY + dy, -1900 + (1000 - 1000 * transform.scale)), mapSize.height - 100 - (1000 - 1000 * transform.scale));

      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
    }

    e.preventDefault();
  }

  function onMouseUp(e) {
    dragging = false;
  }

  function onTouchStart(e) {
    if(e.touches.length === 1) {
      dragging = true;
      startPointX = e.touches[0].clientX;
      startPointY = e.touches[0].clientY;
      startTransformPointX = transform.x;
      startTransformPointY = transform.y;
    } else {
      dragging = false;
      scaling = true;

      let dx = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
      let dy = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
      startDistance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
      startScale = transform.scale;
      startTransformPointX = transform.x;
      startTransformPointY = transform.y;
    }
  }

  function onTouchMove(e) {
    if(dragging) {
      let dx = e.changedTouches[0].clientX - startPointX;
      let dy = e.changedTouches[0].clientY - startPointY;

      transform.x = Math.min(Math.max(startTransformPointX + dx, -1900 + (1000 - 1000 * transform.scale)), mapSize.width - 100 - (1000 - 1000 * transform.scale));
      transform.y = Math.min(Math.max(startTransformPointY + dy, -1900 + (1000 - 1000 * transform.scale)), mapSize.height - 100 - (1000 - 1000 * transform.scale));
      transform.scale = Math.min(Math.max(0.194892, transform.scale), 1.5);

      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
    } else if (scaling && e.touches.length > 1) {
      let dx = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
      let dy = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
      let distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

      transform.scale = Math.min(Math.max(0.194892, distance / startDistance * startScale), 1.5);
      transform.x = startTransformPointX + (transform.scale - startScale) * (transform.x - (-(2000-width)/2));
      transform.y = startTransformPointY + (transform.scale - startScale) * (transform.y - (-(2000-width)/2));
      transform.x = Math.min(Math.max(transform.x, -1900 + (1000 - 1000 * transform.scale)), mapSize.width - 100 - (1000 - 1000 * transform.scale));
      transform.y = Math.min(Math.max(transform.y, -1900 + (1000 - 1000 * transform.scale)), mapSize.height - 100 - (1000 - 1000 * transform.scale));


      document.getElementById('svg_transform').style.transform = `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`;
    }

    e.preventDefault();
  }

  const prevPage = () => {
    props.history.goBack();
    showLoading();
  }

  const nextPage = () => {
    if (window.location.pathname.startsWith("/exclude")) {
      props.history.push(`/exclude/${props.match.params.gameIdx}/${selectedZone}`);
    } else {
      props.history.push(`/seasonTicket/${selectedZone}`);
    }
    showLoading();
  }

  const checkSelectedZoneAvailable = () => {
    const zoneGroup = zoneGroups.find(zoneGroup => zoneGroup.idx === selectedZoneGroup);
    if (zoneGroup) {
      const zone = zoneGroup.zones.find(zone => zone.idx === selectedZone);
      if (zone && zone.cnt > 0) {
        return true;
      }
    }
    return false;
  }

  const getPrevReservation = async () => {
    showLoading();

    console.log(props);
    const {data} = await TicketService.getPrevSeasonTicketReservation();
    const prevReservation = data.reservation;
    if (prevReservation) {
      showConfirm('진행중인 예약이 있습니다.\n이어서 진행하시겠습니까?', null, () => {
        props.history.push(`/reservation/seasonTicket/${prevReservation.idx}`);
      }, async () => {
        await TicketService.cancelSeat(prevReservation.idx);
        window.location.reload();
      });
    } else {
      getSeasonTicketZones().then();
    }
  }

  const getSeasonTicketZones = async () => {
    const {status, data} = await TicketService.getSeasonTicketZones();
    if (status !== 200) {
      if (data.msg) {
        alert(data.msg);
      }
      props.history.goBack();
      return;
    }
    const zoneSeats = data.zoneSeats
    if (zoneSeats) {
      // if (notice) {
      //   showModal(notice, '예매안내');
      // }

      const zoneGroups = [];
      for (let key in zoneSeats) {
        let zoneSeat = zoneSeats[key];
        let zoneGroup = zoneGroups.find(zoneGroup => zoneGroup.idx === zoneSeat.zoneGroupIdx);
        if (zoneGroup) {
          zoneGroup.cnt += zoneSeats[key].cnt;
        } else {
          zoneGroup = zoneGroupData.find(zoneGroup => zoneGroup.idx === zoneSeat.zoneGroupIdx);
          if (!zoneGroup) {
            continue;
          }
          zoneGroup.cnt = zoneSeats[key].cnt;
          zoneGroup.zones = [];
          zoneGroups.push(zoneGroup);
        }
        zoneGroup.zones.push({
          idx: zoneSeat.zoneIdx,
          name: zoneSeat.zoneIdx % 1000,
          cnt: zoneSeat.cnt,
          price: zoneSeat.price,
          comment: zoneSeat.comment,
          notice: zoneSeat.notice,
        });
      }
      console.log(zoneGroups);
      setZoneGroups(zoneGroups);
      hideLoading();
    }
  }

  const onSelectZoneGroup = useCallback(idx => {
    if (selectedZoneGroup === idx) {
      setSelectedZoneGroup(null);
      onSelectZone(null);
    } else {
      setSelectedZoneGroup(idx);
    }
  }, [selectedZoneGroup]);

  const onSelectZone = useCallback((idx, scroll) => {
    setAutoScroll(scroll);
    setSelectedZone(idx);

    const zoneGroup = zoneGroups.find(zoneGroup => zoneGroup.zones.find(zone => zone.idx === idx));
    if (zoneGroup) {
      const zone = zoneGroup.zones.find(zone => zone.idx === idx);
      if (zone.notice) {
        showModal(zone.notice, '좌석안내');
      }
    }
  }, [zoneGroups]);

  const closeZoneSelectPopup = useCallback((idx, scroll) => {
    setMultiZones(null);
  }, []);

  function ZoneGroupList(props) {
    const listItems = props.zoneGroups.sort((a, b) => a.order - b.order).map((zoneGroup) =>
      <ZoneGroupComponent key={zoneGroup.idx} zoneGroup={zoneGroup}
                          active={selectedZoneGroup === zoneGroup.idx} autoScroll={autoScroll} selectedZone={selectedZone}
                          onSelectZoneGroup={onSelectZoneGroup} onSelectZone={onSelectZone} />
    );
    return (
      <div>{listItems}</div>
    );
  }

  return (
    <div style={{position: 'relative', height: '100%'}}>
      <div id="content">
        <div className="map" style={{right: mapSize.landscapeMode ? width - mapSize.width : 0}}>
          <div id="svg_container" style={{width: mapSize.width, height: mapSize.height, overflow: 'hidden'}}>
            <div id="svg_transform" style={{transform: `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.x}, ${transform.y})`, width: 2000, height: 2000}}>
              <ReactSVG src="https://svg.ncdinos.com/stadium.svg"
                afterInjection={(error, svg) => {
                  if (error) {
                    console.error(error)
                    return
                  }

                  let children = svg.childNodes;
                  for (const key in children) {
                    const child = children[key];
                    if (child.className) {
                      const classes = child.className.baseVal.split(' ');
                      if (classes.includes('zone')) {
                        child.onclick = (e) => {
                          if (mouseState.dragging === false) {
                            if (child.id.split(',').length > 1) {
                              setMultiZones(child.id.split(',').map(id => parseInt(id)));
                            } else {
                              const zoneGroup = Math.floor(parseInt(child.id) / 10000);

                              if (selectedZoneGroup !== zoneGroup) {
                                onSelectZoneGroup(zoneGroup);
                              }
                              onSelectZone(parseInt(child.id), true);
                            }
                          }
                        }
                      }
                      if (selectedZoneGroup) {
                        if (classes.includes('zone')) {
                          if (classes.includes(`zone_group_${selectedZoneGroup < 10 ? '0' + selectedZoneGroup : selectedZoneGroup}`)) {
                            child.style.opacity = 1;
                          } else {
                            child.style.opacity = 0.1;
                          }
                        }
                      }
                      if (selectedZone) {
                        if (classes.includes('zone')) {
                          const zoneIdxs = child.id.split(',');
                          let selected = false;
                          for (const key in zoneIdxs) {
                            if (parseInt(zoneIdxs[key]) === selectedZone) {
                              selected = true;
                              child.setAttribute('stroke', 'red');
                              child.setAttribute('stroke-width', '5');
                              break;
                            }
                          }
                          if (!selected) {
                            child.setAttribute('stroke', null);
                          }
                        }
                      }
                    }
                  }
                }}/>
            </div>
          </div>
        </div>
        <div className="map_detail"
             style={{
               position: 'absolute',
               zIndex: 100,
               top: mapSize.landscapeMode ? 0 : mapSize.height,
               left: mapSize.landscapeMode ? mapSize.width : 0,
               right: 0, bottom: 60, overflow: 'auto',
               borderTop: mapSize.landscapeMode ? null : '2px solid #2d3f64',
               borderLeft: mapSize.landscapeMode ? '2px solid #2d3f64' : null
             }}>
          <div className="title" style={{background: '#fff'}}>
            좌석구분
          </div>
          <ZoneGroupList zoneGroups={zoneGroups}/>
        </div>
      </div>
      <footer>
        <div className="prev" onClick={prevPage}>이전단계</div>
        {
          window.location.pathname.startsWith("/exclude") ?
            <div className={`next${checkSelectedZoneAvailable() ? '' : ' disabled'}`}
                 onClick={checkSelectedZoneAvailable() ? nextPage : null}>
              좌석선점
            </div>
            :
          <div className={`next${checkSelectedZoneAvailable() ? '' : ' disabled'}`}
               onClick={checkSelectedZoneAvailable() ? nextPage : null}>
            다음단계(1/3)
          </div>
        }
      </footer>
      {multiZones ? <ZoneSelectPopup zones={multiZones} zoneGroups={zoneGroups} selectedZoneGroup={selectedZoneGroup} onSelectZoneGroup={onSelectZoneGroup} onSelectZone={onSelectZone} closeZoneSelectPopup={closeZoneSelectPopup}/> : null}
    </div>
  );
};

export default SeasonTicketZoneContainer;
