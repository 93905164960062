import React from "react";
import ZoneComponent from "./ZoneComponent";

const ZoneGroupComponent = (props) => {

  const handleClick = e => {
    e.preventDefault();
    props.onSelectZoneGroup(props.zoneGroup.idx);
  }

  function ZoneList(props) {
    let zones = props.zones;
    const listItems = zones.sort((zone1, zone2) => zone1.idx - zone2.idx).map((zone) =>
      <ZoneComponent key={zone.idx} zone={zone} active={props.selectedZone === zone.idx} autoScroll={props.autoScroll} onSelectZone={props.onSelectZone}/>
    );
    return (
      <div>{listItems}</div>
    );
  }

  return (
    <div className="list">
      <div onClick={handleClick}>
        <div className={props.zoneGroup.idx === 17 ? "color wheelchair" : "color"} style={{backgroundColor: props.zoneGroup.color}}/>
        <div className="name">{props.zoneGroup.name}</div>
        <div className="seat">{props.zoneGroup.cnt}<span>석</span></div>
        <div className={`arrow${props.active ? ' up' : ''}`}/>
      </div>
      <div style={{display: props.active ? null : "none"}}>
        <ZoneList zones={props.zoneGroup.zones} selectedZone={props.selectedZone} onSelectZone={props.onSelectZone} autoScroll={props.autoScroll}/>
      </div>
    </div>
  )
}

export default ZoneGroupComponent;
