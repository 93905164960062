import './App.css';
import { ZoneContainer } from "./views/pages/reservation/zone";
import { Route, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import * as AxiosInterceptor from './service/Axios.interceptor'
import Header from "./views/commons/header";
import Moment from "moment";
import { MatchContainer } from "./views/pages/reservation/match";
import SingleSeriesContainer from "./views/pages/reservation/series/SingleSeriesContainer";
import { useCookies } from "react-cookie";
import { SeatContainer } from "./views/pages/reservation/seat";
import { SummaryContainer } from "./views/pages/reservation/summary";
import { PaymentContainer } from "./views/pages/reservation/payment";
import { CompleteContainer } from "./views/pages/reservation/complete";
import {TicketListContainer} from "./views/pages/mypage/ticketList";
import {TicketDetailContainer} from "./views/pages/mypage/ticketDetail";
import Lottie from 'react-lottie';
import Modal from "./views/commons/Modal";
import Confirm from "./views/commons/Confirm";
import animationData from './data/nc_progress_bar.json'
import {hideLoading} from "./helpers/layoutHelper";
import initReactFastclick from 'react-fastclick';
import LoginContainer from "./views/commons/LoginContainer";
import CheckContainer from "./views/pages/check/CheckContainer";
import CheckSeatContainer from "./views/pages/check/CheckSeatContainer";
import SeasonTicketContinueContainer from "./views/pages/reservation/seasonTicket/SeasonTicketContinueContainer";
import SeasonTicketPaymentContainer from "./views/pages/reservation/seasonTicket/SeasonTicketPaymentContainer";
import SeasonTicketZoneContainer from "./views/pages/reservation/seasonTicket/SeasonTicketZoneContainer";
import SeasonTicketSeatContainer from "./views/pages/reservation/seasonTicket/SeasonTicketSeatContainer";
import SeasonTicketCompleteContainer from "./views/pages/reservation/seasonTicket/SeasonTicketCompleteContainer";
import TeamSeriesContainer from "./views/pages/reservation/series/TeamSeriesContainer";
import SeriesTicketListContainer from "./views/pages/mypage/ticketList/SeriesTicketListContainer";
import OpenPracticeContainer from "./views/pages/mypage/openPractice/OpenPracticeContainer";
import OpenPracticePurchaseCompleteContainer from "./views/pages/mypage/openPractice/OpenPracticePurchaseCompleteContainer";
import SigningEventContainer from "./views/pages/mypage/signingEvent/SigningEventContainer";
import SigningEventPurchaseCompleteContainer from "./views/pages/mypage/signingEvent/SigningEventPurchaseCompleteContainer";
import SubscribeInfoContainer from "./views/pages/reservation/subscribe/SubscribeInfoContainer";
import SubscribePaymentContainer from "./views/pages/reservation/subscribe/SubscribePaymentContainer";
import SubscriptionContainer from "./views/pages/mypage/subscription/SubscriptionContainer";
import { EventContainer } from './views/pages/reservation/event';

initReactFastclick();

function App(props) {

  const [isInit, setIsInit] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'refreshToken']);
  const { isLoading, modalMessages, confirmMessages } = useSelector(state => state.layout);

  useEffect(() => {
    Moment.locale('ko', {
      weekdays: ["일요일","월요일","화요일","수요일","목요일","금요일","토요일"],
      weekdaysShort: ["일","월","화","수","목","금","토"],
    });
  }, [])

  useEffect(() => {
    const initAxios = async (cookies) => {
      if (!isInit) {
        await AxiosInterceptor.init(cookies.token, cookies.refreshToken, setCookie, removeCookie);
        setIsInit(true);
        hideLoading();
      } else {
        AxiosInterceptor.updateToken(cookies.token, cookies.refreshToken);
      }
    }

    initAxios(cookies).then();
  }, [cookies])

  function Loading(props) {
    const lottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData
    }
    return props.isLoading ? (
      <div className="loading-container" style={{opacity: 1, transition: 'opacity 500ms'}}>
        <div className="loading">
          <Lottie options={lottieOptions} width={100} height={100} />
        </div>
      </div>
    ) : null;
  }

  return (
    isInit ?
      <div style={{height: '100%', overflow: 'hidden'}}>
        { window.navigator.userAgent.includes(' AppNCDinos/') ? null : <Header /> }
        <div style={{position: 'absolute', top: window.navigator.userAgent.includes(' AppNCDinos/') ? 0 : '60px', bottom: 0, width: '100%', overflow: 'auto'}}>
        {/*<Switch>*/}
          <Route exact path="/">
            <Redirect to="/games"/>
          </Route>
          <Route exact path="/games" component={MatchContainer} />
          <Route exact path="/games/:gameIdx(\d+)" component={ZoneContainer} />
          <Route exact path="/games/:gameIdx(\d+)/:zoneIdx(\d+)" component={SeatContainer} />
          <Route exact path="/series/:seriesIdx(\d+)" component={ZoneContainer} />
          <Route exact path="/series/:seriesIdx(\d+)/:zoneIdx(\d+)" component={SeatContainer} />
          <Route exact path="/teamSeries" component={TeamSeriesContainer} />
          <Route exact path="/singleSeries" component={SingleSeriesContainer} />
          <Route exact path="/reservation/:reservationIdx(\d+)" component={SummaryContainer} />
          <Route exact path="/reservation/:reservationIdx(\d+)/payment" component={PaymentContainer} />
          <Route exact path="/reservation/series/:seriesReservationIdx(\d+)/payment" component={PaymentContainer} />
          <Route exact path="/reservation/:reservationIdx(\d+)/complete" component={CompleteContainer} />
          <Route exact path="/reservation/complete" component={CompleteContainer} />
          <Route exact path="/mypage" component={TicketListContainer} />
          <Route exact path="/mypage/:reservationIdx(\d+)" component={TicketDetailContainer} />
          <Route exact path="/mypage/:reservationIdx(\d+)/:gameIdx(\d+)" component={TicketDetailContainer} />
          <Route exact path="/mypage/:reservationIdx(\d+)/:gameIdx(\d+)/openPractice/:reservationSeatIdx(\d+)" component={OpenPracticeContainer} />
          <Route exact path="/mypage/:reservationIdx(\d+)/:gameIdx(\d+)/openPractice/:reservationSeatIdx(\d+)/complete" component={OpenPracticePurchaseCompleteContainer} />
          <Route exact path="/mypage/:reservationIdx(\d+)/signingEvent/:reservationSeatIdx(\d+)" component={SigningEventContainer} />
          <Route exact path="/mypage/:reservationIdx(\d+)/signingEvent/:reservationSeatIdx(\d+)/complete" component={SigningEventPurchaseCompleteContainer} />
          <Route exact path="/mypage/series/:seriesReservationIdx(\d+)" component={SeriesTicketListContainer} />
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/logout" component={LoginContainer} />
          <Route exact path="/check" component={CheckContainer} />
          <Route exact path="/check/:zoneName(\d+)" component={CheckSeatContainer} />
          <Route exact path="/seasonTicket" component={SeasonTicketZoneContainer} />
          <Route exact path="/seasonTicket/continue" component={SeasonTicketContinueContainer} />
          <Route exact path="/seasonTicket/:zoneIdx(\d+)" component={SeasonTicketSeatContainer} />
          <Route exact path="/reservation/seasonTicket/:reservationIdx(\d+)" component={SeasonTicketPaymentContainer} />
          <Route exact path="/seasonTicket/complete" component={SeasonTicketCompleteContainer} />
          <Route exact path="/subscribe/info" component={SubscribeInfoContainer} /> 
          <Route exact path="/subscribe/payment" component={SubscribePaymentContainer} />
          <Route exact path="/subscription" component={SubscriptionContainer} />
          <Route exact path="/event/:eventIdx" component={EventContainer} />
        {/*</Switch>*/}
        </div>
        <Loading isLoading={isLoading}/>
        <Modal messages={modalMessages}/>
        <Confirm messages={confirmMessages}/>
      </div>
      :
      <div>
        <Route exact path="/check" component={CheckContainer} />
        <Route exact path="/check/:zoneName(\d+)" component={CheckSeatContainer} />
        <Route exact path="/login" component={LoginContainer} />
      </div>
  );
}

export default App;
