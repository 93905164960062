
const SubscribeInfoContainer = (props) => {
  const next = () => {
    props.history.push(`./payment`);
  }

  return (
    <div>
      <div id="content" style={{background: '#000'}}>
        <img src="/imgs/img-dticket-2024.jpg" alt="" style={{width: '100%'}}/>
      </div>
      <footer>
        <div onClick={next}>구독하기</div>
        {/* <div className="ticket-storage-btn">티켓 보관함 바로가기</div> */}
      </footer>
    </div>
  );
}

export default SubscribeInfoContainer;