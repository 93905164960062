import React, {useCallback, useEffect, useState} from 'react';
import ReservationComponent from "./ReservationComponent";
import * as TicketService from "../../../../service/Ticket.service";
import {showLoading, hideLoading, showConfirm} from "../../../../helpers/layoutHelper";
import SeasonTicketComponent from './SeasonTicketComponent';
import SeriesTicketComponent from './SeriesTicketComponent';

const TicketListContainer = (props) => {
  const [isPrev, setIsPrev] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [seasonTickets, setSeasonTickets] = useState([]);
  const [seriesTickets, setSeriesTickets] = useState([]);
  const [giftCode, setGiftCode] = useState('');

  useEffect(() => {
    document.title = "티켓 보관함";
  }, [])

  useEffect(() => {
    showLoading();

    TicketService.getReservations(isPrev).then(response => {
      setReservations(response.data.reservations);
      setSeasonTickets(response.data.seasonTickets ? response.data.seasonTickets : []);
      setSeriesTickets(response.data.seriesTickets ? response.data.seriesTickets : []);
      hideLoading();
    });
  }, [isPrev]);

  const handleGiftCode = (e) => {
    setGiftCode(e.target.value);
  }

  const receiveGift = async () => {
    showLoading();
    const {status, data} = await TicketService.receiveGift(giftCode);
    if (status === 200) {
      window.location.reload();
    } else if (data.msg) {
      alert(data.msg);
      hideLoading();
    } else {
      alert('PIN번호를 등록할 수 없습니다.');
      hideLoading();
    }
  }

  const onClickDetail = useCallback(reservationIdx => {
    props.history.push(`/mypage/${reservationIdx}`);
    showLoading();
  }, [props.history]);

  const onClickSeriesDetail = useCallback(reservationIdx => {
    props.history.push(`/mypage/series/${reservationIdx}`);
    showLoading();
  }, [props.history]);

  const onClickSeasonTicketGiftDetail = useCallback((reservationIdx, gameIdx) => {
    props.history.push(`/mypage/${reservationIdx}/${gameIdx}`);
    showLoading();
  }, [props.history]);

  return (
    <div id="content">
      <div className="ticket">
        <div className="ticket_menu">
          <div className={!isPrev ? "active" : null} onClick={() => setIsPrev(false)}>
            사용가능 티켓
          </div>
          <div className={isPrev ? "active" : null} onClick={() => setIsPrev(true)}>
            사용 완료 티켓
          </div>
        </div>
        <div className="ticket_list">
          {!isPrev ?
          <div className="get_ticket">
            <div className="title">
              PIN번호 등록
            </div>
            <div className="input">
              <input type="text" placeholder="선물받은 티켓의 PIN번호를 입력해주세요." value={giftCode} onChange={handleGiftCode}/>
              <button onClick={receiveGift}>티켓등록</button>
            </div>
          </div> : null}
          {
            reservations.length > 0 || seasonTickets.length > 0 || seriesTickets.length > 0 ?
            <>
              {seasonTickets.map(seasonTicket => <SeasonTicketComponent key={seasonTicket.idx} reservation={seasonTicket} onClickDetail={onClickDetail} />)}
              {seriesTickets.map(seriesTicket => <SeriesTicketComponent key={seriesTicket.idx} reservation={seriesTicket} onClickDetail={onClickSeriesDetail} />)}
              {reservations.map(reservation => <ReservationComponent key={reservation.idx} reservation={reservation} onClickDetail={onClickDetail} onClickSeasonTicketGiftDetail={onClickSeasonTicketGiftDetail} />)}
            </>
            :
            <div className="no_games">예매 내역이 없습니다.</div>
          }
        </div>
      </div>
    </div>
  );
}

export default TicketListContainer;
