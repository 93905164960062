import React, { useEffect, useRef, useState } from 'react';
import * as TicketService from "../../../../service/Ticket.service";
import { teamData } from "../../../../data/team"
import Moment from "moment";
import { hideLoading, showConfirm, showLoading, showModal } from "../../../../helpers/layoutHelper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import moment from 'moment';

const TicketDetailContainer = (props) => {
  const reservationIdx = parseInt(props.match.params.reservationIdx);
  const gameIdx = parseInt(props.match.params.gameIdx);

  const gameSelectRef = useRef();
  const [reservation, setReservation] = useState(null);
  const [seasonTicketGifts, setSeasonTicketGifts] = useState([]);
  const [games, setGames] = useState([]);
  const [seatOptionGroups, setOptionGroups] = useState([]);
  const [state, setState] = useState({
    ticketPage: 0
  });
  const [showGameSelectPopup, setShowGameSelectPopup] = useState(false);
  const [selectedOpenPracticeGameIdx, setSelectedOpenPracticeGameIdx] = useState(null);
  const [selectedOpenPracticeReservationSeatIdx, setSelectedOpenPracticeReservationSeatIdx] = useState(null);

  useEffect(() => {
    document.title = "예매내역";

    loadData();
  }, []);

  const loadData = () => {
    TicketService.getReservationDetail(reservationIdx, gameIdx).then(response => {
      const reservation = response.data.reservation;
      setReservation(reservation);
      setSeasonTicketGifts(response.data.seasonTicketGifts);
      setGames(response.data.games);
      setOptionGroups(response.data.seatOptionGroups);

      if (props.location.hash) {
        const reservationSeatIdx = parseInt(props.location.hash.replace('#', ''));
        const ticketPage = reservation.seats.findIndex(seat => seat.reservationSeatIdx === reservationSeatIdx);
        if (ticketPage !== -1) {
          setState({
            ...state,
            ticketPage
          })
        }
      }

      hideLoading();

      if (reservation.gameIdx === 214) {
        showModal('경기 입장 시부터 7회말까지 1층 스타벅스 앞에 위치한 그라운드 하이파이브 티켓 검표소에서 참여권 사전 검수 후 손목 띠를 수령해주세요.\n8회말부터 외야 중앙문에서 대기 가능하시며, 경기 종료 후 바로 입장 시작합니다.\n손목 띠 분실 시 재수령 불가하며, 손목 띠가 없으면 입장 절대 불가한 점 참고해주세요.', '10/15그라운드 하이파이브 입장 안내');
      }
    });
  }

  const sendGift = (seat) => {
    showConfirm('티켓을 선물하시겠습니까?', null, async () => {
      showLoading();

      const { status, data } = await TicketService.sendGift(reservationIdx, seat.reservationSeatIdx);
      if (status === 200 && data.giftCode) {
        seat.giftCode = data.giftCode;
        setReservation({ ...reservation });
      } else if (data.msg) {
        alert(data.msg);
      } else {
        alert('티켓을 선물할 수 없습니다.');
      }

      hideLoading();
    });
  }

  const cancelGift = (seat) => {
    showConfirm('티켓 선물을 취소하시겠습니까?', null, async () => {
      showLoading();

      const { status, data } = await TicketService.cancelGift(reservationIdx, seat.reservationSeatIdx);
      if (status === 200) {
        seat.giftCode = null;
        setReservation({ ...reservation });
      } else if (data.msg) {
        alert(data.msg);
      } else {
        alert('티켓을 선물을 취소할 수 없습니다.');
      }

      hideLoading();
    });
  }

  const refundFeeString = () => {
    if (reservation.type !== 1 && reservation.type !== 7 && (moment(reservation.completedDatetime).isSame(reservation.playDatetime, 'day') || moment(reservation.completedDatetime).isBefore(Date(), 'day'))) {
      return '예매 후 익일 취소 또는 경기 당일 예매 후 취소 시 수수료가 부가됩니다.\n\n예매수수료는 환불되지 않으며, 예매수수료를 제외한 티켓 결제금액의 10%에 해당하는 취소수수료가 발생됩니다.\n\n';
    }

    return '';
  }

  const cancelReservation = async () => {
    if (reservation.seats.find(seat => seat.reservationSeatOptionIdx)) {
      showModal('오픈 프랙티스 취소 후 티켓을 취소해주세요.');
      return;
    }

    let msg = `${refundFeeString()}예매를 취소하시겠습니까?`;
    if (reservation.seriesReservationIdx) {
      msg += '<br/><br/>이 상품은 패키지 상품으로 다음 티켓도 함께 취소됩니다.<br/>';
      for (const key in reservation.seriesSeats) {
        if (reservation.seriesSeats[key]) {
          msg += `<br/>${reservation.seriesSeats[key]}`
        }
      }
    } else if (reservation.type === 1) {
      msg = '시즌티켓 구매를 취소하시겠습니까?<br/>(카드사 사정에 따라 매출 취소 기간이 영업일 기준 3~5일 소요될 수 있습니다.)';
    }
    showConfirm(msg, null, async () => {
      showLoading();

      const { status, data } = await TicketService.cancelReservation(reservationIdx, reservation.seriesReservationIdx);
      if (status === 200) {
        if (reservation.type === 1) {
          alert('시즌티켓 구매가 취소되었습니다.');
        } else {
          alert('예매가 취소되었습니다.');
        }
        loadData();
      } else {
        if (data.msg) {
          alert(data.msg)
        }
        hideLoading();
      }
    });
  }

  const cancelReservationSeat = async (seat) => {
    if (seat.reservationSeatOptionIdx) {
      showModal('오픈 프랙티스 취소 후 티켓을 취소해주세요.');
      return;
    }
    showConfirm(`${refundFeeString()}${seat.zoneGroup} ${seat.zone}블록 ${seat.line} ${seat.no}번 티켓을 취소하시겠습니까?`, null, async () => {
      showLoading();

      const { status, data } = await TicketService.cancelReservationSeat(reservationIdx, seat.seatIdx);
      if (status === 200) {
        alert('티켓이 취소되었습니다.');
        loadData();
      } else {
        if (data.msg) {
          alert(data.msg)
        }
        hideLoading();
      }
    });
  }

  const purchaseOpenPractice = async (seat, gameIdx) => {
    setSelectedOpenPracticeReservationSeatIdx(seat.reservationSeatIdx);
    setSelectedOpenPracticeGameIdx(gameIdx);
  }

  const cancelOpenPractice = async (reservationSeatOptionIdx) => {
    showConfirm('오픈 프랙티스 티켓을 취소하시겠습니까?<br/>(취소는 경기 당일 4시간 전 까지만 가능합니다.)', null, async () => {
      showLoading();

      const { status, data } = await TicketService.cancelOpenPractice(reservationSeatOptionIdx);
      if (status === 200) {
        alert('오픈 프랙티스 구매가 취소되었습니다.');
        loadData();
      } else {
        if (data.msg) {
          alert(data.msg)
        }
        hideLoading();
      }
    });
  }

  const QRArea = (props) => {
    if (props.seat.status === -1) {
      return (
        <div className="qr">
          <div className="case case01">
            <i />
            <p className="case01">취소된 티켓입니다.</p>
          </div>
          <div className="enter gray">취소완료</div>
        </div>
      );
    } else if (props.seat.ticketNo) {
      return (
        <div className="qr">
          {reservation.doubleHeader > 0 &&
            <div style={{ position: 'absolute', top: 10, left: '50%', width: 160, height: 36, marginLeft: -80, background: '#123269', fontSize: 18, fontWeight: 700, lineHeight: '34px', color: 'white' }}>{reservation.doubleHeader}차전</div>
          }
          <div style={{ width: 200, height: 200, padding: 20, margin: 'auto', background: '#FFF' }}>
            <QRCode value={props.seat.ticketNo} size={160} />
          </div>
          <div className="enter">발권완료</div>
          <div style={{ position: 'absolute', bottom: 6, left: 0, right: 0, fontSize: '12px' }}>{props.seat.ticketNo}</div>
        </div>
      );
    } else if (props.seat.smartTicket === 2) {
      return (
        <div className="qr">
          <div className="case case01">
            <i />
            <p className="case01">지류티켓으로 발권한 티켓입니다.</p>
          </div>
          <div className="enter">발권완료</div>
        </div>
      );
    } else if (props.seat.received === 1) {
      return (
        <div className="qr">
          <div className="case case02">
            <i />
            {
              props.seat.smartTicket === 0 ?
                <p className="case02">선물받은 티켓입니다.<br /><br />매표소에서 증빙 후 발권 가능합니다.</p>
                :
                <p className="case02">선물받은 티켓입니다.<br /><br />검표를 위한 QR코드는 경기 시작<br /><span>4시간 전</span>부터 표시됩니다.</p>
            }
          </div>
          {
            props.seat.smartTicket === 0 ?
              <div className="enter red">증빙필요</div>
              :
              <div className="enter">발권완료</div>
          }
        </div>
      );
    } else if (props.seat.giftCode != null) {
      if (props.seat.giftReceivedDatetime != null) {
        return (
          <div className="qr">
            <div className="case case02">
              <i />
              <p className="case02">선물하기가 완료된 티켓입니다.</p>
            </div>
            <div className="enter">선물완료</div>
          </div>
        );
      } else {
        return (
          <div className="qr">
            <div className="case case02">
              <i />
              <p className="case02">선물한 티켓입니다.</p>
            </div>
            <div className="enter">선물대기</div>
          </div>
        );
      }
    } else if (props.seat.smartTicket === 0) {
      return (
        <div className="qr">
          <div className="case case01">
            <i />
            <p className="case01">매표소에서 증빙 후 발권 가능합니다.</p>
          </div>
          <div className="enter red">증빙필요</div>
        </div>
      );
    } else {
      return (
        <div className="qr">
          <div className="case case03">
            <i />
            {/* <p className="case03">검표를 위한 QR코드는 <span>입장 시간 직전에</span> 표시됩니다.</p> */}
            <p className="case03">검표를 위한 QR코드는 경기 시작<br /><span>4시간 전</span>부터 표시됩니다.</p>
          </div>
          <div className="enter">예매완료</div>
        </div>
      );
    }
  }

  function TicketList(props) {
    const listItems = props.seats.sort((a, b) => b.seatIdx * b.status - a.seatIdx * a.status).map((seat) => {
      return (
        <SwiperSlide key={seat.seatIdx}>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <div className="title">
              좌석등급
            </div>
            <div className="value">
              {seat.zoneGroup}
            </div>
          </div>
          <div style={{ display: 'inline-block', width: '50%', position: 'relative' }}>
            <div className="title">
              좌석정보
            </div>
            <div className="value">
              {seat.zoneGroupIdx === 100 ? seat.zone : `${seat.zone}블록 ${seat.line} ${seat.no}번`}
            </div>
            {seat.extraPrice > 0 &&
              <div style={{ position: 'absolute', top: 40, left: 24, fontSize: 12, fontWeight: 'bold', color: '#ee2526' }}>{seat.extraPriceName}</div>
            }
          </div>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <div className="title">
              권종정보
            </div>
            <div className="value">
              {seat.paymentTitle}
            </div>
          </div>
          {
            reservation.type === 1 && !reservation.gift &&
              <div style={{ display: 'inline-block', width: '50%' }}>
                <div className="title">닉네임</div>
                <div className="value">{reservation.seasonTicketShippingAddr.nickname !== '' ? reservation.seasonTicketShippingAddr.nickname : '-'}</div>
              </div>
          }
          {
            (reservation.type !== 1 || reservation.gift === 1) &&
              <div style={{ display: 'inline-block', width: '50%' }}>
                <div className="title">오픈 프랙티스</div>
                <div className="value">{seat.reservationSeatOptionIdx ? seat.seatOptionName : '-'}</div>
              </div>
          }
          {seat.status === 3 && reservation.gift !== 1 &&
            seatOptionGroups.map(seatOptionGroup =>
              <div className="open_practice">
                {
                  seat.reservationSeatOptionIdx ?
                    <button onClick={() => cancelOpenPractice(seat.reservationSeatOptionIdx)}>오픈 프랙티스 취소</button>
                    :
                    <button onClick={() => purchaseOpenPractice(seat, reservation.gameIdx)}>오픈 프랙티스 구매</button>
                }
              </div>
            )
          }
          {/* {seat.seatOptionName ?
            <div style={{ display: 'inline-block' }}>
              <div className="title">
                오픈 프랙티스 권종
              </div>
              <div className="value" style={{ fontSize: 13 }}>
                {seat.seatOptionName} ({seat.seatOptionComment})
              </div>
            </div>
            : null} */}
          <QRArea seat={seat} style={{ clear: "both" }} />
          {reservation.gift ? null : (seat.giftCode ?
            <div className="pin_cancle">
              {seat.giftReceivedDatetime == null ?
                <div className="pin-guide">
                  <div className="pin_title">
                    PIN번호
                  </div>
                  <div className="pin_desc">
                    PIN번호를 복사하여 티켓을 선물할 분에게 전달해주세요.
                  </div>
                  <div className="pin_value">
                    {seat.giftCode}
                  </div>
                  <CopyToClipboard text={seat.giftCode}>
                    <button className="copy">
                      <i></i><span>복사</span>
                    </button>
                  </CopyToClipboard>
                </div> : null}
              <p>
                <button className="present" onClick={() => cancelGift(seat)} disabled={seat.status < 0} style={{ width: '100%' }}>선물취소</button>
              </p>
            </div> : (reservation.type !== 1 ?
              <div className="pin_cancle">
                <p>
                  <button className="cancle" onClick={() => cancelReservationSeat(seat)} disabled={!reservation.cancelable || seat.status < 0 || reservation.usedPoint > 0 || reservation.userCouponIdx || seat.seatGroupIdx != null || seat.paymentTypeIdx === 7}>부분취소</button>
                  <button className="present" onClick={() => sendGift(seat)} disabled={seat.status < 0}>선물하기</button>
                </p>
              </div> : <div style={{ height: 20 }} />
            )
          )
          }
        </SwiperSlide>
      );
    }
    );

    return (
      <Swiper
        pagination={true}
        modules={[Pagination]}
        spaceBetween={20}
        initialSlide={state.ticketPage}
        onActiveIndexChange={(swiper) => {
          state.ticketPage = swiper.activeIndex;
          window.history.replaceState("Swiper", "", `#${reservation.seats[swiper.activeIndex].reservationSeatIdx}`);
        }}>
        {listItems}
      </Swiper>
    )
  }

  return (
    reservation != null &&
    <>

      <div id="content">
        <div className="ticket">
          <div className="ticket_view">
            <div className="info">
              {reservation.type === 1 && !reservation.gift ?
                <div className="team">{reservation.reservationName}</div>
                :
                <>
                  <div className="team">
                    {reservation.eventTitle ? reservation.eventTitle : `NC VS ${teamData[reservation.awayTeamId].shortName}`}
                    {reservation.doubleHeader > 0 && ` ${reservation.doubleHeader}차전`}
                  </div>
                  <div className="date">
                    {reservation.gameIdx === 214 ? '2023.10.15 경기종료 후' : (reservation.playDatetime ? Moment(reservation.playDatetime).format('YYYY.MM.DD(ddd) HH:mm') : '미편성 경기')}, {reservation.stadiumName}
                  </div>
                </>
              }
              {reservation.gameIdx === 111 || reservation.gameIdx === 112 ?
                <div className="count">
                  총 {reservation.seats.length}장{reservation.gift ? `(${reservation.userName}님이 선물한 티켓)` : ''}
                </div>
                :
                <div className="count">
                  총 {reservation.seats.length}장({reservation.gift ? `${reservation.userName}님이 선물한 티켓` : `${Moment(reservation.completedDatetime).format('YYYY.MM.DD')}일 예매`})
                </div>
              }
              {reservation.eventTitle === '' ?
                <div className="emblem">
                  <span>VS</span> <img src={`/imgs/img-embelm-${reservation.awayTeamId}-60.png`}
                    srcSet={`/imgs/img-embelm-${reservation.awayTeamId}-60@2x.png 2x, /imgs/img-embelm-${reservation.awayTeamId}-60@3x.png 3x`}
                    className="img_embelm_samsung_60" />
                </div> : null
              }

            </div>
            <div className="ticket_view_list">
              <div>
                <div className="catch">
                  <img src="/imgs/img-ticket-banner-2024.jpg" className="img_ticket_banner" />
                  {reservation.type === 1 && <span class="season-ticket-tag">시즌권</span>}
                </div>
                <div className="ticket_info swiper">
                  <TicketList seats={reservation.seats} />
                </div>
              </div>

              {(!reservation.cancelable || reservation.gift) ? null :
                <div className="cancle_all">
                  <button onClick={cancelReservation} disabled={!reservation.cancelable || reservation.status < 0}>전체 취소</button>
                </div>
              }
              {
                reservation.type === 1 && reservation.status === 3 && games.length > 0 && !reservation.gift ?
                  <button className="send_gift_season_ticket" onClick={() => { setShowGameSelectPopup(true); }}>티켓 선물하기</button>
                  : null
              }
              {
                games.filter(game => game.seatOptionGroupName != null).map(game =>
                  <div className="open_practice">
                  {
                    game.seatOptionName ?
                    <button onClick={game.cancelable ? () => cancelOpenPractice(game.reservationSeatOptionIdx) : null}>오픈 프랙티스 {game.seatOptionName}{game.cancelable? ' 취소' : ' 구매완료'} ({Moment(game.playDatetime).format('M/D ddd')} {game.teamName})</button>
                    :
                    <button onClick={() => purchaseOpenPractice(reservation.seats[0], game.idx)}>오픈 프랙티스 구매 ({Moment(game.playDatetime).format('M/D ddd')} {game.teamName})</button>
                  }
                  </div>
                )
              }
              {
                seasonTicketGifts.length > 0 ?
                  <div className='season-ticket-gift'>
                    <h3>시즌티켓 선물 리스트</h3>
                    <ul>
                      {seasonTicketGifts.map(gift => <li>
                        <div className='game_info'>{Moment(gift.playDatetime).format('YYYY.MM.DD(ddd) HH:mm')} NC vs {gift.teamName}</div>
                        {gift.giftReceivedDatetime ?
                          <div style={{ marginTop: 8, marginBottom: 4, fontSize: 12, color: '#747474' }}>선물 수령 완료 ({Moment(gift.giftReceivedDatetime).format('YYYY.MM.DD HH:mm')})</div>
                          :
                          <div className='pin-guide'>
                            <div class="pin_title">PIN번호</div>
                            <div class="pin_desc">PIN번호를 복사하여 티켓을 선물할 분에게 전달해주세요.</div>
                            <div className='pin_value'>{gift.giftCode}</div>
                            <CopyToClipboard text={gift.giftCode}>
                              <button class="copy"><i></i><span>복사</span></button>
                            </CopyToClipboard>
                          </div>
                        }
                        <button class="cancel" onClick={async () => {
                          showLoading();
                          const { status, data } = await TicketService.cancelSeasonTicketGift(gift.reservationSeatIdx, gift.gameIdx);
                          if (status === 200) {
                            loadData();
                          } else {
                            if (data.msg) {
                              alert(data.msg);
                            } else {
                              alert('티켓을 선물을 취소할 수 없습니다.');
                            }
                            hideLoading();
                          }
                        }}>선물취소</button>
                      </li>)}
                    </ul>
                  </div>
                  : null
              }
              <div className="cancle_desc">
                {reservation.cancelable ?
                  <>
                    <div className="title">
                      부분 취소 안내
                    </div>
                    <div className="desc">
                      <ul>
                        {
                          reservation.seriesReservationIdx ?
                            <li>이 상품은 패키지 상품으로 부분취소가 되지 않습니다(일괄취소, 우천취소 제외)</li>
                            :
                            <>
                              <li>쿠폰/포인트 사용으로 구매한 티켓은 부분취소가 불가능합니다. 전체 취소 후 재 예매하여야 합니다.</li>
                              <li>선물한 티켓은 부분취소가 불가능하며, 선물 취소 후 부분 취소하여야 합니다.</li>
                            </>
                        }
                      </ul>
                    </div>
                    <br />
                    <div className="title">
                      취소 수수료 안내
                    </div>
                    <div className="desc">
                      <ul>
                        <li>예매당일 : 취소수수료 없음, 이후 취소시 환불 불가</li>
                        <li>예매익일 ~ 경기일 전일(24:00) : 티켓금액의 10% 취소수수료 발생</li>
                        <li>경기 당일 예매는 결제 이후 취소가 불가능합니다.</li>
                      </ul>
                    </div>
                    <br />
                  </> : null
                }
                {
                  reservation.gift ?
                    <>
                      <div className="title">
                        선물 티켓 사용 안내
                      </div>
                      <div className="desc">
                        <ul>
                          <li>선물한 분이 선물취소를 하면 티켓이 사라집니다.</li>
                          <li>선물받은 티켓이 증빙이 필요한 권종일 경우 선물받은 분이 직접 매표소에 오셔서 해당 권종에 필요한 증빙을 해주셔야 하며, 증빙이 안되는 경우 할인된 금액만큼 추가 결제를 하셔야 합니다.</li>
                        </ul>
                      </div>
                    </>
                    : null
                }
              </div>
            </div>

          </div>
        </div>
      </div>
      {showGameSelectPopup &&
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            bottom: 0,
            width: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 10000,
          }}>
          <div className="pop-wrap">
            <div className="pop-title-center" style={{ marginTop: 10, marginBottom: 20 }}>경기 선택</div>
            <div className="pop-cont">
              <select ref={gameSelectRef} style={{ width: '100%' }}>
                {
                  games.filter(game => seasonTicketGifts.find(gift => gift.gameIdx === game.idx) === undefined).map(game => <option value={game.idx}>{`${Moment(game.playDatetime).format('YYYY.MM.DD(ddd) HH:mm')} NC vs ${game.teamName}`}</option>)
                }
              </select>
            </div>
            <div className="pop-btn">
              <button className="gray" onClick={() => {
                setShowGameSelectPopup(false);
              }}>취소</button>
              <button className="navy" onClick={async () => {
                setShowGameSelectPopup(false);
                showLoading();

                const { status, data } = await TicketService.sendSeasonTicketGift(reservation.seats[0].reservationSeatIdx, parseInt(gameSelectRef.current.value));

                if (status === 200 && data.giftCode) {
                  loadData();
                } else {
                  if (data.msg) {
                    alert(data.msg);
                  } else {
                    alert('티켓을 선물할 수 없습니다.');
                  }
                  hideLoading();
                }
              }}>확인</button>
            </div>
          </div>
        </div>}
      {selectedOpenPracticeReservationSeatIdx != null &&
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          bottom: 0,
          width: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 10000,
        }}>
          <div className="pop-wrap">
            <div className="pop-cont">
              <p style={{ padding: '16px 0 8px', fontSize: 13 }}>
                <h3 style={{ fontSize: 14, marginBottom: 4 }}>참여방법</h3>
                구매 시 1시간 선입장하여 ‘홈팀’ 훈련을 관람할 수 있습니다.<br/>
                선수단 훈련 관람 시 자유롭게 착석 가능하나 시즌회원 입장 후부터 시즌회원 좌석은 착석 불가합니다.<br/>
                선수단 훈련을 방해하지 않도록 유의해 관람해주세요. 상황에 따라 진행요원이 퇴장을 요구할 수 있습니다.<br/>
                GATE 1로 선입장합니다. 언제라도 재입장 가능하며, 별도의 퇴장 절차가 없습니다.<br/>
                <span style={{ color: '#FF3529' }}>(프리미엄 : 경기 3시간~2시간 30분 전 까지 3루 더그아웃 출입 가능)</span>
              </p>
              <p style={{ padding: '16px 0 8px', fontSize: 13 }}>
                <h3 style={{ fontSize: 14, marginBottom: 4 }}>환불 및 취소</h3>
                취소의 경우 경기시작 4시간 전까지 가능합니다.<br />
                단, 날씨에 따라 선수단 훈련을 관람하지<br />
                못한 경우 환불해 드립니다.<br />
                <span style={{ color: '#FF3529' }}>(예 : 선수단후련 관람 후 경기 전 또는 경기 중 취소<br />
                  되는 경우 티켓은 환불, 오픈 프랙티스는 미환불)</span>
              </p>
            </div>
            <div className="pop-btn">
              <button className="gray" onClick={() => {
                setSelectedOpenPracticeReservationSeatIdx(null);
              }}>취소</button>
              <button className="navy" onClick={async () => {
                props.history.push(`/mypage/${reservationIdx}/${selectedOpenPracticeGameIdx}/openPractice/${selectedOpenPracticeReservationSeatIdx}`);
                showLoading();
              }}>결제하기</button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default TicketDetailContainer;
