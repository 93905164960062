import React, {useEffect, useState} from 'react';
import NumberFormat from "react-number-format";
import * as TicketService from "../../../../service/Ticket.service";
import {hideLoading, showLoading, showModal} from "../../../../helpers/layoutHelper";
import Agreements from "../../../../data/agreements.json";
import Moment from "moment";

const SigningEventContainer = (props) => {

  const reservationIdx = parseInt(props.match.params.reservationIdx);
  const reservationSeatIdx = parseInt(props.match.params.reservationSeatIdx);

  const [reservation, setReservation] = useState(null);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [seatOptions, setSeatOptions] = useState([]);
  const [selectedSeatOptionIdx, setSelectedSeatOptionIdx] = useState(0);
  const [myPoint, setMyPoint] = useState(0);
  const [usePoint, setUsePoint] = useState(0);
  const [ncPays, setNcPays] = useState([]);
  const [ncPayIndex, setNcPayIndex] = useState(0);
  const [cancelFeeAgreed, setCancelFeeAgreed] = useState(false);
  const [selectedPayMethod, setSelectedPayMethod] = useState('');
  const [agreements, setAgreements] = useState([
    {
      title: '서비스 이용약관 동의 (필수)',
      checked: false,
      name: 'terms',
      message: Agreements.terms
    },
    {
      title: '개인정보 수집 및 이용동의 (필수)',
      checked: false,
      message: Agreements.privacy
    }
  ]);

  const openPracticePrice = 0;

  useEffect(() => {

    document.title = "오픈 프랙티스 구매";

    fetchData();
  }, []);

  async function fetchData() {
    {
      const {data} = await TicketService.requestMyWallet();
      setUserName(data.name);
      setUserPhone(data.phone);
      setUserEmail(data.email);
      setMyPoint(data.point);
      setNcPays(data.ncPays);
    }
    {
      const {data} = await TicketService.getReservationDetail(reservationIdx);
      setReservation(data.reservation);
      setSeatOptions(data.seatOptionGroups);
    }
    hideLoading();
  }

  function paymentAmount() {
    return Math.max(openPracticePrice - usePoint, 0);
  }

  const checkAgreements = () => {
    return agreements.reduce((a, b) => a && b.checked, true) && cancelFeeAgreed && selectedSeatOptionIdx !== 0;
  }

  const requestPay = async () => {
    showLoading();

    const {status, data} = await TicketService.submitSigningEvent(reservationSeatIdx, selectedSeatOptionIdx);

    if (status === 200) {
      props.history.push(`/mypage/${reservationIdx}/signingEvent/${reservationSeatIdx}/complete`);
    } else {
      if (data.msg) {
        alert(data.msg)
      }
      fetchData();
    }
  }

  const showTerms = (e) => {
    showModal(e.target.dataset.message, e.target.dataset.title);
  }

  function GroupList() {
    const listItems = seatOptions.map((seatOption) => {
      return (
        <option key={seatOption.idx} value={seatOption.idx}>{seatOption.name}</option>
      );
    })

    return (
      <select value={selectedSeatOptionIdx} onChange={(e) => setSelectedSeatOptionIdx(parseInt(e.target.value))}>
        <option value="0">오픈 프랙티스 권종을 선택해 주세요.</option>
        {listItems}
      </select>
    );
  }

  function AgreementList(props) {
    const handleChange = index => {
      setAgreements(
        agreements.map((agreement, key) =>
          key === index ? {...agreement, checked: !agreement.checked} : agreement)
      );
    }

    const listItems = props.agreements.map((agreement, index) => {
      return (
        <li key={index}>
          <label className="form-label">
            <input type="checkbox" checked={agreement.checked} onChange={() => handleChange(index)}/>
            <span>{agreement.title}</span>
          </label>
          <span onClick={showTerms} data-message={agreement.message} data-title={agreement.title}>상세보기</span>
        </li>
      );
    });
    return (
      <div className="list">
        <ul>
          {listItems}
        </ul>
      </div>
    );
  }

  return (
    reservation != null &&
    <div>
      <div id="content">
        <div className="order_info">
          <div className="title">
            구매자 정보 입력
          </div>
          <div className="user-input">
            <ul>
              <li>이름 (필수)</li>
              <li><input type="text" value={userName || ''} readOnly={true}/></li>
              <li>휴대폰 번호 (필수)</li>
              <li><input type="text" value={userPhone || ''} readOnly={true}/></li>
              <li>이메일 (선택)</li>
              <li><input type="email" value={userEmail || ''} onChange={(e) => {setUserEmail(e.target.value)}}/></li>
            </ul>
          </div>
        </div>

        <div className="sale">
          <div className="title">오픈 프랙티스 권종 선택</div>
          <div className="sale-coupon">
            <ul>
              <li><span><input type='radio'/> 일반</span><span>3,000원</span></li>
              <li><span><input type='radio'/> 프리미엄 [30/30]</span><span>5,000원</span></li>
            </ul>
            {/* <GroupList /> */}
          </div>
        </div>

        <div className="pay-price">
          <div className="title">최종 결제 금액</div>
          <ul>
            <li><span>오픈 프랙티스 일반</span><span>3,00<NumberFormat value={openPracticePrice} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
            <li className="total-price"><span>총 결제 금액</span><span>3,00<NumberFormat value={paymentAmount()} displayType={"text"} thousandSeparator={true}/><span>원</span></span></li>
          </ul>
          <h6>오픈 프랙티스 취소 안내</h6>
          <p dangerouslySetInnerHTML={{__html: `&#8901; 취소수수료 없음<br/>&#8901; 11월 24일(금) 오후 2시 이후 취소 불가`}}></p>
          
          <label><input type="checkbox" checked={cancelFeeAgreed} onChange={() => setCancelFeeAgreed(!cancelFeeAgreed)} /> <span>사인회 참여권 취소 안내에 동의합니다.</span></label>

        </div>

        <div className="agreement">
          <div className="title">
            약관 동의
            <label><input type="checkbox" onChange={(e) => setAgreements(agreements.map((agreement) => {
              return {
                ...agreement,
                checked: e.target.checked
              }
            }))}/><span>모두 동의</span></label>
          </div>
          <AgreementList agreements={agreements} />
        </div>

        <div className="ticket_summary">
          <div>
            <span>총 결제금액</span><span>3,00<NumberFormat value={paymentAmount()} displayType={"text"} thousandSeparator={true}/>원</span>
          </div>
        </div>
      </div>
      <footer>
        <div className="prev" onClick={() => {props.history.goBack()}}>이전단계</div>
        <div className={`next${checkAgreements() ? '' : ' disabled'}`} onClick={checkAgreements() ? requestPay : null}>결제하기</div>
      </footer>
      <form name="payForm" method="post" action={`${process.env.REACT_APP_TICKET_API_URL}/openPractice/payment/confirm`}>
        <input type="hidden" name="GoodsName" value={`오픈 프랙티스 (${Moment(reservation.playDatetime).format('MM.DD')})`}/>
        <input type="hidden" name="Amt" value={paymentAmount()}/>
        <input type="hidden" name="MID" value={process.env.REACT_APP_NICEPAY_MID}/>
        <input type="hidden" name="EdiDate"/>
        <input type="hidden" name="Moid"/>
        <input type="hidden" name="SignData"/>
        <input type="hidden" name="BuyerName" value={userName}/>
        <input type="hidden" name="BuyerTel" value={userPhone}/>
        <input type="hidden" name="ReturnURL" value={`${process.env.REACT_APP_TICKET_API_URL}/openPractice/payment/confirm`}/>
        <input type="hidden" name="BuyerEmail" value={userEmail}/>
        <input type="hidden" name="CharSet" value="utf-8"/>
        <input type="hidden" name="VbankExpDate" id="vExp"/>
        <input type="hidden" name="GoodsCl" value={1}/>
        <input type="hidden" name="WapUrl" value="ncdinos://"/>
        <input type="hidden" name="PayMethod" value={selectedPayMethod}/>
        <input type="hidden" name="ReqReserved"/>
        <input type="hidden" name="MallReserved"/>
        <input type="hidden" name="CardQuota" value="00"/>
        <input type="hidden" name="OSType"/>
      </form>
    </div>
  );
}

export default SigningEventContainer;
