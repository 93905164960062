import React, {useState} from 'react';
import * as TicketService from "../../service/Ticket.service";
import {useCookies} from "react-cookie";

const LoginContainer = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cookies, setCookie] = useCookies(['token', 'refreshToken']);

  const login = async () => {
    const {status, data} = await TicketService.login(username, password);
    if (status === 200) {
      setCookie('token', data.token, {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/'
      });
      setCookie('refreshToken', data.refreshToken, {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/'
      });
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('refresh', data.token, data.refreshToken);
      }

      window.location.href = '/games';
    } else {
      alert('아이디/비밀번호를 확인해주세요.');
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  }

  return (
    <div id="login">
        <ul class="login-wrap">
            <li><img src="/imgs/img-embelm-128@2x.png" /></li>
            <li><h6>반갑습니다. NC 다이노스 입니다.</h6></li>
            <li><input type="text" value={username} placeholder="아이디를 입력해 주세요." onChange={(e) => setUsername(e.target.value)} onKeyPress={onKeyPress} /></li>
            <li><input type="password" value={password} placeholder="비밀번호를 입력해 주세요." onChange={(e) => setPassword(e.target.value)} onKeyPress={onKeyPress} /></li>
            <li><button type="button" onClick={login}>로그인</button></li>


            <li><p>회원가입 및 아이디 비밀번호 찾기는<br />NC 다이노스 홈페이지를 이용해주세요.</p></li>
            <li><button class="brown" type="button" onClick={(e) => {window.location.href = 'https://www.ncdinos.com/'}}>홈페이지 바로가기</button></li>
        </ul>
            {/* <div>
      <input type="text" placeholder="ID" value={username} onChange={(e) => setUsername(e.target.value)}/><br/>
      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/><br/>
      <button onClick={login}>로그인</button>
    </div> */}

    </div>
  );
}

export default LoginContainer;