import * as ApiService from './Api.service'
import axios from "axios";
import {updateToken} from "./Axios.interceptor";

export const login = async (username, password) => {
  const url = '../auth/login'

  return ApiService.post(url, {username, password});
}

export const getGames = async () => {
  const url = '/games';

  return ApiService.get(url);
}

export const getSingleSeries = async () => {
  const url = '/singleSeries';

  return ApiService.get(url);
}

export const getTeamSerieses = async () => {
  const url = '/teamSeries';

  return ApiService.get(url);
}

export const getEventApplicants = async () => {
  const url = '/eventApplicants';

  return ApiService.get(url);
}

export const getPrevReservation = async (matchIdx) => {
  const url = '/prevReservation';

  return ApiService.get(url, {matchIdx});
}

export const getPrevSeriesReservation = async (seriesIdx) => {
  const url = '/prevSeriesReservation';

  return ApiService.get(url, {seriesIdx});
}

export const getPrevSeasonTicketReservation = async () => {
  const url = '/prevSeasonTicketReservation';

  return ApiService.get(url);
}

export const getTodayZones = async () => {
  const url = '/todayZones';

  return ApiService.get(url);
}

export const getZones = async (matchIdx) => {
  const url = '/zones';

  return ApiService.get(url, {matchIdx});
}

export const getSeriesZones = async (seriesIdx) => {
  const url = '/seriesZones';

  return ApiService.get(url, {seriesIdx});
}

export const getSeasonTicketZones = async () => {
  const url = '/seasonTicketZones';

  return ApiService.get(url);
}

export const getSeats = async (matchIdx, zoneIdx) => {
  const url = '/seats';

  return ApiService.get(url, {matchIdx, zoneIdx});
}

export const getSeriesSeats = async (seriesIdx, zoneIdx) => {
  const url = '/seriesSeats';

  return ApiService.get(url, {seriesIdx, zoneIdx});
}

export const getSeasonTicketSeats = async (zoneIdx) => {
  const url = '/seasonTicketSeats';

  return ApiService.get(url, {zoneIdx});
}

export const getReservedSeats = async (zoneName) => {
  const url = '/reservedSeats';

  return ApiService.get(url, {zoneName});
}

export const reserveSeats = async (matchIdx, seats) => {
  const url = '/reserveSeats'

  return ApiService.post(url, {matchIdx, seats});
}

export const reserveSeriesSeats = async (seriesIdx, seats) => {
  const url = '/reserveSeriesSeats'

  return ApiService.post(url, {seriesIdx, seats});
}

export const reserveSeasonTicketSeats = async (seats) => {
  const url = '/reserveSeasonTicketSeats'

  return ApiService.post(url, {seats});
}

export const excludeSeats = async (matchIdx, seats, memo) => {
  const url = '/excludeSeats'

  return ApiService.post(url, {matchIdx, seats, memo});
}

export const cancelSeat = async (reservationIdx) => {
  const url = '/cancelSeat'

  return ApiService.post(url, {reservationIdx});
}

export const cancelSeriesSeat = async (seriesReservationIdx) => {
  const url = '/cancelSeat'

  return ApiService.post(url, {seriesReservationIdx});
}

export const checkVoucherCode = async (reservationIdx, code) => {
  const url = '/checkVoucher';

  return ApiService.post(url, {reservationIdx, code});
}

export const getPayments = async (reservationIdx) => {
  const url = '/payments'

  return ApiService.get(url, {reservationIdx});
}

export const getSeriesPayments = async (seriesReservationIdx) => {
  const url = '/seriesPayments'

  return ApiService.get(url, {seriesReservationIdx});
}

export const getSeasonTicketInfo = async (matchIdx, zoneIdx) => {
  const url = '/seasonTicketInfo';

  return ApiService.get(url);
}

export const getSeasonTicketPayments = async (reservationIdx) => {
  const url = '/seasonTicketPayments';

  return ApiService.get(url, {reservationIdx});
}

export const setSeatPayments = async (reservationIdx, seatPayments, vouchers) => {
  const url = '/seatPayments'

  return ApiService.post(url, {reservationIdx, seatPayments, vouchers});
}

export const getReservationSummary = async (reservationIdx) => {
  const url = '/reservationSummary';

  return ApiService.get(url, {reservationIdx});
}

export const getReservations = async (isPrev) => {
  const url = isPrev ? '/reservations/prev' : '/reservations';

  return ApiService.get(url);
}

export const getSeriesReservations = async (seriesReservationIdx) => {
  const url = '/seriesReservations';

  return ApiService.get(url, {seriesReservationIdx});
}

export const getReservationDetail = async (reservationIdx, gameIdx) => {
  const url = '/reservationDetail';

  return ApiService.get(url, {reservationIdx, gameIdx});
}

export const startPayment = async (reservationIdx, seriesReservationIdx, usedPoint, userCouponIdx) => {
  const url = '/startPayment';

  return ApiService.post(url, {reservationIdx, seriesReservationIdx, usedPoint, userCouponIdx});
}

export const cancelReservation = async (reservationIdx, seriesReservationIdx) => {
  const url = '/cancelReservation'

  return ApiService.post(url, {reservationIdx, seriesReservationIdx});
}

export const cancelReservationSeat = async (reservationIdx, seatIdx) => {
  const url = '/cancelReservationSeat'

  return ApiService.post(url, {reservationIdx, seatIdx});
}

export const sendGift = async (reservationIdx, reservationSeatIdx) => {
  const url = '/sendGift'

  return ApiService.post(url, {reservationIdx, reservationSeatIdx});
}

export const cancelGift = async (reservationIdx, reservationSeatIdx) => {
  const url = '/cancelGift'

  return ApiService.post(url, {reservationIdx, reservationSeatIdx});
}

export const receiveGift = async (giftCode) => {
  const url = '/receiveGift'

  return ApiService.post(url, {giftCode});
}

export const sendSeasonTicketGift = async (reservationSeatIdx, gameIdx) => {
  const url = '/sendSeasonTicketGift'

  return ApiService.post(url, {reservationSeatIdx, gameIdx});
}

export const cancelSeasonTicketGift = async (reservationSeatIdx, gameIdx) => {
  const url = '/cancelSeasonTicketGift'

  return ApiService.post(url, {reservationSeatIdx, gameIdx});
}

export const confirmNcPayPassword = async (ncPayId, password) => {
  const url = '/ncPay/password'

  return ApiService.post(url, {ncPayId, password});
}

export const requestNcPayPayment = async (reservationIdx, seriesReservationIdx, ncPayId, usedPoint, userCouponIdx, cardQuota, shippingInfo = null) => {
  const url = '/ncPay/payment'

  return ApiService.post(url, {reservationIdx, seriesReservationIdx, ncPayId, usedPoint, userCouponIdx, cardQuota, shippingInfo});
}

export const requestPointPayment = async (reservationIdx, usedPoint, userCouponIdx) => {
  const url = '/point/payment'

  return ApiService.post(url, {reservationIdx, usedPoint, userCouponIdx});
}

export const startOpenPracticePayment = async (gameIdx, reservationSeatIdx, seatOptionIdx, usedPoint) => {
  const url = '/openPractice/payment/start';

  return ApiService.post(url, {gameIdx, reservationSeatIdx, seatOptionIdx, usedPoint});
}

export const requestOpenPracticePaymentNCPay = async (gameIdx, reservationSeatIdx, seatOptionIdx, ncPayId, usedPoint, userName, userPhone, userEmail) => {
  const url = '/openPractice/payment/ncpay'

  return ApiService.post(url, {gameIdx, reservationSeatIdx, seatOptionIdx, ncPayId, usedPoint, userName, userPhone, userEmail});
}

export const requestOpenPracticePaymentPoint = async (reservationSeatIdx, usedPoint) => {
  const url = '/openPractice/payment/point'

  return ApiService.post(url, {reservationSeatIdx, usedPoint});
}

export const cancelOpenPractice = async (reservationSeatOptionIdx, gameIdx) => {
  const url = '/openPractice/cancel'

  return ApiService.post(url, {reservationSeatOptionIdx, gameIdx});
}

export const submitSigningEvent = async (reservationSeatIdx, seatOptionIdx) => {
  const url = '/signingEvent/submit'

  return ApiService.post(url, {reservationSeatIdx, seatOptionIdx});
}

export const cancelSigningEvent = async (reservationSeatOptionIdx) => {
  const url = '/signingEvent/cancel'

  return ApiService.post(url, {reservationSeatOptionIdx});
}

export const startPRPassPayment = async (usedPoint, userCouponIdx) => {
  const url = '/startPRPassPayment';

  return ApiService.post(url, {usedPoint, userCouponIdx});
}

export const requestPRPassPaymentNCPay = async (ncPayId, usedPoint, userCouponIdx) => {
  const url = '/pass/priority/payment/ncpay';

  return ApiService.post(url, {ncPayId, usedPoint, userCouponIdx});
}

export const requestPRPassPaymentPoint = async () => {
  const url = '/pass/priority/payment/point';

  return ApiService.post(url, {});
}

export const requestMyWallet = async () => {
  const url = '/wallet'

  return ApiService.get(url);
}

export const getVouchers = async (reservationIdx) => {
  const url = '/vouchers'

  return ApiService.get(url, {reservationIdx});
}

export const agreeSeasonTicketAgreement = async () => {
  const url = '/agreeSeasonTicketAgreement'

  return ApiService.post(url);
}

export const getSeasonInfo = async () => {
  const url = '/seasonInfo'

  return ApiService.get(url);
}

export const requestSubscription = async (ncPayId, name, phone, email) => {
  const url = '/subscribe'

  return ApiService.post(url, {ncPayId, name, phone, email});
}

export const getSubscriptions = async () => {
  const url = '/subscriptions'

  return ApiService.get(url);
}

export const cancelSubscription = async (subscriptionIdx) => {
  const url = '/cancelSubscription'

  return ApiService.post(url, {subscriptionIdx});
}

export const getEventDetail = async (eventIdx) => {
  const url = '/eventDetail';

  return ApiService.get(url, {eventIdx});
}

export const refreshToken = async(refreshToken, setCookie) => {

  const refreshRequest = axios.create({
    baseURL: `${process.env.REACT_APP_AUTH_API_URL}`
  });
  return refreshRequest.post('/refresh', {
    refreshToken
  }).then(({ status, data }) => {
    if (status === 200) {
      console.log('get new token')

      setCookie('token', data.token, {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/'
      });
      setCookie('refreshToken', data.refreshToken, {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/'
      });
      updateToken(data.token, data.refreshToken);

      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('refresh', data.token, data.refreshToken);
      }
    } else {
      if (status === 401) {
        if (window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler('logout');
        }
      }
    }
  });
}
